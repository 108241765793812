import { Fragment, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createNewUserShare, getHomeShares } from "../../actions/shareActions";
import MetaData from "./MetaData";

export default function ShareUser() {
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [teleno, setTeleno] = useState("");
  const [commuter, setCommuter] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const { id } = useParams();
  const { shares = [] } = useSelector((state) => state.shareState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createNewUserShare(username, useremail, teleno, commuter,pickupAddress, id));
    navigate("/");
  };

  useEffect(() => {
    dispatch(getHomeShares);
  }, [dispatch]);

  const shareNo = shares.filter((share) => {
    return share._id === id;
  })[0];

  useEffect(() => {
    const sharedata = shares.filter((share) => {
      return share._id === id;
    })[0];

    if (sharedata?.sharePassenger === sharedata?.count) {
      navigate("/");
    }
  }, [id, shares,navigate]);

  return (
    <Fragment>
      <MetaData title={"book share vehicle"} />
      <div id="booking" className="section">
        <div className="section-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="booking-form">
                  <form onSubmit={submitHandler} encType="multipart/form-data">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Name</span>
                          <input
                            className="form-control"
                            type="text"
                            value={username}
                            placeholder="name"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">
                            Email (to send details)
                          </span>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="email"
                            value={useremail}
                            onChange={(e) => setUseremail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Phone Number</span>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="contact no"
                            value={teleno}
                            onChange={(e) => setTeleno(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Passengers</span>
                          <input
                            className="form-control"
                            type="number"
                            min="1"
                            max={shareNo.sharePassenger - shareNo.count}
                            placeholder="passengers"
                            value={commuter}
                            onChange={(e) => setCommuter(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <span className="form-label">Pickup Place</span>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Pickup Place"
                            value={pickupAddress}
                            onChange={(e) => setPickupAddress(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-btn">
                      <button type="submit" className="submit-btn">
                        Book
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
