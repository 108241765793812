import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { driverDetailActions } from "../../actions/bookingActions";
import { getReviews } from "../../actions/driverActions";
import MetaData from "../layouts/MetaData";
import moment from "moment";

export default function CompletedDetails() {
  const { driverDetail = {} } = useSelector((state) => state.bookingState);
  const {
    mapInfo = {},
    user = {},
    packageInfo = {},
  } = driverDetail;
  const { reviews = [] } = useSelector((state) => state.driverState);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(driverDetailActions(id));
    dispatch(getReviews);
  }, [dispatch]);

  let filterReview = reviews.filter((review) => {
    return review.bookingId === id;
  });

  return (
    <Fragment>
      <MetaData title={"single completed"} />
      <div className="card-confirm">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-7 pb-5 pe-lg-5">
              <div className="row">
                <div className="col-12 p-5">
                  <img
                    src="/images/taxidriver.jpg"
                    style={{ height: "auto", width: "100%" }}
                    alt=""
                  />
                </div>
                <div className="row m-0 bg-light">
                  <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                    <p className="text-muted">Name</p>
                    <p className="h6">{user.name}</p>
                  </div>
                  <div className="col-md-4 col-6  ps-30 my-4">
                    <p className="text-muted">Vehicle</p>
                    <p className="h6 m-0">{driverDetail.vehicle}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Contact No</p>
                    <p className="h6 m-0">{driverDetail.phoneNo}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Passengers</p>
                    <p className="h6 m-0">{driverDetail.noOfPassengers}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Date</p>
                    <p className="h6 m-0">{driverDetail.pickupDate}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Time</p>
                    <p className="h6 m-0">
                      {moment(driverDetail.pickupTime, "HH:mm").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 p-0 ps-lg-4">
              <div className="row m-0">
                <div className="col-12 px-4">
                  <div className="d-flex align-items-end mt-4 mb-2">
                    <p className="h4 m-0">
                      <span className="pe-1">Trip Details</span>
                    </p>
                  </div>
                  {filterReview &&
                    filterReview.map((review) => (
                      <Fragment>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="textmuted">Review</p>
                          <div className="rating-outer">
                            <div
                              className="rating-inner"
                              style={{ width: `${(review.rating / 5) * 100}%` }}
                            ></div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="textmuted">Comment</p>
                          <p className="fs-14 fw-bold">{review.comment}</p>
                        </div>
                      </Fragment>
                    ))}
                  <div className="d-flex justify-content-between mb-3">
                    <p className="textmuted fw-bold">Total</p>
                    <div className="d-flex align-text-top ">
                      <span className="h4">
                        Rs.{driverDetail.priceForDriver}
                      </span>
                    </div>
                  </div>
                </div>
                {driverDetail.packageInfo ? (
                  <div className="col-12 px-0">
                    <div className="row bg-light m-0">
                      <div className="col-12 px-4 my-4">
                        <p className="fw-bold">Package details</p>
                      </div>
                      <div className="col-12 px-4">
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Days</p>
                          <p className="fs-14 fw-bold">{packageInfo.days}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Free Mileage</p>
                          <p className="fs-14 fw-bold">
                            {packageInfo.mileage} km
                          </p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Extra per Km</p>
                          <p className="fs-14 fw-bold">
                            Rs.{packageInfo.extra}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Pickup Place</p>
                          <p className="fs-14 fw-bold">
                            {packageInfo.pickupPlace}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 px-0">
                    <div className="row bg-light m-0">
                      <div className="col-12 px-4 my-4">
                        <p className="fw-bold">Visiting places</p>
                      </div>
                      <div className="col-12 px-4">
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Duration</p>
                          <p className="fs-14 fw-bold">{mapInfo.duration}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Start</p>
                          <p className="fs-14 fw-bold">{mapInfo.pickupPoint}</p>
                        </div>

                        {mapInfo.waypoints?.length ? (
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">Stops</p>

                            {mapInfo.waypoints.map((way, index) => (
                              <>
                                <br />
                                <p className="fs-14 fw-bold ml-2">
                                  {index + 1}.{way}
                                </p>
                              </>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">End</p>
                          <p className="fs-14 fw-bold">{mapInfo.dropPoint}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
