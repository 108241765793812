import axios from "axios";
import {
  adminBookShareFail,
  adminBookShareRequest,
  adminBookShareSuccess,
  adminShareFail,
  adminShareRequest,
  adminShareSuccess,
  deleteShareFail,
  deleteShareRequest,
  deleteShareSuccess,
  driverBookShareFail,
  driverBookShareRequest,
  driverBookShareSuccess,
  driverShareFail,
  driverShareRequest,
  driverShareSuccess,
  getAdminSingleShareFail,
  getAdminSingleShareRequest,
  getAdminSingleShareSuccess,
  homeShareFail,
  homeShareRequest,
  homeShareSuccess,
  newShareDriverFail,
  newShareDriverRequest,
  newShareDriverSuccess,
  newShareUserFail,
  newShareUserRequest,
  newShareUserSuccess,
  updateShareFail,
  updateShareRequest,
  updateShareSuccess,
} from "../slices/shareSlice";

export const createNewDriverShare =
  (
    departure,
    arrive,
    departureDate,
    departureTime,
    sharePassenger,
    shareAmount
  ) =>
  async (dispatch) => {
    try {
      dispatch(newShareDriverRequest());

      const { data } = await axios.post(`/api/v1/driver/addshare`, {
        departure,
        arrive,
        departureDate,
        departureTime,
        sharePassenger,
        shareAmount,
      });

      dispatch(newShareDriverSuccess(data));
    } catch (error) {
      //handle error
      dispatch(newShareDriverFail(error.response.data.message));
    }
  };

//admin
export const createNewAdminShare =
  (
    departure,
    arrive,
    departureDate,
    departureTime,
    sharePassenger,
    shareAmount,
    driver
  ) =>
  async (dispatch) => {
    try {
      dispatch(newShareDriverRequest());

      const { data } = await axios.post(`/api/v1/admin/addshare`, {
        departure,
        arrive,
        departureDate,
        departureTime,
        sharePassenger,
        shareAmount,
        driver,
      });

      dispatch(newShareDriverSuccess(data));
    } catch (error) {
      //handle error
      dispatch(newShareDriverFail(error.response.data.message));
    }
  };

//get admin shares
export const getAdminShares = async (dispatch) => {
  try {
    dispatch(adminShareRequest());

    const { data } = await axios.get(`/api/v1/admin/viewshare`);

    dispatch(adminShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(adminShareFail(error.response.data.message));
  }
};

//get driver shares
export const getDriverShares = async (dispatch) => {
  try {
    dispatch(driverShareRequest());

    const { data } = await axios.get(`/api/v1/driver/viewshare`);

    dispatch(driverShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(driverShareFail(error.response.data.message));
  }
};

//get home shares
export const getHomeShares = async (dispatch) => {
  try {
    dispatch(homeShareRequest());

    const { data } = await axios.get(`/api/v1/shares`);

    dispatch(homeShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(homeShareFail(error.response.data.message));
  }
};

export const createNewUserShare =
  (username, useremail, teleno, commuter,pickupAddress, id) => async (dispatch) => {
    try {
      dispatch(newShareUserRequest());

      const { data } = await axios.post(`/api/v1/bookshare/${id}`, {
        username,
        useremail,
        teleno,
        commuter,
        pickupAddress
      });

      dispatch(newShareUserSuccess(data));
    } catch (error) {
      //handle error
      dispatch(newShareUserFail(error.response.data.message));
    }
  };

//get driver shares
export const getDriverBookShares = (id) => async (dispatch) => {
  try {
    dispatch(driverBookShareRequest());

    const { data } = await axios.get(`/api/v1/driver/viewshare/${id}`);

    dispatch(driverBookShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(driverBookShareFail(error.response.data.message));
  }
};

//get driver shares
export const getAdminBookShares = (id) => async (dispatch) => {
  try {
    dispatch(adminBookShareRequest());

    const { data } = await axios.get(`/api/v1/admin/viewshare/${id}`);

    dispatch(adminBookShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(adminBookShareFail(error.response.data.message));
  }
};

//delete share
export const deleteShare = (id) => async (dispatch) => {
  try {
    dispatch(deleteShareRequest());

    await axios.delete(`/api/v1/driver/viewshare/${id}`);

    dispatch(deleteShareSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteShareFail(error.response.data.message));
  }
};

//delete share
export const deleteAdminShare = (id) => async (dispatch) => {
  try {
    dispatch(deleteShareRequest());

    await axios.delete(`/api/v1/admin/viewshare/${id}`);

    dispatch(deleteShareSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteShareFail(error.response.data.message));
  }
};

//delete share
export const deleteAdminUserShare = (id) => async (dispatch) => {
  try {
    dispatch(deleteShareRequest());

    await axios.delete(`/api/v1/admin/usershare/${id}`);

    dispatch(deleteShareSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteShareFail(error.response.data.message));
  }
};

export const updateSharedriver = (id, shareData) => async (dispatch) => {
  try {
    dispatch(updateShareRequest());
    const config = {
      headers: {
        "Content-type": "application/json", //without image
      },
    };

    const { data } = await axios.put(
      `/api/v1/admin/viewshare/${id}`,
      shareData,
      config
    );

    dispatch(updateShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(updateShareFail(error.response.data.message));
  }
};

export const getSingleBookShare = (id) => async (dispatch) => {
  try {
    dispatch(getAdminSingleShareRequest());

    const { data } = await axios.get(`/api/v1/admin/view/${id}`);

    dispatch(getAdminSingleShareSuccess(data));
  } catch (error) {
    //handle error
    dispatch(getAdminSingleShareFail(error.response.data.message));
  }
};
