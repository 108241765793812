import { Fragment } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function BookingSuccess() {
  return (
    <Fragment>
      <MetaData title={"success"} />
      <div className="row justify-content-center">
        <div className="col-6 mt-5 text-center">
          <img
            className="my-5 img-fluid d-block mx-auto"
            src="/images/success.png"
            alt="Order Success"
            width="200px"
            height="200px"
          />

          <h2>Your Booking has been placed successfully.</h2>

          <Link to="/mybookings">Go to Your Bookings</Link>
        </div>
      </div>
    </Fragment>
  );
}
