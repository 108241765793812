import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  cancelBooking,
  userBookings as userBookingsAction,
} from "../../actions/bookingActions";
import {
  clearBookingCancelled,
  clearBookingError,
} from "../../slices/bookingSlice";
import { toast } from "react-toastify";
import MenuBar from "./MenuBar";
import MetaData from "../layouts/MetaData";
import moment from "moment";
import Loader from "../layouts/Loader";

export default function UserBookings() {
  const {
    userBooking = [],
    error,
    loading,
    isBookingUpdated = false,
  } = useSelector((state) => state.bookingState);
  const dispatch = useDispatch();

  const [userReason, setUserReason] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (isBookingUpdated) {
      handleClose();
      toast("Booking cancelled successfully", {
        type: "success",
        toastId: "userbookupdate",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearBookingCancelled()),
      });
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "userbookerror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }
    dispatch(userBookingsAction);
  }, [isBookingUpdated, error, dispatch]);

  const submitHandler = (e, id) => {
    e.preventDefault();
    let bookingData = userBooking.filter((book) => {
      return id === book._id;
    })[0];
    bookingData = {
      ...bookingData,
      status: {
        ...bookingData.status,
        bookStatus: "Cancelled",
        cancelledUserAt: new Date(),
        userReason: userReason,
      },
    };
    dispatch(cancelBooking(id, bookingData));
  };

  return (
    <Fragment>
      
    
      <MetaData title={"mybookings"} />
      <MenuBar bookings />
      { loading ? (<Loader/> ):(
        <Fragment>

      <h1 className="mt-5 text-center">My Booking</h1>
      <div className="row text-center">
        {userBooking.map((booking, index) => (
          <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp">
            <div className="pricing_design">
              <div className="single-pricing bg-c-yellow">
                <div className="price-head">
                  <h2
                    style={{
                      color:
                        booking.status.bookStatus.includes("Processing") ||
                        booking.status.bookStatus.includes("Cancelled")
                          ? "red"
                          : "green",
                    }}
                  >
                    {booking.status.bookStatus}
                  </h2>
                  <h1>Rs.{booking.priceForUser}</h1>
                </div>
                <ul>
                  <li>
                    <b>{booking.pickupDate}</b>/
                    <b>
                      {moment(booking.pickupTime, "HH:mm").format("hh:mm A")}
                    </b>
                  </li>
                  <li>
                    <b>{booking.phoneNo}</b>
                  </li>
                  <li>
                    <b>{booking.randomGeneratedCode}</b> Pickup code
                  </li>
                  {booking.packageInfo ? (
                    <li>
                      <b>{booking.packageInfo.days}</b> days/{" "}
                      <b>{booking.packageInfo.mileage}</b>km Free Mileage
                    </li>
                  ) : (
                    <li>
                      From <b>{booking.mapInfo.pickupPoint}</b>
                      <br />
                      To <b>{booking.mapInfo.dropPoint}</b>
                    </li>
                  )}
                </ul>
                <div className="pricing-price"></div>
                <div className="mx-3 mt-3 mb-2">
                  <Link
                    to={`/booking/${booking._id}`}
                    type="button"
                    className="btn btn-primary btn-block"
                  >
                    <small>View</small>
                  </Link>
                </div>

                {(booking.status.bookStatus === "Processing" ||
                  booking.status.bookStatus === "Accepted") &&
                  new Date(
                    booking.pickupDate + "T" + booking.pickupTime
                  ).getTime() >
                    new Date().setDate(new Date().getDate() + 2) && (
                    <Fragment>
                      <div className="mx-3 mt-3 mb-2">
                        <button
                          type="button"
                          onClick={handleShow}
                          className="btn btn-danger btn-block"
                          data-toggle="modal"
                          data-target={"#bookingid" + index}
                        >
                          <small>Cancel</small>
                        </button>
                      </div>

                      <div
                        className="modal fade"
                        id={"bookingid" + index}
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                Do you want to cancel the trip?
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                aria-hidden="true"
                              >
                                &times;
                              </button>
                            </div>
                            <div className="modal-body">
                              <textarea
                                className="form-control"
                                type="text"
                                value={userReason}
                                onChange={(e) => setUserReason(e.target.value)}
                                placeholder="Please Give Reason"
                              ></textarea>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                No
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => submitHandler(e, booking._id)}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
      )}
    </Fragment>
  );
}
