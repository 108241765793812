import { NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function Sidetoolbar() {
  const navigate = useNavigate();
  return (
    <div className="sidebar-wrapper">
      <nav id="sidebar">
        <ul className="list-unstyled components">
          <li>
            <Link to="/admin/dashboard">
              <i className="fas fa-tachometer-alt"></i> Dashboard
            </Link>
          </li>

          <li>
            <NavDropdown
              title={<i className="fa fa-pencil-square-o">Custom</i>}
            >
              <NavDropdown.Item onClick={() => navigate("/admin/custom/new")}>
                <i className="fa fa-plus">Create</i>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/admin/custom")}>
                <i className="fa fa-shopping-basket">All</i>
              </NavDropdown.Item>
            </NavDropdown>
          </li>
          <li>
            <NavDropdown title={<i className="fa fa-car">Drivers</i>}>
              <NavDropdown.Item
                onClick={() => navigate("/admin/drivers/create")}
              >
                <i className="fa fa-plus">Create</i>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/admin/drivers")}>
                <i className="fa fa-id-card">All</i>
              </NavDropdown.Item>
            </NavDropdown>
          </li>

          <li>
            <Link to="/admin/users">
              <i className="fa fa-users"></i> Users
            </Link>
          </li>

          <li>
            <Link to="/admin/bookings">
              <i className="fa fa-book"></i> Bookings
            </Link>
          </li>

          <li>
            <NavDropdown
              title={<i className="fa fa-pencil-square-o">Packages</i>}
            >
              <NavDropdown.Item onClick={() => navigate("/admin/package/new")}>
                <i className="fa fa-plus">Create</i>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/admin/package")}>
                <i className="fa fa-shopping-basket">All</i>
              </NavDropdown.Item>
            </NavDropdown>
          </li>
          <li>
            <NavDropdown
              title={<i className="fa fa-pencil-square-o">Share Vehicle</i>}
            >
              <NavDropdown.Item onClick={() => navigate("/admin/share/new")}>
                <i className="fa fa-plus">Create</i>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/admin/share")}>
                <i className="fa fa-shopping-basket">All</i>
              </NavDropdown.Item>
            </NavDropdown>
          </li>
          <li>
            <Link to="/admin/contacts">
              <i className="fa fa-comment"></i> Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
