import { Fragment, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Autocomplete } from "@react-google-maps/api";
import { Box, HStack, Input } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleBookShare,
  updateSharedriver,
} from "../../actions/shareActions";
import Sidetoolbar from "./Sidetoolbar";
import { getDrivers } from "../../actions/driverActions";
import { toast } from "react-toastify";
import { clearError, clearShareUpdated } from "../../slices/shareSlice";

export default function UpdateShareVehicle() {
  const [sharePassenger, setSharePassenger] = useState("");
  const [shareAmount, setShareAmount] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [driver, setDriver] = useState("");
  const departureRef = useRef();
  const arriveRef = useRef();

  const { id: shareId } = useParams();

  const { drivers = [] } = useSelector((state) => state.driverState);

  const { isShareUpdated=false, error, adminSingleShare = {} } = useSelector(
    (state) => state.shareState
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let departure = departureRef.current ? departureRef.current.value : "";
  let arrive = arriveRef.current ? arriveRef.current.value : "";

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("departure", departureRef.current.value);
    formData.append("arrive", arriveRef.current.value);
    formData.append("departureDate", departureDate);
    formData.append("departureTime", departureTime);
    formData.append("sharePassenger", sharePassenger);
    formData.append("shareAmount", shareAmount);

    dispatch(updateSharedriver(shareId, formData));
  };

  const driverPass = driver
    ? drivers.filter((drive) => {
        return drive.user === driver;
      })[0]
    : "";

  //console.log(adminSingleShare);

  useEffect(() => {
    if (isShareUpdated) {
      toast("share vehicle updated successfully", {
        type: "success",
        toastId: "shareupdateadminsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearShareUpdated()),
      });
      navigate("/admin/share");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "shareupdateerror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    dispatch(getSingleBookShare(shareId));
    dispatch(getDrivers);
  }, [isShareUpdated, dispatch,error]);

  useEffect(() => {
    if (adminSingleShare._id) {
      setSharePassenger(adminSingleShare.sharePassenger);
      setShareAmount(adminSingleShare.shareAmount);
      setDepartureDate(adminSingleShare.departureDate);
      setDepartureTime(adminSingleShare.departureTime);
    }
  }, [adminSingleShare]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidetoolbar />
        </div>

        <div className="col-12 col-md-10">
          <div id="booking" className="section">
            <div className="section-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="booking-form">
                      <form
                        onSubmit={submitHandler}
                        encType="multipart/form-data"
                      >
                      

                        <HStack spacing={2} justifyContent="space-between">
                          <Box flexGrow={1}>
                            <span className="form-label">Departure Place</span>
                            <Autocomplete
                              options={{
                                componentRestrictions: { country: "lk" },
                              }}
                            >
                              <Input
                                id="search"
                                type="text"
                                placeholder="Pickup Place"
                                ref={departureRef}
                                required
                              />
                            </Autocomplete>
                          </Box>
                        </HStack>
                        <HStack spacing={2} justifyContent="space-between">
                          <Box flexGrow={1}>
                            <span className="form-label">Arrive Place</span>
                            <Autocomplete
                              options={{
                                componentRestrictions: { country: "lk" },
                              }}
                            >
                              <Input
                                id="search"
                                type="text"
                                placeholder="Pickup Place"
                                ref={arriveRef}
                                required
                              />
                            </Autocomplete>
                          </Box>
                        </HStack>

                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <span className="form-label">Departure Date</span>
                              <input
                                className="form-control"
                                type="date"
                                value={departureDate}
                                onChange={(e) =>
                                  setDepartureDate(e.target.value)
                                }
                                min={new Date().toISOString().split("T")[0]}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <span className="form-label">Departure Time</span>
                              <input
                                className="form-control"
                                type="time"
                                value={departureTime}
                                onChange={(e) =>
                                  setDepartureTime(e.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <span className="form-label">Passengers</span>
                              <input
                                className="form-control"
                                type="number"
                                placeholder="passengers"
                                min="1"
                                max={driverPass.maxPassengers}
                                value={sharePassenger}
                                onChange={(e) =>
                                  setSharePassenger(e.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <span className="form-label">
                                Price per person
                              </span>
                              <input
                                className="form-control"
                                type="number"
                                placeholder="price per person"
                                min="1"
                                value={shareAmount}
                                onChange={(e) => setShareAmount(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-btn">
                          <button type="submit" className="submit-btn">
                            Update share Vehicle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
