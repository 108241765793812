import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuBar from "../booking/MenuBar";
import { validateBooking } from "./FormPackage";
import CheckOut from "./CheckOut";
import MetaData from "../layouts/MetaData";
import moment from "moment";
import axios from "axios";
import PayHerePackage from "./PayHerePackage";

export default function ConfirmPackage() {
  const { bookingInfo = {} } = useSelector((state) => state.bookingState);
  const { user } = useSelector((state) => state.authState);
  const packageInfo = JSON.parse(sessionStorage.getItem("packageInfo")) ?? {};
  const navigate = useNavigate();

  const [hash, setHashKey] = useState("");
  const [order, setOrder] = useState("");


  useEffect(() => {
    if (localStorage.getItem("bookingInfo")) {
      validateBooking(bookingInfo, navigate);
    } else {
      navigate("/");
    }

    async function getHashApiKey() {
      try {
        const { data } = await axios.get(
          `/api/v1/payment-hash/${(packageInfo.priceForUser*bookingInfo.bookDay)+(bookingInfo.bookDay-1)*2000}`
        );
        setHashKey(data.hash);
        setOrder(data.orderId);
      } catch (error) {
       
      }
    }
    getHashApiKey();
  }, []);

  return (
    <Fragment>
      <MetaData title={"confirm package"} />
      <MenuBar mypackage />
      <CheckOut paymentpack />

      <div className="card-confirm mb-5">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-7 pb-5 pe-lg-5">
              <div className="row">
                <div className="col-12 p-0">
                  <img
                    src="/images/taxi.jpg"
                    style={{ height: "300px", width: "650px" }}
                    alt=""
                  />
                </div>

                <div className="row m-0 bg-light">
                  <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                    <p className="text-muted">Name</p>
                    <p className="h6">{user.name}</p>
                  </div>
                  <div className="col-md-4 col-6  ps-30 my-4">
                    <p className="text-muted">Vehicle</p>
                    <p className="h6 m-0">{packageInfo.vehicle}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Contact No</p>
                    <p className="h6 m-0">{bookingInfo.phoneNo}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Passengers</p>
                    <p className="h6 m-0">{packageInfo.noOfPassengers}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Pickup date</p>
                    <p className="h6 m-0">{bookingInfo.pickupDate}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Pickup Time</p>
                    <p className="h6 m-0">
                      {moment(bookingInfo.pickupTime, "HH:mm").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 p-0 ps-lg-4">
              <div className="row m-0">
                <div className="col-12 px-4">
                  <div className="d-flex align-items-end mt-4 mb-2">
                    <p className="h4 m-0">
                      <span className="pe-1">Package Details</span>
                    </p>
                  </div>


                  <div className="d-flex justify-content-between mb-3">
                    <p className="textmuted fw-bold">Subtotal</p>
                    <div className="d-flex align-text-top ">
                      <span className="h6">Rs.</span>
                      <span className="h6">{packageInfo.priceForUser*bookingInfo.bookDay}</span>
                      
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="textmuted fw-bold">Waiting Charge</p>
                    <div className="d-flex align-text-top ">
                      <span className="h6">Rs.</span>
                      <span className="h6">{(bookingInfo.bookDay-1)*2000}</span>
                     
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="textmuted fw-bold">Total</p>
                    <div className="d-flex align-text-top ">
                      <span className="h4">Rs.</span>
                      <span className="h4">{(packageInfo.priceForUser*bookingInfo.bookDay)+(bookingInfo.bookDay-1)*2000}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="row bg-light m-0">
                    <div className="col-12 px-4">
                      <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted">Days</p>
                        <p className="fs-14 fw-bold">{bookingInfo.bookDay}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted">Free Mileage/day</p>
                        <p className="fs-14 fw-bold">{packageInfo.mileage}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted">Extra per km</p>
                        <p className="fs-14 fw-bold">{packageInfo.extra}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted">Pickup Place</p>
                        <p className="fs-14 fw-bold">
                          {bookingInfo.pickupPlace}
                        </p>
                      </div>

                      <PayHerePackage
                        orderId={order}
                        name="Vehicle booking"
                        amount={(packageInfo.priceForUser*bookingInfo.bookDay)+(bookingInfo.bookDay-1)*2000}
                        hash={hash}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
