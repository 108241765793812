import { Autocomplete } from "@react-google-maps/api";
import { Box, HStack, Input } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCustomsUser } from "../../actions/customActions";
const google = window.google;

export default function FrontMap() {
  const { isAuthenticated, user } = useSelector((state) => state.authState);
  const { customUser = {} } = useSelector((state) => state.customState);
  const pickupRef = useRef();
  const dropRef = useRef();
  const [pickupPlace, setPickupPlace] = useState("");
  const [dropoffPlace, setDropoffPlace] = useState("");
  const [vehicle, setVehicle] = useState("bus");
  const [distance, setDistance] = useState("");
  const dispatch = useDispatch();
  let lat = 7.2909659652741645;
  let lng = 80.62750391095952;

  useEffect(() => {
    dispatch(getCustomsUser);
  }, []);

  async function calculateRoute() {
    setPickupPlace(pickupRef.current.value);
    setDropoffPlace(dropRef.current.value);

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: pickupRef.current.value,
      destination: dropRef.current.value,

      //optimizeWaypoints: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    let totalDistance = 0;

    let legs = results.routes[0].legs;
    for (let i = 0; i < legs.length; i++) {
      totalDistance += legs[i].distance.value;
    }

    setDistance(totalDistance / 1000);
  }

  let subtotal = 0;
  let total;
  let discount = 0.08;

  if (vehicle === "car") {
    subtotal = Math.ceil(distance * customUser.userCar);
  }
  if (vehicle === "minicar") {
    subtotal = Math.ceil(distance * customUser.userMiniCar);
  }
  if (vehicle === "van") {
    subtotal = Math.ceil(distance * customUser.userVan);
  }
  if (vehicle === "minivan") {
    subtotal = Math.ceil(distance * customUser.userMiniVan);
  }
  if (vehicle === "bus") {
    subtotal = Math.ceil(distance * customUser.userBus);
  }
  if (vehicle === "minibus") {
    subtotal = Math.ceil(distance * customUser.userMiniBus);
  }

  total = Math.ceil(subtotal - discount * subtotal);

  return (
    <div className="mapsample">
      <div className="discount">
        <h3 className="map-front">Review the price of your journey</h3>
        <div>
          <HStack spacing={2} justifyContent="space-between">
            <Box flexGrow={1}>
              <span className="form-label">Pick up Place</span>
              <Autocomplete
                onPlaceChanged={calculateRoute}
                options={{ componentRestrictions: { country: "lk" } }}
              >
                <Input
                  id="search"
                  type="text"
                  placeholder="Pick up Place"
                  ref={pickupRef}
                  required
                />
              </Autocomplete>
            </Box>
          </HStack>

          <HStack spacing={2} justifyContent="space-between">
            <Box flexGrow={1}>
              <span className="form-label">Drop off Place</span>
              <Autocomplete
                onPlaceChanged={calculateRoute}
                options={{ componentRestrictions: { country: "lk" } }}
              >
                <Input
                  id="search"
                  type="text"
                  placeholder="Drop off Place"
                  ref={dropRef}
                  required
                />
              </Autocomplete>
            </Box>
          </HStack>
        </div>
        <div>
          <div>
            <div className="form-group">
              <span className="form-label col-sm-12">Vehicles</span>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="vehicle"
                  id="vehicle1"
                  value="minicar"
                  checked={vehicle === "minicar"}
                  onChange={(e) => setVehicle(e.target.value)}
                />

                <label className="form-check-label" htmlFor="vehicle1">
                  <i className="fas fa-car"></i> MiniCar-
                  <i class="fa fa-users" aria-hidden="true"></i> 3
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="vehicle"
                  id="vehicle2"
                  value="car"
                  checked={vehicle === "car"}
                  onChange={(e) => setVehicle(e.target.value)}
                />

                <label className="form-check-label" htmlFor="vehicle2">
                  <i className="fas fa-car"></i> Car-
                  <i class="fa fa-users" aria-hidden="true"></i> 4
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="vehicle"
                  id="vehicle3"
                  value="minivan"
                  checked={vehicle === "minivan"}
                  onChange={(e) => setVehicle(e.target.value)}
                />

                <label className="form-check-label" htmlFor="vehicle3">
                  <i className="fas fa-shuttle-van"></i> MiniVan-
                  <i class="fa fa-users" aria-hidden="true"></i> 8
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="vehicle"
                  id="vehicle4"
                  value="van"
                  checked={vehicle === "van"}
                  onChange={(e) => setVehicle(e.target.value)}
                />

                <label className="form-check-label" htmlFor="vehicle4">
                  <i className="fas fa-shuttle-van"></i> Van-
                  <i class="fa fa-users" aria-hidden="true"></i> 9
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="vehicle"
                  id="vehicle5"
                  value="minibus"
                  checked={vehicle === "minibus"}
                  onChange={(e) => setVehicle(e.target.value)}
                />

                <label className="form-check-label" htmlFor="vehicle5">
                  <i className="fas fa-bus"></i> MiniBus-
                  <i class="fa fa-users" aria-hidden="true"></i> 30
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="vehicle"
                  id="vehicle6"
                  value="bus"
                  checked={vehicle === "bus"}
                  onChange={(e) => setVehicle(e.target.value)}
                />

                <label className="form-check-label" htmlFor="vehicle6">
                  <i className="fas fa-bus"></i> Bus-{" "}
                  <i class="fa fa-users" aria-hidden="true"></i> 50
                </label>
              </div>
            </div>
          </div>
        </div>
{/* 
        <div className="row">
          
          <div className="form-label ml-3">
            {" "}
            Subtotal(two way):{" "}
            <span style={{ alignContent: "right" }}>Rs.{subtotal}</span>
          </div>
        </div>
        
        <div className="row">
          <div className="form-label ml-3">
            {" "}
            Discount(8%): <span>Rs.{Math.ceil(discount * subtotal)}</span>
          </div>
        </div>
        */}
        <div className="row">
          <div className="form-label ml-3">
            {" "}
            <b>
              {" "}
              Total:{" "}
              <span>Rs.{subtotal}</span>
            </b>
          </div>
        </div>

        <div className="start-btn">
          {isAuthenticated && user.role === "user" ? (
            <Link to="/map" className="btn-get anim">
              Book Now <i className="fa fa-angle-right"></i>
            </Link>
          ) : isAuthenticated && user.role === "driver" ? (
            <Link to="/" className="btn-get anim">
              Book Now <i className="fa fa-angle-right"></i>
            </Link>
          ) : (
            <Link to="/login" className="btn-get anim">
              Book Now <i className="fa fa-angle-right"></i>
            </Link>
          )}
        </div>
      </div>
      <div className="mapframe">
        <iframe
          width="650"
          height="650"
          frameborder="0"
          referrerpolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyAQomA-awg-YBTtxjp6pSg9lQ4KboaPVWM&center=${lat},${lng}&origin=${pickupPlace}
            &destination=${dropoffPlace}
            &avoid=tolls|highways&zoom=7
            `}
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
