import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  bookingDetailActions,
  userUpdateBooking,
} from "../../actions/bookingActions";
import { Modal } from "react-bootstrap";
import { createReview } from "../../actions/driverActions";
import { clearError, clearReviewSubmitted } from "../../slices/driverSlice";
import { toast } from "react-toastify";
import DriverReview from "./DriverReview";
import MenuBar from "./MenuBar";
import MetaData from "../layouts/MetaData";
import moment from "moment";
import { clearBookinguserUpdate } from "../../slices/bookingSlice";

export default function BookingDetails() {
  const {
    bookingDetail = {},
    singleDriver = {},
    loading,
    error,
    isBookingUpdated = false,
  } = useSelector((state) => state.bookingState);
  const { mapInfo = {}, status = {}, packageInfo = {} } = bookingDetail;
  const { isReviewSubmitted } = useSelector((state) => state.driverState);
  const { user = {} } = useSelector((state) => state.authState);
  const [pickupDate, setDate] = useState("");
  const [pickupTime, setTime] = useState("");



  const dispatch = useDispatch();
  const { id: bookingId } = useParams();

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");

  const reviewHandler = (e, driverId) => {
    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("comment", comment);
    formData.append("driverId", driverId);
    formData.append("bookingId", bookingId);
    dispatch(createReview(formData));
  };

  useEffect(() => {
    if (isReviewSubmitted) {
      handleClose();
      toast("Review Submitted successfully", {
        type: "success",
        toastId: "reviewsubmitsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearReviewSubmitted()),
      });
    }
    if (isBookingUpdated) {
      handleClose();
      toast("Booking updated successfully", {
        type: "success",
        toastId: "userbookupdate",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearBookinguserUpdate()),
      });
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "reviewsubmiterror",
        onOpen: () => {
          dispatch(clearError);
        },
      });
      return;
    }
    dispatch(bookingDetailActions(bookingId));
  }, [dispatch, error, isReviewSubmitted, bookingId, isBookingUpdated]);

  useEffect(() => {
    if (bookingDetail._id) {
      setDate(bookingDetail.pickupDate);
      setTime(bookingDetail.pickupTime);
    }
  }, [bookingDetail]);

  const whatsappLink = singleDriver
    ? `https://wa.me/${singleDriver.contactNo}?`
    : null;

  const changeHandler = (e, id) => {
    e.preventDefault();
    let bookingData = bookingDetail;

    bookingData = {
      ...bookingData,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      updatedAt: new Date(),
    };
    dispatch(userUpdateBooking(id, bookingData));
  };

  return (
    <Fragment>
      <MetaData title={"user single booking"} />
      <MenuBar bookings />
      {(status.bookStatus === "Processing" ||
        status.bookStatus === "Accepted") && (
          <div className="mx-3 mt-3 mb-2">
            <button
              type="button"
              onClick={handleShow1}
              className="btn btn-success btn-block"
              data-toggle="modal"
              data-target={"#dateid"}
            >
              <small>Change Date/Time </small>
            </button>
            <div
              className="modal fade"
              id={"dateid"}
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Do you want to change the trip date & time?
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Pickup Date</span>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="Pickup date"
                            value={pickupDate}
                            onChange={(e) => setDate(e.target.value)}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Pickup Time</span>
                          <input
                            className="form-control"
                            placeholder="Pickup time"
                            type="time"
                            value={pickupTime}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => changeHandler(e, bookingId)}
                    >
                      Set
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {singleDriver._id ? (
        <Fragment>
          <div className="card-confirm">
            <div className="container">
              <div className="row m-0">
                <div className="col-lg-7 pb-5 pe-lg-5">
                  <div className="row">
                    <div className="col-12 p-5">
                      <img
                        src="/images/taxi.jpg"
                        style={{ height: "300px", width: "400px" }}
                        alt=""
                      />
                    </div>

                    <div className="col-md-2 col-6">
                      <img
                        src={
                          singleDriver.user.avatar ??
                          "/images/default_avatar.png"
                        }
                        alt="profile"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <p className="h5">{singleDriver.name}</p>
                    </div>

                    <div className="rating-outer">
                      <div
                        className="rating-inner"
                        style={{
                          width: `${(singleDriver.ratings / 5) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <span>({singleDriver.numOfReviews} Reviews)</span>

                    <div className="row m-0 bg-light">
                      <div className=" col-6 ps-40 my-4">
                        <p className="text-muted">Contact No</p>
                        <p className="h5 m-0">{singleDriver.contactNo}</p>
                      </div>

                      <div className=" col-6 ps-40 my-4">
                        <p className="text-muted">Vehicle</p>
                        <p className="h5 m-0">{singleDriver.vehicleCategory}</p>
                      </div>
                      <div className=" col-6 ps-40 my-4">
                        <p className="text-muted">Vehicle Number</p>
                        <p className="h5 m-0">{singleDriver.vehicleNo}</p>
                      </div>
                      <div className=" col-6 ps-40 my-4">
                        <p className="text-muted">Vehicle Model</p>
                        <p className="h5 m-0">{singleDriver.vehicleModal}</p>
                      </div>
                    </div>
                    <div className="col-12  mb-4 p-0 px-4">
                      <button
                        onClick={handleShow}
                        className="btn btn-primary "
                        data-toggle="modal"
                        data-target="#ratingModal"
                      >
                        Give Review
                      </button>
                    </div>
                    {singleDriver.reviews && singleDriver.reviews.length > 0 ? (
                      <DriverReview
                        reviews={singleDriver.reviews}
                        bookingId={bookingId}
                      />
                    ) : null}
                    <div className="row mt-2 mb-5">
                      <div className="rating w-50">
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Submit Review</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <ul className="stars">
                              {[1, 2, 3, 4, 5].map((star) => (
                                <li
                                  value={star}
                                  onClick={() => setRating(star)}
                                  className={`star ${
                                    star <= rating ? "orange" : ""
                                  }`}
                                  onMouseOver={(e) =>
                                    e.target.classList.add("yellow")
                                  }
                                  onMouseOut={(e) =>
                                    e.target.classList.remove("yellow")
                                  }
                                >
                                  <i className="fa fa-star"></i>
                                </li>
                              ))}
                            </ul>

                            <textarea
                              onChange={(e) => setComment(e.target.value)}
                              name="review"
                              id="review"
                              className="form-control mt-3"
                            ></textarea>

                            <button
                              disabled={loading}
                              onClick={(e) =>
                                reviewHandler(e, singleDriver._id)
                              }
                              className="btn btn-primary my-3 float-right review-btn px-4 text-black "
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              Submit
                            </button>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 p-0 ps-lg-4">
                  <div className="row m-0">
                    <div className="col-12 px-4">
                      <div className="d-flex align-items-end mt-4 mb-2">
                        <p className="h4 m-0">
                          <span className="pe-1">Trip Details</span>
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="textmuted">Name</p>
                        <p className="fs-14 fw-bold">{user.name}</p>
                      </div>

                      <div className="d-flex justify-content-between mb-2">
                        <p className="textmuted">Contact details</p>
                        <p className="fs-14 fw-bold">{bookingDetail.phoneNo}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="textmuted">Pickup Date</p>
                        <p className="fs-14 fw-bold">
                          {bookingDetail.pickupDate}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="textmuted">Pickup Time</p>
                        <p className="fs-14 fw-bold">
                          {moment(bookingDetail.pickupTime, "HH:mm").format(
                            "hh:mm A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="textmuted">Passengers</p>
                        <p className="fs-14 fw-bold">
                          {bookingDetail.noOfPassengers}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="textmuted">Code</p>
                        <p className="fs-14 fw-bold">
                          {bookingDetail.randomGeneratedCode}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <p className="textmuted fw-bold">Total</p>
                        <div className="d-flex align-text-top ">
                          <span className="h4">Rs.</span>
                          <span className="h4">
                            {bookingDetail.priceForUser}
                          </span>
                        </div>
                      </div>
                    </div>
                    {mapInfo.pickupPoint && (
                      <Fragment>
                        <div className="col-12 px-0">
                          <div className="row bg-light m-0">
                            <div className="col-12 px-4 my-4">
                              <p className="fw-bold">Visiting places</p>
                            </div>
                            <div className="col-12 px-4">
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Duration</p>
                                <p className="fs-14 fw-bold">
                                  {mapInfo.duration}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Start</p>
                                <p className="fs-14 fw-bold">
                                  {mapInfo.pickupPoint}
                                </p>
                              </div>
                              {mapInfo.waypoints.length ? (
                                <div className="d-flex justify-content-between mb-2">
                                  <p className="text-muted">Stops</p>

                                  {mapInfo.waypoints.map((way, index) => (
                                    <>
                                      <br />
                                      <p className="fs-14 fw-bold ml-2">
                                        {index + 1}.{way}
                                      </p>
                                    </>
                                  ))}
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">End</p>
                                <p className="fs-14 fw-bold">
                                  {mapInfo.dropPoint}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {packageInfo.days && (
                      <Fragment>
                        <div className="col-12 px-0">
                          <div className="row bg-light m-0">
                            <div className="col-12 px-4 my-4">
                              <p className="fw-bold">Package Details</p>
                            </div>
                            <div className="col-12 px-4">
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Days</p>
                                <p className="fs-14 fw-bold">
                                  {packageInfo.days}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Mileage</p>
                                <p className="fs-14 fw-bold">
                                  {packageInfo.mileage}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Extra per KM</p>
                                <p className="fs-14 fw-bold">
                                  {packageInfo.extra}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Pickup Place</p>
                                <p className="fs-14 fw-bold">
                                  {packageInfo.pickupPlace}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Link to={whatsappLink} target="_blank">
              <span className="btn-label">Driver Number</span>
              <img
                src="/images/whatsapp.png"
                alt="whatsapp"
                className="whatsapp_float"
              />
            </Link>
          </div>
        </Fragment>
      ) : (
        <div className="card-confirm">
          <div className="container">
            <div className="row m-0">
              <div className="col-lg-10 pb-5 pe-lg-5">
                <div className="row m-0">
                  <div className="col-12 px-4">
                    <div className="d-flex align-items-end mt-4 mb-2">
                      <p className="h4 m-0">
                        <span className="pe-1">Trip Details</span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Name</p>
                      <p className="fs-14 fw-bold">{user.name}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Contact details</p>
                      <p className="fs-14 fw-bold">{bookingDetail.phoneNo}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Pickup Date</p>
                      <p className="fs-14 fw-bold">
                        {bookingDetail.pickupDate}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Pickup Time</p>
                      <p className="fs-14 fw-bold">
                        {moment(bookingDetail.pickupTime, "HH:mm").format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Passengers</p>
                      <p className="fs-14 fw-bold">
                        {bookingDetail.noOfPassengers}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Vehicle</p>
                      <p className="fs-14 fw-bold">{bookingDetail.vehicle}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="textmuted">Code</p>
                      <p className="fs-14 fw-bold">
                        {bookingDetail.randomGeneratedCode}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <p className="textmuted fw-bold">Total</p>
                      <div className="d-flex align-text-top ">
                        <span className="h4">Rs.</span>
                        <span className="h4">{bookingDetail.priceForUser}</span>
                      </div>
                    </div>
                  </div>
                  {mapInfo.pickupPoint && (
                    <Fragment>
                      <div className="col-12 px-0">
                        <div className="row bg-light m-0">
                          <div className="col-12 px-4 my-4">
                            <p className="fw-bold">Visiting places</p>
                          </div>
                          <div className="col-12 px-4">
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Duration</p>
                              <p className="fs-14 fw-bold">
                                {mapInfo.duration}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Start</p>
                              <p className="fs-14 fw-bold">
                                {mapInfo.pickupPoint}
                              </p>
                            </div>
                            {mapInfo.waypoints.length ? (
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Stops</p>

                                {mapInfo.waypoints.map((way, index) => (
                                  <>
                                    <br />
                                    <p className="fs-14 fw-bold ml-2">
                                      {index + 1}.{way}
                                    </p>
                                  </>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">End</p>
                              <p className="fs-14 fw-bold">
                                {mapInfo.dropPoint}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </Fragment>
                  )}
                  {packageInfo.days && (
                    <Fragment>
                      <div className="col-12 px-0">
                        <div className="row bg-light m-0">
                          <div className="col-12 px-4 my-4">
                            <p className="fw-bold">Package Details</p>
                          </div>
                          <div className="col-12 px-4">
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Days</p>
                              <p className="fs-14 fw-bold">
                                {packageInfo.days}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Mileage</p>
                              <p className="fs-14 fw-bold">
                                {packageInfo.mileage}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Extra per KM</p>
                              <p className="fs-14 fw-bold">
                                {packageInfo.extra}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Pickup Place</p>
                              <p className="fs-14 fw-bold">
                                {packageInfo.pickupPlace}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
