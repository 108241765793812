import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  driverAcceptBookings,
  driverBookings as driverBookingAction,
  updateDriverBooking,
} from "../../actions/bookingActions";
import {
  clearBookingError,
  clearBookingUpdated,
} from "../../slices/bookingSlice";
import { toast } from "react-toastify";
import Loader from "../layouts/Loader";
import Tabs from "./Tabs";
import MetaData from "../layouts/MetaData";
import moment from "moment";

export default function NewBooking() {
  const {
    driverBooking = [],
    driverAcceptBooking = [],
    loading = true,
    error,
    isBookingUpdated = false,
  } = useSelector((state) => state.bookingState);
  const { user } = useSelector((state) => state.authState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "newerror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }
    if (isBookingUpdated) {
      toast("Booking Accepted successfully", {
        type: "success",
        toastId: "newsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearBookingUpdated()),
      });
      return;
    }

    dispatch(driverBookingAction);
    dispatch(driverAcceptBookings);
  }, [dispatch, isBookingUpdated, error]);

  const filterAccepted = driverAcceptBooking
    .filter((book) => {
      return (
        book.status.bookStatus === "Accepted" ||
        book.status.bookStatus === "Pickedup"
      );
    })
    .filter((book) => {
      return book.status.driver === user._id;
    })[0];

  // const driverVehicle=logInDriver.vehicleCategory;

  const newbook = !filterAccepted ? driverBooking : null;

  const submitHandler = (e, id) => {
    e.preventDefault();
    let bookingData = newbook.filter((book) => {
      return book._id === id;
    })[0];

    bookingData = {
      ...bookingData,
      status: {
        ...bookingData.status,
        driver: user._id,
        bookStatus: "Accepted",
        acceptedAt: new Date(),
      },
      cancelledDriver: [...bookingData.cancelledDriver],
    };

    dispatch(updateDriverBooking(id, bookingData));
  };

  return (
    <Fragment>
      <MetaData title={"new booking"} />
      <Tabs newbooking />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {newbook ? (
            <Fragment>
              <div className="row text-center">
                {newbook.map((book) => (
                  <div className="col-lg-4 col-sm-6 col-xs-12  wow fadeInUp">
                    <div className="pricing_design">
                      <div className="single-pricing bg-c-yellow">
                        <div className="price-head">
                          <h2>{book.status.bookStatus}</h2>
                          <h1>Rs.{book.priceForDriver}</h1>
                        </div>
                        <ul>
                          <li>
                            <b>{book.pickupDate}</b>/
                            <b>
                              {moment(book.pickupTime, "HH:mm").format(
                                "hh:mm A"
                              )}
                            </b>
                          </li>
                          <li>
                            <i class="fa fa-users" aria-hidden="true"></i>
                            <b>{book.noOfPassengers}</b>
                          </li>

                          {book.packageInfo ? (
                            <Fragment>
                              <li>
                                {" "}
                                <b>{book.packageInfo.days}</b> days
                              </li>
                              <li>
                                {" "}
                                <b>{book.packageInfo.mileage}</b>km free mileage
                              </li>
                              <li>
                                {" "}
                                Rs.<b>{book.packageInfo.extra}</b> extra per km
                              </li>
                              <li>
                                pickup At <b>{book.packageInfo.pickupPlace}</b>
                              </li>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <li>
                                pickup At <b>{book.mapInfo.pickupPoint}</b>
                              </li>
                              {book.mapInfo.waypoints?.map((way, index) => (
                                <li>
                                  {index + 1}.<b>{way}</b>
                                </li>
                              ))}

                              <li>
                                drop off At <b>{book.mapInfo.dropPoint}</b>
                              </li>
                            </Fragment>
                          )}
                        </ul>
                        <div className="pricing-price"></div>
                        <div className="mx-3 mt-3 mb-2">
                          <button
                            onClick={(e) => submitHandler(e, book._id)}
                            type="button"
                            className="btn btn-primary btn-block"
                          >
                            <small>Accept</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          ) : (
            <div className="alert alert-danger mt-5 text-center">
              Wait for new booking
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
