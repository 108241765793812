import { Fragment } from "react";
import MetaData from "./MetaData";
import request_delete from "../../../src/delete_request.pdf"

export default function Policy() {
  return (
    <Fragment>
      <MetaData title={"privacy & policy"} />
      <div className="policy">
        <h1>
          <b>Privacy And Policy</b>
        </h1>

        <p>
          At BRO 3 (PVT) LTD, we are committed to protecting the privacy and
          security of our user’s personal information. This Privacy Policy
          outlines how we collect, use and protect your information when you
          visit or make a booking on our website/application. By using our website/application, you
          consent to the practices mentioned in this policy.
        </p>

        <h4>
          <b>1. INFORMATION WE COLLECT</b>
        </h4>
        <p>
          When you visit our website/application, we may obtain certain information about
          you, such as:
          <ul>
            <li>
              Personal identification information ( such as name, email address
              and phone number ) provided voluntarily by you during the
              registration or checkout process.
            </li>
            <li>
              Payment and billing information necessary to process your booking,
              including bank card details, are securely handled by trusted
              third-party payment processors.
            </li>
            <li>
              Browsing information, such as your IP address, browser type, location and
              device information are collected automatically using cookies and
              similar technologies.
            </li>
          </ul>
        </p>
        <h4>
          <b>2. USE OF INFORMATION</b>
        </h4>
        <p>
          We may use the collected information for the following purposes:
          <ul>
            <li>To process and finalise your booking.</li>
            <li>
              To communicate with you regarding your booking, provide customer
              support, and respond to inquiries or requests.
            </li>
            <li>To improve our website/application and services based on your feedback.</li>
            <li>
              To detect and prevent fraud, unauthorised activities, and abuse of
              our website/application.
            </li>
          </ul>
        </p>

        <h4>
          <b>3. INFORMATION SHARING</b>
        </h4>
        <p>
          We respect your privacy and do not sell, trade, or otherwise transfer
          your personal information to third parties without your consent,
          except in the following circumstances:
          <ul>
            <li>
              Trusted service providers: We may share your information with
              third-party service providers who assist us in operating our
              website and process payments. These providers are contractually
              obliged to handle your data securely and confidentially.
            </li>
            <li>
              Legal requirements: We may disclose your information if required
              to do so by law or in response to valid legal requests.
            </li>
          </ul>
        </p>

        <h4>
          <b>4. DATA SECURITY</b>
        </h4>
        <p>
          We implement industry-standard security measures to protect your
          personal information from unauthorised access, alteration, destruction
          and disclosure. However please be aware that no method of storage is
          100% secure, and we cannot guarantee absolute security.
        </p>
        <h4>
          <b>5. DELETE MY ACCOUNT</b>
        </h4>
        <p>
          You are entitled to request us to erase any personal data we hold about you. 
          Please fill in this form and email it to us at bro3taxiservices@gmail.com, with the required documents, for us to process your erasure request.
         If you have any outstanding balance on your account, this will be taken out before we proceed with your request.
        </p>
        <p>
         Once your account is deleted it won’t be possible to restore any information connected to it, including your Bro3 Account Balance.
        </p>
        <p>
          <button className="delete_request_button">
            <a href={request_delete}>Download</a>
          </button>
        </p>
        <h4>
          <b>5. CHANGES TO THE PRIVACY POLICY</b>
        </h4>
        <p>
          We reserve the right to modify this Privacy Policy at any time. Any
          changes will be posted on this page. We encourage you to review this
          Privacy Policy periodically to stay informed about how we collect, use
          and protect your information.
        </p>
        <h4>
          <b>6. CONTACT US</b>
        </h4>
        <p>
          If you have any questions or requests regarding our Privacy Policy or
          the handling of your personal information, please feel free to contact
          us using the information provided on our website.
        </p>
      </div>
    </Fragment>
  );
}
