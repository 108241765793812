import Tabs from "./Tabs";
import { Fragment, useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Autocomplete } from "@react-google-maps/api";
import { Box, HStack, Input } from "@chakra-ui/react";
import MetaData from "../layouts/MetaData";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewDriverShare,
  getDriverShares,
} from "../../actions/shareActions";
import { toast } from "react-toastify";
import { clearShareDriverCreated } from "../../slices/shareSlice";
import { getReviews } from "../../actions/driverActions";

export default function AddShare() {
  const [sharePassenger, setSharePassenger] = useState("");
  const [shareAmount, setShareAmount] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const departureRef = useRef();
  const arriveRef = useRef();

  const { shares = [], isShareCreated } = useSelector(
    (state) => state.shareState
  );
  const { driverDetails = {} } = useSelector((state) => state.driverState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let departure = departureRef.current ? departureRef.current.value : "";
  let arrive = arriveRef.current ? arriveRef.current.value : "";

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createNewDriverShare(
        departure,
        arrive,
        departureDate,
        departureTime,
        sharePassenger,
        shareAmount
      )
    );
    navigate("/driver/acceptbooking");
  };

  useEffect(() => {
    if (isShareCreated) {
      toast("share vehicle created successfully", {
        type: "success",
        toastId: "sharedriversuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearShareDriverCreated()),
      });
      navigate("/admin/share");
      return;
    }
    dispatch(getDriverShares);
    dispatch(getReviews);
  }, [dispatch, isShareCreated]);

  return (
    <Fragment>
      <MetaData title={"Add share"} />
      <Tabs accept />
      <div id="booking" className="section">
        <div className="section-center">
          <div className="container">
            <div className="row">
              {shares && shares.length >= 1 ? (
                <div className="col-md-12 ">
                  <div className="booking-form">
                    <h1>Please delete your created share vehicle</h1>
                  </div>
                </div>
              ) : (
                <div className="col-md-12 ">
                  <div className="booking-form">
                    <form
                      onSubmit={submitHandler}
                      encType="multipart/form-data"
                    >
                      <HStack spacing={2} justifyContent="space-between">
                        <Box flexGrow={1}>
                          <span className="form-label">
                            Departure Place (From)
                          </span>
                          <Autocomplete
                            options={{
                              componentRestrictions: { country: "lk" },
                            }}
                          >
                            <Input
                              id="search"
                              type="text"
                              placeholder="Departure Place"
                              ref={departureRef}
                              required
                            />
                          </Autocomplete>
                        </Box>
                      </HStack>
                      <HStack spacing={2} justifyContent="space-between">
                        <Box flexGrow={1}>
                          <span className="form-label">Arrive Place (To)</span>
                          <Autocomplete
                            options={{
                              componentRestrictions: { country: "lk" },
                            }}
                          >
                            <Input
                              id="search"
                              type="text"
                              placeholder="Arrive Place"
                              ref={arriveRef}
                              required
                            />
                          </Autocomplete>
                        </Box>
                      </HStack>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Departure Date</span>
                            <input
                              className="form-control"
                              type="date"
                              value={departureDate}
                              onChange={(e) => setDepartureDate(e.target.value)}
                              min={new Date().toISOString().split("T")[0]}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Departure Time</span>
                            <input
                              className="form-control"
                              type="time"
                              value={departureTime}
                              onChange={(e) => setDepartureTime(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Passengers</span>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Passengers"
                              min="1"
                              max={driverDetails.maxPassengers}
                              value={sharePassenger}
                              onChange={(e) =>
                                setSharePassenger(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Price per person</span>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Price per person"
                              min="1"
                              value={shareAmount}
                              onChange={(e) => setShareAmount(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-btn">
                        <button type="submit" className="submit-btn">
                          Add share Vehicle
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
