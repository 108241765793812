import { createSlice } from "@reduxjs/toolkit";

const shareSlice = createSlice({
  name: "share",
  initialState: {
    loading: false,
    share: {},
    adminSingleShare: {},
    shares: [],
    bookshares: [],
    isShareCreated: false,
    isShareDeleted: false,
    isShareUpdated: false,
  },
  reducers: {
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearShare(state, action) {
      return {
        ...state,
        share: {},
      };
    },
    newShareDriverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newShareDriverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        share: action.payload.sharedriver,
        isShareCreated: true,
      };
    },
    newShareDriverFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isShareCreated: false,
      };
    },
    clearShareDriverCreated(state, action) {
      return {
        ...state,
        isShareCreated: false,
      };
    },
    adminShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    adminShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        shares: action.payload.shares,
      };
    },
    adminShareFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    driverShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    driverShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        shares: action.payload.shares,
      };
    },
    driverShareFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    homeShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    homeShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        shares: action.payload.shares,
      };
    },
    homeShareFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    newShareUserRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newShareUserSuccess(state, action) {
      return {
        ...state,
        loading: false,
        share: action.payload.shareuser,
        isShareCreated: true,
      };
    },
    newShareUserFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isShareCreated: false,
      };
    },
    clearShareUserCreated(state, action) {
      return {
        ...state,
        isShareCreated: false,
      };
    },
    driverBookShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    driverBookShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        bookshares: action.payload.bookshares,
      };
    },
    driverBookShareFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    adminBookShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    adminBookShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        bookshares: action.payload.bookshares,
      };
    },
    adminBookShareFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    deleteShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isShareDeleted: true,
      };
    },
    deleteShareFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isShareDeleted: false,
      };
    },
    clearShareDeleted(state, action) {
      return {
        ...state,
        isShareDeleted: false,
      };
    },
    updateShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        sharedriver: action.payload.sharedriver,
        isShareUpdated: true,
      };
    },
    updateShareFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearShareUpdated(state, action) {
      return {
        ...state,
        isShareUpdated: false,
      };
    },
    getAdminSingleShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    getAdminSingleShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        adminSingleShare: action.payload.singleShare,
      };
    },
    getAdminSingleShareFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = shareSlice;

export const {
  clearError,
  clearShare,
  newShareDriverRequest,
  newShareDriverSuccess,
  newShareDriverFail,
  clearShareDriverCreated,
  adminShareRequest,
  adminShareSuccess,
  adminShareFail,
  driverShareRequest,
  driverShareSuccess,
  driverShareFail,
  homeShareFail,
  homeShareRequest,
  homeShareSuccess,
  newShareUserRequest,
  newShareUserFail,
  newShareUserSuccess,
  clearShareUserCreated,
  driverBookShareFail,
  driverBookShareRequest,
  driverBookShareSuccess,
  adminBookShareFail,
  adminBookShareRequest,
  adminBookShareSuccess,
  deleteShareFail,
  deleteShareRequest,
  deleteShareSuccess,
  clearShareDeleted,
  updateShareFail,
  updateShareRequest,
  updateShareSuccess,
  clearShareUpdated,
  getAdminSingleShareFail,
  getAdminSingleShareRequest,
  getAdminSingleShareSuccess,
} = actions;

export default reducer;
