import { Fragment, useEffect, useState } from "react";
import Sidetoolbar from "./Sidetoolbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearDriverUpdated, clearError } from "../../slices/driverSlice";
import { getDriver, updateDriver } from "../../actions/driverActions";

export default function UpdateDriver() {
  const [name, setName] = useState("");
  const [driverEmail, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [category, setCategory] = useState("");
  const [modal, setModal] = useState("");
  const [Passengers, setPassengers] = useState("");
  const [orderAccess, setOrderAccess] = useState("");
  const [images, setImages] = useState([]);
  const [imagesCleared, setImagesCleared] = useState(false);
  const [imagesPreview, setImagesPreview] = useState([]);

  const {
    loading,
    isDriverUpdated = false,
    error,
    admindriver = {},
  } = useSelector((state) => state.driverState);
  const { id } = useParams();
  const navigate = useNavigate();

  const categories = ["van", "car", "bus","minicar","minivan","minibus"];

  //const navigate= useNavigate();
  const dispatch = useDispatch();

  const onImagesChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldArray) => [...oldArray, reader.result]);

          setImages((oldArray) => [...oldArray, file]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("driverEmail", driverEmail);
    formData.append("contactNo", contactNo);
    formData.append("vehicleNo", vehicleNo);
    formData.append("vehicleCategory", category);
    formData.append("vehicleModal", modal);
    formData.append("maxPassengers", Passengers);
    formData.append("orderAccess", orderAccess);
    images.forEach((image) => {
      formData.append("images", image);
    });
    formData.append("imagesCleared", imagesCleared);
    dispatch(updateDriver(id, formData));
   
  };

  const clearImagesHandler = () => {
    setImages([]);
    setImagesPreview([]);
    setImagesCleared(true);
  };

  useEffect(() => {
    if (isDriverUpdated) {
      toast("driver updated successfully", {
        type: "success",
        toastId: "driverupdatesuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearDriverUpdated()),
      });
      //setImages([])
      navigate("/admin/drivers");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "driverupdateerror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    dispatch(getDriver(id));
  }, [isDriverUpdated, error, dispatch, id]);

  useEffect(() => {
    if (admindriver._id) {
      setName(admindriver.name);
      setEmail(admindriver.driverEmail);
      setContactNo(admindriver.contactNo);
      setVehicleNo(admindriver.vehicleNo);
      setCategory(admindriver.vehicleCategory);
      setModal(admindriver.vehicleModal);
      setPassengers(admindriver.maxPassengers);
      setOrderAccess(admindriver.orderAccess);

      let images = [];
      admindriver.images.forEach((image) => {
        images.push(image.image);
      });
      setImagesPreview(images);
    }
  }, [admindriver]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>
      <div className="col-12 col-md-10">
        <Fragment>
          <div className="container">
            <div className="wrapper">
              <div className="title">Update Driver</div>
              <form onSubmit={submitHandler} encType="multipart/form-data">
                <div className="field">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label>Name</label>
                </div>
                <div className="field">
                  <input
                    type="email"
                    value={driverEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label>Email Address</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    required
                  />
                  <label>Vehicle No</label>
                </div>

                <div className="field">
                  <input
                    type="text"
                    value={vehicleNo}
                    onChange={(e) => setVehicleNo(e.target.value)}
                    required
                  />
                  <label>Vehicle No</label>
                </div>
                <div className="field">
                  <select
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    <option value="">Select vehicle</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={modal}
                    onChange={(e) => setModal(e.target.value)}
                    required
                  />
                  <label>Vehicle Model</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={Passengers}
                    onChange={(e) => setPassengers(e.target.value)}
                    required
                  />
                  <label>Max Passengers</label>
                </div>
                <div className="form-group">
                  <label>Images</label>

                  <div className="custom-file">
                    <input
                      type="file"
                      name="vehicle_images"
                      className="custom-file-input"
                      id="customFile"
                      multiple
                      onChange={onImagesChange}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      Choose Images
                    </label>
                  </div>
                  {imagesPreview.length > 0 && (
                    <span
                      className="mr-2"
                      onClick={clearImagesHandler}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  )}
                  {imagesPreview.map((image) => (
                    <img
                      className="mt-3 mr-2"
                      key={image}
                      src={image}
                      alt={`imagePreview`}
                      width="55"
                      height="52"
                    />
                  ))}
                </div>

                <div className="form-group">
                  <label>
                    <b>Access Orders</b>{" "}
                    <input
                      type="checkbox"
                      checked={orderAccess}
                      onChange={(e) => setOrderAccess(e.target.checked)}
                    />
                  </label>
                </div>

                <div className="field" disabled={loading}>
                  <input type="submit" value="Update" />
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
