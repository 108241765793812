import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  driverAcceptBookings as driverBookingAction,
  updateDriverBooking,
} from "../../actions/bookingActions";
import {
  clearBookingError,
  clearBookingUpdated,
} from "../../slices/bookingSlice";
import { toast } from "react-toastify";
import Tabs from "./Tabs";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";
import { deleteShare, getDriverShares } from "../../actions/shareActions";
import { Button } from "react-bootstrap";
import { clearShareDeleted } from "../../slices/shareSlice";
import moment from "moment";

export default function AcceptedBooking() {
  var CryptoJS = require("crypto-js");
  const {
    driverAcceptBooking = [],
    error,
    isBookingUpdated = false,
    encryptedCode = {},
  } = useSelector((state) => state.bookingState);
  const { user } = useSelector((state) => state.authState);
  const { shares = [], isShareDeleted } = useSelector(
    (state) => state.shareState
  );

  const [code, setCode] = useState("");
  const [reason, setReason] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteShare(id));
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "accepterror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }
    if (isBookingUpdated) {
      handleClose();
      toast("Booking Updated successfully", {
        type: "success",
        toastId: "acceptsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearBookingUpdated()),
      });
    }
    if (isShareDeleted) {
      toast("share vehicle deleted successfully", {
        type: "success",
        toastId: "sharelistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearShareDeleted()),
      });
      return;
    }

    dispatch(driverBookingAction);
    dispatch(getDriverShares);
  }, [dispatch, isBookingUpdated, error, isShareDeleted]);

  let newbook = driverAcceptBooking[0];

  const key = "pwd@1234";
  const decryptedCode = encryptedCode
    ? CryptoJS.AES.decrypt(String(encryptedCode), key).toString(
        CryptoJS.enc.Utf8
      )
    : null;

  const pickedupHandler = (e) => {
    e.preventDefault();
    let bookingData = { ...newbook };
    if (decryptedCode === code) {
      bookingData = {
        ...bookingData,
        status: {
          ...bookingData.status,
          driver: user._id,
          bookStatus: "Pickedup",
          pickedupAt: new Date(),
        },
        cancelledDriver: [...bookingData.cancelledDriver],
      };
      dispatch(updateDriverBooking(newbook._id, bookingData));
    } else {
      toast("Please Enter Correct code", {
        type: "error",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    let bookingData = { ...newbook };
    bookingData = {
      ...bookingData,
      status: {
        bookStatus: "Processing",
      },
      cancelledDriver: [
        ...bookingData.cancelledDriver,
        {
          canceldriver: user._id,
          reason: reason,
          cancelledAt: new Date(),
        },
      ],
    };
    dispatch(updateDriverBooking(newbook._id, bookingData));
  };

  const completedHandler = (e) => {
    e.preventDefault();
    let bookingData = { ...newbook };
    bookingData = {
      ...bookingData,
      status: {
        ...bookingData.status,
        bookStatus: "Completed",
        completedAt: new Date(),
      },
      cancelledDriver: [...bookingData.cancelledDriver],
    };
    dispatch(updateDriverBooking(newbook._id, bookingData));
  };

  const whatsappLink = newbook ? `https://wa.me/${newbook.phoneNo}?` : null;

  return (
    <Fragment>
      <MetaData title={"Accepted"} />
      <Tabs accept />
      <div className="mx-3 mt-3 mb-2">
        {shares && shares.length === 0 ? (
          <Link to="/driver/addshare" type="button" className="btn btn-primary">
            <small>Add Share van</small>
          </Link>
        ) : (
          <></>
        )}
        <Fragment>
          <div className="card-confirm">
            <table className="wp-table">
              {shares &&
                shares.map((book) => (
                  <tr>
                    <td>{book.departure}</td>
                    <td>{book.arrive}</td>
                    <td>{book.departureDate}</td>
                    <td>
                      {moment(book.departureTime, "HH:mm").format("hh:mm A")}
                    </td>
                    <td>Rs.{book.shareAmount}</td>
                    <td>{book.sharePassenger}</td>
                    <td>
                      <Link
                        to={`/driver/viewshare/${book._id}`}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-eye"></i>
                      </Link>
                    </td>
                    <td>
                      <Button className="btn btn-danger py-1 px-2 ml-2">
                        <i
                          className="fa fa-trash"
                          onClick={(e) => deleteHandler(e, book._id)}
                        ></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </Fragment>
      </div>
      {newbook ? (
        <div className="card-confirm">
          <div className="container">
            <div className="row m-0">
              <Fragment>
                <div className="col-lg-7 pb-5 pe-lg-5">
                  <div className="row">
                    <div className="col-12 p-5">
                      <img
                        src="/images/taxidriver.jpg"
                        style={{ height: "auto", width: "100%" }}
                        alt=""
                      />
                    </div>
                    <div className="row m-0 bg-light">
                      <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                        <p className="text-muted">Name</p>
                        <p className="h6">{newbook.user.name}</p>
                      </div>
                      <div className="col-md-4 col-6  ps-30 my-4">
                        <p className="text-muted">Vehcile</p>
                        <p className="h6 m-0">{newbook.vehicle}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Contact No</p>
                        <p className="h6 m-0">{newbook.phoneNo}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Passengers</p>
                        <p className="h6 m-0">{newbook.noOfPassengers}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Pickup Date</p>
                        <p className="h6 m-0">{newbook.pickupDate}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Pickup Time</p>
                        <p className="h6 m-0">
                          {moment(newbook.pickupTime, "HH:mm").format(
                            "hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 p-0 ps-lg-4">
                  <div className="row m-0">
                    <div className="col-12 px-4">
                      <div className="d-flex align-items-end mt-4 mb-2">
                        <p className="h4 m-0">
                          <span className="pe-1">Trip Details</span>
                        </p>
                      </div>

                      <div className="d-flex justify-content-between mb-3">
                        <p className="textmuted fw-bold">Total</p>
                        <div className="d-flex align-text-top ">
                          <span className="h4">
                            Rs.{newbook.priceForDriver}
                          </span>
                        </div>
                      </div>
                    </div>

                    {newbook.packageInfo ? (
                      <div className="col-12 px-0">
                        <div className="row bg-light m-0">
                          <div className="col-12 px-4">
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Days</p>
                              <p className="fs-14 fw-bold">
                                {newbook.packageInfo.days}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Mileage</p>
                              <p className="fs-14 fw-bold">
                                {newbook.packageInfo.mileage} km
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Extra per km</p>
                              <p className="fs-14 fw-bold">
                                Rs.{newbook.packageInfo.extra}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Pickup place</p>
                              <p className="fs-14 fw-bold">
                                {newbook.packageInfo.pickupPlace}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 px-0">
                        <div className="row bg-light m-0">
                          <div className="col-12 px-4">
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Duration</p>
                              <p className="fs-14 fw-bold">
                                {newbook.mapInfo.duration}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">Start</p>
                              <p className="fs-14 fw-bold">
                                {newbook.mapInfo.pickupPoint}
                              </p>
                            </div>
                            {newbook.mapInfo.waypoints?.length ? (
                              <div className="d-flex justify-content-between mb-2">
                                <p className="text-muted">Stops</p>

                                {newbook.mapInfo.waypoints.map((way, index) => (
                                  <>
                                    <br />
                                    <p className="fs-14 fw-bold ml-2">
                                      {index + 1}.{way}
                                    </p>
                                  </>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="d-flex justify-content-between mb-2">
                              <p className="text-muted">End</p>
                              <p className="fs-14 fw-bold">
                                {newbook.mapInfo.dropPoint}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      {newbook.status.bookStatus === "Accepted" && (
                        <div className="mx-3 mt-3 mb-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => setCode(e.target.value)}
                              placeholder="Enter your Picked up code"
                              required
                            />
                          </div>

                          <div className="mx-3 mt-3 mb-2">
                            {" "}
                            <button
                              className="btn btn-primary btn-block"
                              onClick={pickedupHandler}
                            >
                              Picked up
                            </button>
                          </div>
                          <div className="mx-3 mt-3 mb-2">
                            <button
                              onClick={handleShow}
                              type="button"
                              className="btn btn-danger btn-block"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                            >
                              cancel
                            </button>
                          </div>
                          <div
                            className="modal fade"
                            id="exampleModalCenter"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Reason
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    aria-hidden="true"
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    placeholder="Please Give Reason"
                                    required
                                  />
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    disabled={!reason}
                                    onClick={cancelHandler}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Link to={whatsappLink} target="_blank">
                              <span className="btn-label">Customer Number</span>
                              <img
                                src="/images/whatsapp.png"
                                alt="whatsapp"
                                className="whatsapp_float"
                              />
                            </Link>
                          </div>
                        </div>
                      )}
                      {newbook.status.bookStatus === "Pickedup" && (
                        <div>
                          <div className="mx-3 mt-3 mb-2">
                            {" "}
                            <button
                              className="btn btn-success btn-block"
                              onClick={completedHandler}
                            >
                              Completed
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      ) : (
        <div className="alert alert-danger mt-5 text-center">
          Please accept bookings
        </div>
      )}
    </Fragment>
  );
}
