import axios from "axios";
import {
  contactFail,
  contactRequest,
  contactSuccess,
  deleteContactFail,
  deleteContactRequest,
  deleteContactSuccess,
  newContactFail,
  newContactRequest,
  newContactSuccess,
} from "../slices/contactSlice";

//create new customs
export const createNewContact =
  (userName, userEmail, userMessage) => async (dispatch) => {
    try {
      dispatch(newContactRequest());

      const { data } = await axios.post(`/api/v1/contact/new`, {
        userName,
        userEmail,
        userMessage,
      });

      dispatch(newContactSuccess(data));
    } catch (error) {
      //handle error
      dispatch(newContactFail(error.response.data.message));
    }
  };

//get admin customs
export const getContacts = async (dispatch) => {
  try {
    dispatch(contactRequest());

    const { data } = await axios.get(`/api/v1/admin/contacts`);

    dispatch(contactSuccess(data));
  } catch (error) {
    //handle error
    dispatch(contactFail(error.response.data.message));
  }
};

//delete driver
export const deleteContact = (id) => async (dispatch) => {
  try {
    dispatch(deleteContactRequest());

    await axios.delete(`/api/v1/admin/contact/${id}`);

    dispatch(deleteContactSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteContactFail(error.response.data.message));
  }
};
