import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './slices/userSlice';
import authReducer from './slices/authSlice';
import driverReducer from './slices/driverSlice';
import bookingReducer from './slices/bookingSlice';
import customReducer from './slices/customSlice';
import packageReducer from './slices/packageSlice';
import contactReducer from './slices/contactSlice';
import shareReducer from './slices/shareSlice';

const reducer = combineReducers({
    userState: userReducer,
    authState: authReducer,
    driverState: driverReducer,
    bookingState:bookingReducer,
    customState:customReducer,
    packageState:packageReducer,
    contactState:contactReducer,
    shareState:shareReducer
})



//state access

const store = configureStore({
    reducer,
    middleware: [thunk]
});


export default store;
