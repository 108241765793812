import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminBookingDetailActions } from "../../actions/bookingActions";
import { Link, useParams } from "react-router-dom";
import { getDrivers } from "../../actions/driverActions";
import moment from "moment";

export default function SingleBooking() {
  const { adminBookingDetail } = useSelector((state) => state.bookingState);
  const {
    mapInfo = {},
    status = {},
    user = {},
    packageInfo = {},
  } = adminBookingDetail;
  const { drivers = [] } = useSelector((state) => state.driverState);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminBookingDetailActions(id));
    dispatch(getDrivers);
  }, [dispatch]);

  const driverId = drivers.filter((driverData) => {
    return driverData.user === status.driver;
  });

  return (
    <Fragment>
      <div className="card-confirm">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-7 pb-5 pe-lg-5">
              <div className="row">
                <div className="col-12 p-5">
                  <img src="/images/taxidriver.jpg" alt="" />
                </div>

                <div className="col-md-2 col-6">
                  <img
                    src={user.avatar ?? "/images/default_avatar.png"}
                    alt="profile"
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>

                <div className="row m-0 bg-light">
                  <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                    <p className="text-muted">Name</p>
                    <p className="h5">{user.name}</p>
                  </div>
                  <div className="col-md-4 col-6  ps-30 my-4">
                    <p className="text-muted">Email</p>
                    <p className="h6 m-0">{user.email}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Contact No</p>
                    <p className="h5 m-0">{adminBookingDetail.phoneNo}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Pickup Date</p>
                    <p className="h6 m-0">{adminBookingDetail.pickupDate}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Pickup Time</p>
                    <p className="h5 m-0">{adminBookingDetail.pickupTime}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Passengers</p>
                    <p className="h5 m-0">
                      {adminBookingDetail.noOfPassengers}
                    </p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">status</p>
                    <p className="h5 m-0">{status.bookStatus}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Driver</p>
                    <p className="h6 m-0">{status.driver}</p>
                  </div>
                  {driverId &&
                    driverId.map((driver) => (
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">View Driver</p>
                        <Link
                          to={`/admin/singledriver/${driver._id}`}
                          className="btn btn-primary py-1 px-2 ml-2"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="col-lg-5 p-0 ps-lg-4">
              <div className="row m-0">
                <div className="col-12 px-4">
                  <div className="d-flex align-items-end mt-4 mb-2">
                    <p className="h4 m-0">
                      <span className="pe-1">Trip Details</span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Id</p>
                    <p className="fs-14 fw-bold">{adminBookingDetail._id}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">created at</p>
                    <p className="fs-14 fw-bold">
                      {moment(adminBookingDetail.createdAt).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">accepted at</p>
                    <p className="fs-14 fw-bold">
                      {status.acceptedAt
                        ? moment(status.acceptedAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )
                        : null}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">pickedup at</p>
                    <p className="fs-14 fw-bold">
                      {status.pickedupAt
                        ? moment(status.pickedupAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )
                        : null}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">completed at</p>
                    <p className="fs-14 fw-bold">
                      {status.completedAt
                        ? moment(status.completedAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )
                        : null}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">updated date/time at</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.updatedAt
                        ? moment(adminBookingDetail.updatedAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )
                        : null}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">NIC</p>
                    <p className="fs-14 fw-bold">{adminBookingDetail.nic}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Vehicle</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.vehicle}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Code</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.randomGeneratedCode}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Price for User</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.priceForUser}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Price for Driver</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.priceForDriver}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="textmuted fw-bold">Profit</p>
                    <div className="d-flex align-text-top ">
                      <span className="h6">
                        {adminBookingDetail.priceForUser -
                          adminBookingDetail.priceForDriver}
                      </span>
                    </div>
                  </div>
                </div>
                {adminBookingDetail.packageInfo ? (
                  <div className="col-12 px-0">
                    <div className="row bg-light m-0">
                      <div className="col-12 px-4 my-4">
                        <p className="fw-bold">Package Info</p>
                      </div>
                      <div className="col-12 px-4">
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Days</p>
                          <p className="fs-14 fw-bold">{packageInfo.days}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Mileage</p>
                          <p className="fs-14 fw-bold">{packageInfo.mileage}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">extra per hour</p>
                          <p className="fs-14 fw-bold">{packageInfo.extra}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Pickup place</p>
                          <p className="fs-14 fw-bold">
                            {packageInfo.pickupPlace}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 px-0">
                    <div className="row bg-light m-0">
                      <div className="col-12 px-4 my-4">
                        <p className="fw-bold">Visiting places</p>
                      </div>
                      <div className="col-12 px-4">
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Duration</p>
                          <p className="fs-14 fw-bold">{mapInfo.duration}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Distance</p>
                          <p className="fs-14 fw-bold">{mapInfo.distance}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Start</p>
                          <p className="fs-14 fw-bold">{mapInfo.pickupPoint}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">End</p>
                          <p className="fs-14 fw-bold">{mapInfo.dropPoint}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Stops</p>
                          <p className="fs-14 fw-bold">{mapInfo.waypoints}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
