import { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuBar from "../booking/MenuBar";
import CheckOut from "./CheckOut";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesUser } from "../../actions/packageActions";
import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";

export default function CardPackage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packageUser = [], loading } = useSelector(
    (state) => state.packageState
  );

  useEffect(() => {
    dispatch(getPackagesUser);
  }, [dispatch]);

  const handleSubmit = (e, id) => {
    e.target.disabled = true;
    const filterData = packageUser.filter((pack) => {
      return pack._id === id;
    })[0];

    let discount = 0.08;
    const days = filterData.days;
    const mileage = filterData.mileage;
    const extra = filterData.extra;
    //discount
    const priceForUser = Math.ceil(
      filterData.userAmount
    );
    const noOfPassengers = filterData.passengers;
    const vehicle = filterData.vehiclePackage;
    const data = {
      id,
      days,
      mileage,
      extra,
      priceForUser,
      noOfPassengers,
      vehicle,
    };

    sessionStorage.setItem("packageInfo", JSON.stringify(data));
    navigate("/booking");
  };

  return (
    <Fragment>
      <MetaData title={"Packages"} />
      <MenuBar mypackage />
      <CheckOut packages />

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="container-package">
            <h1 className="heading">Available Packages</h1>

            <div className="card_group">
              {packageUser.map((packdata) => (
                <div className="pricing-card" key={packdata._id}>
                  {(packdata.vehiclePackage === "car" ||
                    packdata.vehiclePackage === "minicar") && (
                    <i className="fas fa-car"></i>
                  )}
                  {(packdata.vehiclePackage === "van" ||
                    packdata.vehiclePackage === "minivan") && (
                    <i className="fas fa-shuttle-van"></i>
                  )}
                  {(packdata.vehiclePackage === "bus" ||
                    packdata.vehiclePackage === "minibus") && (
                    <i className="fas fa-bus-alt"></i>
                  )}

                  <h4 className="price">{packdata.vehiclePackage}</h4>
                  <h4 className="pricetotal">
                    Rs.{packdata.userAmount}/day{" "}
                  </h4>
                  {/*
                  <h4 className="pricestrike">
                    <strike>Rs.{packdata.userAmount}/day</strike>{" "}
                    <span className="offer">-8%</span>
                  </h4>
                */}

                  <ul className="package_list">
                    <li>Free Mileage/day : {packdata.mileage} km </li>
                    <li>(Two-way)</li>
                    <li>Extra per KM : Rs.{packdata.extra}</li>
                    <li>Passengers : {packdata.passengers}</li>
                    <li>24/7 Support</li>
                  </ul>
                  <Link
                    to="/packageform"
                    className="get_started_btn"
                    onClick={(e) => handleSubmit(e, packdata._id)}
                  >
                    book me
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
