import { Fragment, useEffect, useState } from "react";
import Carousel from "./layouts/Carousel";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Aos from "aos";
import "aos/dist/aos.css";
import { createNewContact } from "../actions/contactActions";
import { clearContactCreated, clearError } from "../slices/contactSlice";
import { toast } from "react-toastify";
import PackageCard from "./layouts/PackageCard";
import FrontMap from "./layouts/FrontMap";

export default function Home() {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const { isContactCreated = false, error } = useSelector(
    (state) => state.contactState
  );
  const { isAuthenticated, user } = useSelector((state) => state.authState);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(createNewContact(userName, userEmail, userMessage));
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });

    if (isContactCreated) {
      toast("Your messege was sent successfully", {
        type: "success",
        toastId: "contactcreate",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearContactCreated()),
      });
      setUserName("");
      setUserEmail("");
      setUserMessage("");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "homeerror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
  }, [isContactCreated, error, dispatch]);

  return (
    <Fragment>
      <div className="hero" id="home">
        <div className="logoImg">
          <img
            src="/images/backgroundlogo.png"
            alt="logo"
            className="logo-img"
          />
        </div>
        <div className="content">
          <div className="logoImg">
            <img
              src="/images/vanfront.png"
              alt="vehicle"
              className="logo-car"
              data-aos="fade-down-left"
              style={{ height: 200, width: 300, marginTop: 0 }}
            />
          </div>

          <h1 className="anim">
            <b>SRI LANKA TAXI SERVICES</b>
          </h1>
          <h3 className="anim">
            <b>Easy, Fast And Safe Services</b>
          </h3>
          <h1 className="anim">
            <b>+94760667724</b>
          </h1>

          <div className="start-btn">
            {isAuthenticated && user.role === "user" ? (
              <Link to="/map" className="btn-get anim">
                Get Started <i className="fa fa-angle-right"></i>
              </Link>
            ) : isAuthenticated && user.role === "driver" ? (
              <Link to="/driver/newbooking" className="btn-get anim">
                Get Started <i className="fa fa-angle-right"></i>
              </Link>
            ) : isAuthenticated && user.role === "admin" ? (
              <Link to="/admin/dashboard" className="btn-get anim">
                Get Started <i className="fa fa-angle-right"></i>
              </Link>
            ) : (
              <Link to="/login" className="btn-get anim">
                Get Started <i className="fa fa-angle-right"></i>
              </Link>
            )}
            <Link to="/sharevehicle" className="btn-get anim ">
              Book Sharing Vehicle <i className="fa fa-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
      

      {/* 
      <div className="banner-discount"></div>
      */}

      <FrontMap />

      <Carousel />

      <div className="details" id="about">
        <div className="one" data-aos="fade-left">
          <h3>How to Use Our Services</h3>
          <h6>1.Register to our service</h6>
          <h6>2.Book your Journey</h6>
          <h6>3.Travel with us</h6>

          {isAuthenticated && user.role === "user" ? (
            <Link to="/map" className="btn-about">
              Book now
            </Link>
          ) : isAuthenticated && user.role === "driver" ? (
            <Link to="/driver/newbooking" className="btn-about">
              Book now
            </Link>
          ) : isAuthenticated && user.role === "admin" ? (
            <Link to="/admin/dashboard" className="btn-about">
              Book now
            </Link>
          ) : (
            <Link to="/login" className="btn-about">
              Book now
            </Link>
          )}
        </div>
        <div className="two" data-aos="fade-up">
          <h3>Our Services</h3>
          <br />
          <h6>
            <span>&#9755;</span>Airport Pick up & Drop off
          </h6>
          <h6>
            <span>&#9755;</span>Trips to different tourist attractions
          </h6>
          <h6>
            <span>&#9755;</span>Event Hire Services
          </h6>
          <h6>
            <span>&#9755;</span>Other different packages are also available
          </h6>
        </div>
      </div>

      <PackageCard />

      <div className="demo1">
        <div className="process1" data-aos="fade-up">
          <h3>How to make a booking</h3>
          <h6>1.Click on 'Get Started'</h6>
          <h6>
            2.If you do not have an account, click on 'Sign Up', to create an
            account
          </h6>
          <h6>3.Fill out all the details, and click on 'Register'</h6>
          <h6>
            4.Then book your trip, by filling out the pick up and drop off
            places
          </h6>
          <h6>5.You can add stops by clicking on the '+' sign</h6>
          <h6>
            6.To make your reservation, you must fill out the booking details.
          </h6>
          <h6>
            7.Then, you will get a summary of your booking, and will be asked to
            pay the total amount.
          </h6>
          <h6>8.Finally, you pay for the booking.</h6>
        </div>
        <div className="frame1" data-aos="fade-up">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/-HCveqJbByM?si=WL8qYDhE246ZzpgW"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className="trainer">
        <div className="about">
          <h2 data-aos="fade-up">Hello,</h2>
          <p data-aos="fade-up">
            Book us to travel around Sri Lanka, hassle-free. You do not need to
            worry about travel when you book your holiday to Sri Lanka because
            we have got you sorted.
            <br />
            <br />
            Our service ensures that you have the best possible experience.
          </p>
        </div>
        <div className="people">
          <div className="team1">
            <div className="teamDetails" data-aos="fade-left">
              <div className="name">
                <h4>24/7 SERVICE</h4>
              </div>
            </div>
            <div className="teamDetails" data-aos="fade-left">
              <div className="name">
                <h4>100% TRUSTWORTHY</h4>
              </div>
            </div>
            <div className="teamDetails" data-aos="fade-left">
              <div className="name">
                <h4>EASY TO BOOK</h4>
              </div>
            </div>
          </div>

          <div className="team2">
            <div className="teamDetails" data-aos="fade-left">
              <div className="name">
                <h4>FAST RELIABLE SERVICE</h4>
              </div>
            </div>
            <div className="teamDetails" data-aos="fade-left">
              <div className="name">
                <h4>100% CUSTOMER SATISFACTION</h4>
              </div>
            </div>
            <div className="teamDetails" data-aos="fade-left">
              <div className="name">
                <h4>GREAT SERVICE AT LOW RATES</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="demo2">
        <div className="process2" data-aos="fade-up">
          <h3>How to register as a driver</h3>
          <h6>1.Click on 'Get Started'</h6>
          <h6>
            2.If you do not have an account, click on 'Sign Up', to create an
            account
          </h6>
          <h6>
            3.Fill out all the details, and remember to click 'yes' to register
            as a driver.
          </h6>
          <h6>4.You will receive an email with the registration form.</h6>
          <h6>5.Fill in the form.</h6>
          <h6>6.Agree to the terms and conditions</h6>
          <h6>7.Finally submit the form.</h6>
        </div>
        <div className="frame2" data-aos="fade-up">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Ik3F0NeuPlo?si=61VSdJNpnE_Ad-nS"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div id="class">
        <h2 className="h2" data-aos="fade-up">
          DIFFERENT KINDS OF TRANSPORT AVAILABLE
        </h2>
        <div className="row" data-aos="fade-up">
          <div className="team1">
            <img src="/images/carblock.png" alt="car" />
            <h4>Cars</h4>
            <p>
              We have car options available. This would be the best option for
              families, when travelling to different tourist attractions in Sri
              Lanka.
            </p>
          </div>

          <div className="team1">
            <img src="/images/van.jpg" alt="van" />
            <h4>Vans</h4>
            <p>
              There are van options available as well. This would be the best
              option when you need lots of luggage space, and comfy seating.
            </p>
          </div>

          <div className="team1">
            <img src="/images/busblock.png" alt="bus" />
            <h4>Buses</h4>
            <p>
              There are buses available as well. If there is a large number of
              people and you want to sightsee together, you can book a bus and
              travel together.
            </p>
          </div>
        </div>
      </div>

      <div className="demo3">
        <div className="process3" data-aos="fade-up">
          <h3>How to make a share booking</h3>
          <h6>1.Click on 'Book Sharing Vehicle'</h6>
          <h6>2.You can select any of the bookings that are available</h6>
          <h6>3.Then fill out the form with all the required details.</h6>
          <h6>
            4.Finally, you will get a booking confirmation sent to your email.
          </h6>
          <h6>
            5.Give the payment to the driver directly (sharing vehicle payments
            are decided by the drivers).
          </h6>
        </div>
        <div className="frame3" data-aos="fade-up">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/eqCZS2iMJis?si=NHRJah7Iek4ATj3R"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className="contact" id="contact">
        <div className="form">
          <h2 data-aos="fade-up">Contact Us</h2>
          <div className="submit" data-aos="fade-up">
            <input
              type="text"
              name=""
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Name"
            />
            <input
              type="email"
              name=""
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <textarea
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Message"
              required
            ></textarea>
            <button
              className="btn-send"
              disabled={!userEmail && !userMessage}
              onClick={handleSubmit}
            >
              Send Message
            </button>
          </div>
        </div>
        <div className="map" data-aos="fade-up">
          <div className="contact-box">
            <h1>
              <a className="phone-no">
                <i class="fa fa-map-marker" aria-hidden="true"></i> 24/B
                Alehiwatte Road, Welisara, Ragama
              </a>
            </h1>
            <h1>
              <a href="tel:+94760667724" className="phone-no">
                <i className="fa fa-phone" aria-hidden="true"></i> +94760667724
              </a>
            </h1>
            <h1>
              <a href="mailto:bro3taxiservices@gmail.com" className="phone-no">
                <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                bro3taxiservices@gmail.com
              </a>
            </h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
