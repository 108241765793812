import { Fragment, useEffect, useState } from "react";
import Sidetoolbar from "./Sidetoolbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser, updateUser } from "../../actions/userActions";
import { clearUserError, clearUserUpdated } from "../../slices/userSlice";

export default function UpdateUser() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [suspendDriver, setSuspendDriver] = useState(false);

  const {
    loading,
    isUserUpdated = false,
    error,
    user = {},
  } = useSelector((state) => state.userState);
  const { user: authUser } = useSelector((state) => state.authState);
  const { id: userId } = useParams();

  //const navigate= useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("role", role);
    formData.append("suspendDriver", suspendDriver);

    dispatch(updateUser(userId, formData));
    
  };

  useEffect(() => {
    if (isUserUpdated) {
      toast("user updated successfully", {
        type: "success",
        toastId: "userupdatesuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearUserUpdated()),
      });
      navigate("/admin/users");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "userupdateerror",
        onOpen: () => {
          dispatch(clearUserError());
        },
      });
      return;
    }
    dispatch(getUser(userId));
  }, [isUserUpdated, error, dispatch]);

  useEffect(() => {
    if (user._id) {
      setName(user.name);
      setEmail(user.email);
      setRole(user.role);
      setSuspendDriver(user.suspendDriver);
    }
  }, [user]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>
      <div className="col-12 col-md-10">
        <Fragment>
          <div className="container">
            <div className="wrapper">
              <div className="title">Update Profile</div>
              <form onSubmit={submitHandler} encType="multipart/form-data">
                <div className="field">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label>Name</label>
                </div>
                <div className="field">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label>Email Address</label>
                </div>

                <div className="field">
                  <select
                    disabled={user._id === authUser._id}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="admin">Admin</option>
                    <option value="driver">Driver</option>
                    <option value="user">User</option>
                  </select>
                </div>

                <div className="content">
                  <div className="checkbox">
                    <label>Suspend Driver</label>
                    <input
                      type="checkbox"
                      checked={suspendDriver}
                      onChange={(e) => setSuspendDriver(e.target.checked)}
                    />
                  </div>
                </div>

                <div className="field" disabled={loading}>
                  <input type="submit" value="Update" />
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
