import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminBookingDetailActions,
  updateAdminBooking,
} from "../../actions/bookingActions";
import { Link, useParams } from "react-router-dom";
import { getDrivers } from "../../actions/driverActions";
import {
  clearAdminBookingUpdated,
  clearBookingError,
} from "../../slices/bookingSlice";
import { toast } from "react-toastify";

export default function UpdateBooking() {
  const { adminBookingDetail, error, isBookingUpdated } = useSelector(
    (state) => state.bookingState
  );
  const {
    mapInfo = {},
    status = {},
    user = {},
    packageInfo = {},
  } = adminBookingDetail;
  const { drivers = [] } = useSelector((state) => state.driverState);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [driver, setDriver] = useState("");
  const [bookStatus, setBookStatus] = useState("");
  const [driverPayment, setDriverPayment] = useState("");

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "bookupdateerror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }
    if (isBookingUpdated) {
      toast("Booking updated successfully", {
        type: "success",
        toastId: "bookupdatesuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearAdminBookingUpdated()),
      });
      return;
    }

    dispatch(adminBookingDetailActions(id));
    dispatch(getDrivers);
  }, [dispatch, isBookingUpdated]);

  useEffect(() => {
    if (adminBookingDetail._id) {
      setDriverPayment(adminBookingDetail.driverPayment);
    }
  }, [adminBookingDetail]);

  const driverId = drivers.filter((driverData) => {
    return driverData.user === status.driver;
  });

  const submitHandler = (e) => {
    e.preventDefault();
    let bookingData = adminBookingDetail;

    if (bookStatus === "Processing") {
      bookingData = {
        ...bookingData,
        status: {
          bookStatus: bookStatus,
        },
        cancelledDriver: [
          ...bookingData.cancelledDriver,
          {
            canceldriver: user._id,
            reason: "Admin Cancelled",
            cancelledAt: new Date(),
          },
        ],
      };
    }
    if (bookStatus === "Accepted") {
      bookingData = {
        ...bookingData,
        status: {
          ...bookingData.status,
          driver: driver,
          bookStatus: bookStatus,
          acceptedAt: new Date(),
        },
        cancelledDriver: [...bookingData.cancelledDriver],
      };
    }

    dispatch(updateAdminBooking(id, bookingData));
  };

  const submitPaidHandler = (e) => {
    e.preventDefault();
    let bookingData = adminBookingDetail;

    bookingData = {
      ...bookingData,
      driverPayment: driverPayment,
    };

    dispatch(updateAdminBooking(id, bookingData));
  };

  return (
    <Fragment>
      <div className="card-confirm">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-7 pb-5 pe-lg-5">
              <div className="row">
                <div className="col-12 p-5">
                  <img src="/images/taxidriver.jpg" alt="" />
                </div>

                <div className="col-md-2 col-6">
                  <img
                    src="/images/default_avatar.png"
                    alt="profile"
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>

                <div className="row m-0 bg-light">
                  <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                    <p className="text-muted">Name</p>
                    <p className="h5">{user.name}</p>
                  </div>
                  <div className="col-md-4 col-6  ps-30 my-4">
                    <p className="text-muted">Email</p>
                    <p className="h6 m-0">{user.email}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Contact No</p>
                    <p className="h5 m-0">{adminBookingDetail.phoneNo}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Pickup Date</p>
                    <p className="h6 m-0">{adminBookingDetail.pickupDate}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Pickup Time</p>
                    <p className="h5 m-0">{adminBookingDetail.pickupTime}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Passengers</p>
                    <p className="h5 m-0">
                      {adminBookingDetail.noOfPassengers}
                    </p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">status</p>
                    <p className="h5 m-0">{status.bookStatus}</p>
                  </div>
                  <div className="col-md-4 col-6 ps-30 my-4">
                    <p className="text-muted">Driver</p>
                    <p className="h6 m-0">{status.driver}</p>
                  </div>
                  {driverId &&
                    driverId.map((driver) => (
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">View Driver</p>
                        <Link
                          to={`/admin/singledriver/${driver._id}`}
                          className="btn btn-primary py-1 px-2 ml-2"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </div>
                    ))}
                </div>
                <div>
                  <form onSubmit={submitHandler} encType="multipart/form-data">
                    <div className="field">
                      <select
                        value={bookStatus}
                        onChange={(e) => setBookStatus(e.target.value)}
                        disabled={
                          status.bookStatus === "Completed" ||
                          status.bookStatus === "Pickedup"
                        }
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Processing">Processing</option>
                        <option value="Accepted">Accepted</option>
                      </select>
                    </div>

                    <div className="field">
                      <select
                        value={driver}
                        onChange={(e) => setDriver(e.target.value)}
                        disabled={
                          bookStatus === "Processing" ||
                          status.bookStatus === "Completed" ||
                          status.bookStatus === "Pickedup"
                        }
                        required
                      >
                        <option value="">Select Driver</option>
                        {drivers &&
                          drivers.map((driverInfo) => (
                            <option value={driverInfo.user}>
                              {driverInfo.driverEmail}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="field">
                      <input type="submit" value="Update" />
                    </div>
                  </form>
                </div>
                <div className="ml-5">
                  <form
                    onSubmit={submitPaidHandler}
                    encType="multipart/form-data"
                  >
                    <div className="field">
                      <select
                        value={driverPayment}
                        onChange={(e) => setDriverPayment(e.target.value)}
                        disabled={adminBookingDetail.driverPayment === "paid"}
                        required
                      >
                        <option value="paid">Paid</option>
                        <option value="unpaid">UnPaid</option>
                      </select>
                    </div>

                    <div className="field">
                      <input
                        type="submit"
                        value="Update"
                        disabled={status.bookStatus !== "Completed"}
                        id="update-btn"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-5 p-0 ps-lg-4">
              <div className="row m-0">
                <div className="col-12 px-4">
                  <div className="d-flex align-items-end mt-4 mb-2">
                    <p className="h4 m-0">
                      <span className="pe-1">Trip Details</span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Id</p>
                    <p className="fs-14 fw-bold">{adminBookingDetail._id}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">created at</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.createdAt}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">accepted at</p>
                    <p className="fs-14 fw-bold">{status.acceptedAt}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">pickedup at</p>
                    <p className="fs-14 fw-bold">{status.pickedupAt}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">completed at</p>
                    <p className="fs-14 fw-bold">{status.completedAt}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">NIC</p>
                    <p className="fs-14 fw-bold">{adminBookingDetail.nic}</p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Vehicle</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.vehicle}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Code</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.randomGeneratedCode}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Price for User</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.priceForUser}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <p className="textmuted">Price for Driver</p>
                    <p className="fs-14 fw-bold">
                      {adminBookingDetail.priceForDriver}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p className="textmuted fw-bold">Profit</p>
                    <div className="d-flex align-text-top ">
                      <span className="h6">
                        {adminBookingDetail.priceForUser -
                          adminBookingDetail.priceForDriver}
                      </span>
                    </div>
                  </div>
                </div>
                {adminBookingDetail.packageInfo ? (
                  <div className="col-12 px-0">
                    <div className="row bg-light m-0">
                      <div className="col-12 px-4 my-4">
                        <p className="fw-bold">Package Info</p>
                      </div>
                      <div className="col-12 px-4">
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Days</p>
                          <p className="fs-14 fw-bold">{packageInfo.days}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Mileage</p>
                          <p className="fs-14 fw-bold">{packageInfo.mileage}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">extra per hour</p>
                          <p className="fs-14 fw-bold">{packageInfo.extra}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 px-0">
                    <div className="row bg-light m-0">
                      <div className="col-12 px-4 my-4">
                        <p className="fw-bold">Visiting places</p>
                      </div>
                      <div className="col-12 px-4">
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Duration</p>
                          <p className="fs-14 fw-bold">{mapInfo.duration}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Distance</p>
                          <p className="fs-14 fw-bold">{mapInfo.distance}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Start</p>
                          <p className="fs-14 fw-bold">{mapInfo.pickupPoint}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">End</p>
                          <p className="fs-14 fw-bold">{mapInfo.dropPoint}</p>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="text-muted">Stops</p>
                          <p className="fs-14 fw-bold">{mapInfo.waypoints}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
