import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import { getDriver } from "../../actions/driverActions";
import Sidetoolbar from "./Sidetoolbar";

export default function CancelDriver() {
  const { adminCancelDriver = [] } = useSelector((state) => state.driverState);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getDriver(id));
  }, [dispatch, id]);

  console.log(adminCancelDriver);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <div className="card-confirm">
          <table className="wp-table">
            <tr>
              <th>_id</th>
              <th>Reason</th>
              <th>CancelledAt</th>
            </tr>
            {adminCancelDriver &&
              adminCancelDriver.map((cancel) => (
                <tr>
                  <td>{cancel._id}</td>
                  <td>{cancel.reason}</td>
                  <td>{cancel.cancelledAt}</td>
                  <td>
                    <Link
                      to={`/admin/singlebooking/${cancel.bookId}`}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-eye"></i>
                    </Link>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}
