import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bookingCompleted } from "../../slices/bookingSlice";
import { createNewBooking } from "../../actions/bookingActions";

const PayHere = ({ orderId, name, amount, hash }) => {
  const dispatch = useDispatch();

  const mapInfo = sessionStorage.getItem("mapInfo")
    ? JSON.parse(sessionStorage.getItem("mapInfo"))
    : {};
  const { user } = useSelector((state) => state.authState);
  const { bookingInfo = {} } = useSelector(
    (state) => state.bookingState
  );

  const book = {
    mapInfo,
    phoneNo: bookingInfo.phoneNo,
    noOfPassengers: bookingInfo.noOfPassengers,
    pickupDate: bookingInfo.pickupDate,
    pickupTime: bookingInfo.pickupTime,
    vehicle: bookingInfo.vehicle,
    priceForUser: bookingInfo.priceForUser,
  };

  const navigate = useNavigate();

  // Put the payment variables here
  var payment = {
    sandbox: false, // if the account is sandbox or real
    merchant_id: "232673", // Replace your Merchant ID
    return_url: "/booking/success",
    cancel_url: "/confirm",
    notify_url: "https://www.bro3taxi.com/preapprove-notify",
    order_id: orderId,
    items: name,
    amount: amount,
    hash: hash,
    currency: "LKR",
    first_name: user.name,
    last_name: "",
    email: user.email,
    phone: bookingInfo.phoneNo,
    address: "",
    city: "",
    country: "",
    delivery_address: "", // optional field
    delivery_city: "", // optional field
    delivery_country: "", // optional field
    custom_1: "", // optional field
    custom_2: "", // optional field
  };

  // Called when user completed the payment. It can be a successful payment or failure
  window.payhere.onCompleted = function onCompleted(orderId) {
    book.paymentInfo = {
      id: orderId,
      status: "success",
    };

    console.log("Payment completed. OrderID:" + orderId);
    dispatch(createNewBooking(book));
    dispatch(bookingCompleted());
    navigate("/booking/success");
    //Note: validate the payment and show success or failure page to the customer
  };

  // Called when user closes the payment without completing
  window.payhere.onDismissed = function onDismissed() {
    //Note: Prompt user to pay again or show an error page
    navigate("/confirm");
  };

  // Called when error happens when initializing payment such as invalid parameters
  window.payhere.onError = function onError(error) {
    navigate("/booking/fail");
  };

  function pay() {
    window.payhere.startPayment(payment);
  }

  return (
    <button
      id="pay_btn"
      type="submit"
      className="btn btn-primary-pay"
      onClick={pay}
    >
      <span className="ps-3">
        Pay {`Rs.${bookingInfo && bookingInfo.priceForUser}`}
      </span>
      <span className="fas fa-arrow-right"></span>
    </button>
  );
};

export default PayHere;
