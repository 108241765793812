import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { FaTimes } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
  useJsApiLoader,
  GoogleMap,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutBar from "./CheckoutBar";
import MenuBar from "./MenuBar";
import MetaData from "../layouts/MetaData";
//import { useDispatch } from 'react-redux';
const google = window.google;

const center = { lat: 6.927079, lng: 79.861244 };

export const validateMap = (mapInfo, navigate) => {
  if (!mapInfo.pickupPoint || !mapInfo.dropPoint) {
    toast.error("Please provide pick up and drop off places", {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "validatemaperror",
    });
    navigate("/map");
  } else if (mapInfo.pickupPoint === mapInfo.dropPoint && mapInfo.count === 0) {
    toast.error(
      "As the pick up and drop off destination are the same, Please provide the places you want to go to as stop points.",
      {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "validateerormap",
      }
    );
    navigate("/map");
  } else if(mapInfo.distance<10){
    toast.error(
      "Booking distance must be above 10km",
      {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "validateerorbookdistance",
      }
    );
    navigate("/map");
  }
};

function Map() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [visit, setVisit] = useState("");
  //const [stopContainerVisible, setStopContainerVisible] = useState(false);
  const [waypointInputList, setWaypointInputList] = useState([]);
  //const [stopdata,setStopdata]=useState('');
  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const stopRef = useRef();

  //const dispatch=useDispatch()

  let stopdata;

  const handleWaypointAdd = () => {
    setWaypointInputList([...waypointInputList, { waypoints: uuidv4() }]);
    // handleClick();
  };

  const handleWaypointRemove = () => {
    // console.log(index)
    const list = [...waypointInputList];
    list.pop();
    setWaypointInputList(list);

    items.pop();

    setItems([...items]);

    if (stopRef.current) {
      stopRef.current.value = "";
    }
    calculateRoute({ waypoint_id: "" });
  };

  const handleClick = ({ waypoint_id }) => {
    stopdata = stopRef.current ? stopRef.current.value : "";

    if (stopdata !== "" && !items.includes(stopdata)) {
      if (items.find((item) => item.id === waypoint_id)) {
        items.find((item) => item.id === waypoint_id).stopdata =
          document.getElementById(waypoint_id).value;
      } else {
        items.push({ id: stopRef.current.id, stopdata: stopdata });
      }
    }
  };

  if (!isLoaded) {
    return <SkeletonText />;
  }

  async function calculateRoute({ waypoint_id }) {
    let waypts = [];

    handleClick({ waypoint_id });

    for (let i = 0; i < items.length; i++) {
      let address = items[i].stopdata;
      if (address !== "") {
        waypts.push({
          location: address,
          stopover: true,
        });
      }
    }

    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,

      //optimizeWaypoints: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: waypts,
    });
    const results1 = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,

      //optimizeWaypoints: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    let totalDistance = 0;
    let totalDuration = 0;
    let diffDistance = 0;
    let legs = results.routes[0].legs;
    for (let i = 0; i < legs.length; i++) {
      totalDistance += legs[i].distance.value;
      totalDuration += legs[i].duration.value;
    }
    diffDistance = results1.routes[0].legs[0].distance.value;

    setVisit(Math.ceil(totalDistance / 1000));

    let hour = Math.floor(totalDuration / 60 / 60);
    let minutes = totalDuration % 60;
    setDistance(Math.ceil((totalDistance + diffDistance) / 2000));
    setDuration(hour + " hrs " + minutes + " min");
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setVisit("");
    setDistance("");
    setDuration("");
    setItems([]);
    setWaypointInputList([]);

    //setStopdata('')
    originRef.current.value = "";
    destinationRef.current.value = "";
    //stopRef.current.value = ''
  }

  const handleSubmit = () => {
    const pickupPoint = originRef.current.value;
    const dropPoint = destinationRef.current.value;
    const waypoints = items.map((item) => item.stopdata);
    const count = items.length;

    const data = {
      pickupPoint,
      dropPoint,
      waypoints,
      duration,
      distance,
      count,
    };

    sessionStorage.setItem("mapInfo", JSON.stringify(data));
    navigate("/booking");
  };

  return (
    <Fragment>
      <MetaData title={"map"} />
      <MenuBar map />
      <CheckoutBar map />
      <Flex
        position="relative"
        flexDirection="column"
        alignItems="left"
        h="100vh"
        w="100vw"
      >
        <Box position="absolute" left={0} top={0} h="100%" w="100%">
          {/* Google Map Box */}
          <GoogleMap
            center={center}
            zoom={5}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        </Box>

        <Box
          p={4}
          borderRadius="lg"
          m={4}
          bgColor="white"
          shadow="base"
          zIndex="1"
          w={{ base: "300px", md: "400px", lg: "500px" }}
        >
          <HStack spacing={2} justifyContent="space-between">
            <Box flexGrow={1}>
              <Autocomplete
                onPlaceChanged={() => calculateRoute({ waypoint_id: "" })}
                options={{ componentRestrictions: { country: "lk" } }}
              >
                <Input
                  id="search"
                  type="text"
                  placeholder="Pickup Place"
                  ref={originRef}
                  required
                />
              </Autocomplete>
            </Box>
          </HStack>

          {waypointInputList.length === 0 && (
            <button id="add-btn" type="submit" onClick={handleWaypointAdd}>
              <AddCircleIcon />
            </button>
          )}
          <ul>
            {waypointInputList.map((singleWaypoint, index) => {
              return (
                <li key={index}>
                  <HStack spacing={2} justifyContent="space-between">
                    <Box flexGrow={1}>
                      <Autocomplete
                        options={{ componentRestrictions: { country: "lk" } }}
                        onPlaceChanged={() =>
                          calculateRoute({
                            waypoint_id: singleWaypoint.waypoints,
                          })
                        }
                      >
                        <Input
                          id={singleWaypoint.waypoints}
                          type="text"
                          placeholder="Stopping Place"
                          ref={stopRef}
                        />
                      </Autocomplete>
                    </Box>
                  </HStack>

                  {waypointInputList.length - 1 === index &&
                    waypointInputList.length <= 5 && (
                      <button onClick={handleWaypointRemove}>
                        <RemoveCircleIcon />
                      </button>
                    )}

                  {waypointInputList.length - 1 === index &&
                    waypointInputList.length <= 4 && (
                      <button
                        type="submit"
                        onClick={handleWaypointAdd}
                        id="add"
                      >
                        <AddCircleIcon />
                      </button>
                    )}
                </li>
              );
            })}
          </ul>
          <HStack spacing={2} justifyContent="space-between">
            <Box flexGrow={1}>
              <Autocomplete
                options={{ componentRestrictions: { country: "lk" } }}
                onPlaceChanged={() => calculateRoute({ waypoint_id: "" })}
              >
                <Input
                  id="search1"
                  type="text"
                  placeholder="Finally drop off place"
                  ref={destinationRef}
                  required
                />
              </Autocomplete>
            </Box>
          </HStack>
          <HStack spacing={4} mt={4} justifyContent="space-between">
            <Text>Distance (km): {visit} </Text>
            <Text>Duration: {duration} </Text>
          </HStack>
          <ButtonGroup spacing={40}>
            <IconButton
              aria-label="center back"
              icon={<FaTimes />}
              onClick={clearRoute}
            />
            <Button
              colorScheme="orange"
              type="submit"
              disabled={!distance && !duration}
              onClick={handleSubmit}
            >
              Next
              <ArrowForwardIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Fragment>
  );
}

export default Map;
