import axios from "axios";
import {
  customDriverFail,
  customDriverRequest,
  customDriverSuccess,
  customFail,
  customRequest,
  customSingleFail,
  customSingleRequest,
  customSingleSuccess,
  customSuccess,
  customUserFail,
  customUserRequest,
  customUserSuccess,
  deleteCustomFail,
  deleteCustomRequest,
  deleteCustomSuccess,
  newCustomFail,
  newCustomRequest,
  newCustomSuccess,
  updateCustomFail,
  updateCustomRequest,
  updateCustomSuccess,
} from "../slices/customSlice";

//create new customs
export const createNewCustom =
  (
    userMiniCar,
    userCar,
    userMiniVan,
    userVan,
    userMiniBus,
    userBus,
    driverMiniCar,
    driverCar,
    driverMiniVan,
    driverVan,
    driverMiniBus,
    driverBus
  ) =>
  async (dispatch) => {
    try {
      dispatch(newCustomRequest());

      const { data } = await axios.post(`/api/v1/admin/custom/new`, {
        userMiniCar,
        userCar,
        userMiniVan,
        userVan,
        userMiniBus,
        userBus,
        driverMiniCar,
        driverCar,
        driverMiniVan,
        driverVan,
        driverMiniBus,
        driverBus,
      });

      dispatch(newCustomSuccess(data));
    } catch (error) {
      //handle error
      dispatch(newCustomFail(error.response.data.message));
    }
  };

//get admin customs
export const getCustoms = async (dispatch) => {
  try {
    dispatch(customRequest());

    const { data } = await axios.get(`/api/v1/admin/custom`);

    dispatch(customSuccess(data));
  } catch (error) {
    //handle error
    dispatch(customFail(error.response.data.message));
  }
};

//delete driver
export const deleteCustom = (id) => async (dispatch) => {
  try {
    dispatch(deleteCustomRequest());

    await axios.delete(`/api/v1/admin/custom/${id}`);

    dispatch(deleteCustomSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteCustomFail(error.response.data.message));
  }
};

//get driver customs
export const getCustomsDriver = async (dispatch) => {
  try {
    dispatch(customDriverRequest());

    const { data } = await axios.get(`/api/v1/driver/custom`);

    dispatch(customDriverSuccess(data));
  } catch (error) {
    //handle error
    dispatch(customDriverFail(error.response.data.message));
  }
};

//get driver customs
export const getCustomsUser = async (dispatch) => {
  try {
    dispatch(customUserRequest());

    const { data } = await axios.get(`/api/v1/user/custom`);

    dispatch(customUserSuccess(data));
  } catch (error) {
    //handle error
    dispatch(customUserFail(error.response.data.message));
  }
};


export const getSingleCustom = (id) => async (dispatch) => {
  try {
    dispatch(customSingleRequest());

    const { data } = await axios.get(`/api/v1/admin/custom/${id}`);

    dispatch(customSingleSuccess(data));
  } catch (error) {
    //handle error
    dispatch(customSingleFail(error.response.data.message));
  }
};

//Admin-update custom
export const updateCustom = (id, customData) => async (dispatch) => {
  try {
    dispatch(updateCustomRequest());
    const config = {
      headers: {
        "Content-type": "application/json", //without image
      },
    };

    const { data } = await axios.put(`/api/v1/admin/custom/${id}`, customData,config);

    dispatch(updateCustomSuccess(data));
  } catch (error) {
    //handle error
    dispatch(updateCustomFail(error.response.data.message));
  }
};