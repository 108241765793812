import { useState, useEffect, Fragment } from "react";
import { resetPassword, clearAuthError } from "../../actions/userActions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MetaData from "../layouts/MetaData";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.authState);

  const { token } = useParams();

  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const toggle1 = () => {
    setVisible1(!isVisible1);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("password", password);
    formData.append("confirmPassword", confirmPassword);
    dispatch(resetPassword(formData, token));
  };

  useEffect(() => {
    if (isAuthenticated) {
      toast("Reset Password successfully", {
        type: "success",
        toastId: "resetsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      navigate("/");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "reseterror",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [isAuthenticated, error, dispatch, navigate]);

  return (
    <Fragment>
      <MetaData title={"ResetPassword"} />
      <div className="container">
        <div className="wrapper">
          <div className="title">New Password</div>
          <form onSubmit={submitHandler}>
            <div className="field">
              <input
                type={!isVisible ? "password" : "text"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="eye" onClick={toggle}>
                {isVisible ? (
                  <i className="fa fa-eye" id="eye" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    id="eye-slash"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
              <label>Password</label>
            </div>
            <div className="field">
              <input
                type={!isVisible1 ? "password" : "text"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span className="eye" onClick={toggle1}>
                {isVisible1 ? (
                  <i className="fa fa-eye" id="eye" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    id="eye-slash"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
              <label>Confirm Password</label>
            </div>

            <div className="field">
              <input type="submit" value="Set Password" />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
