import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthError, updateProfile } from "../../actions/userActions";
import { toast } from "react-toastify";
import { clearUpdateProfile } from "../../slices/authSlice";
import MetaData from "../layouts/MetaData";

export default function UpdateProfile() {
  const { user, error, isUpdated, isAuthenticated } = useSelector(
    (state) => state.authState
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");
  const [driverRole, setDriverRole] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(
    "/images/default_avatar.png"
  );
  const dispatch = useDispatch();

  const onChangeAvatar = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setAvatar(e.target.files[0]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("avatar", avatar);
    formData.append("driverRole", driverRole);
    dispatch(updateProfile(formData));
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setDriverRole(user.driverRole);
      if (user.avatar) {
        setAvatarPreview(user.avatar);
      }
    }

    if (isUpdated) {
      toast("profile updated successfully", {
        type: "success",
        toastId: "uprofilesuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearUpdateProfile()),
      });
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "uprofileerror",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [user, isUpdated, error, dispatch]);

  return (
    <Fragment>
      <MetaData title={"Update Profile"} />
      <div className="container">
        <div className="wrapper">
          <div className="title">Update Profile</div>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            <div className="field">
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label>Name</label>
            </div>
            <div className="field">
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label>Email Address</label>
            </div>

            <div className="form-group mt-3">
              <div className="d-flex align-items-center">
                <div>
                  <figure className="avatar mr-3 item-rtl">
                    <img
                      src={avatarPreview}
                      className="rounded-circle"
                      alt="avatar"
                    />
                  </figure>
                </div>
                <div className="custom-file">
                  <input
                    type="file"
                    name="avatar"
                    onChange={onChangeAvatar}
                    className="custom-file-input"
                    id="customFile"
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose Avatar
                  </label>
                </div>
              </div>
            </div>
            {isAuthenticated && user.role === "user" && (
              <div className="content">
                <div className="checkbox">
                  <label htmlFor="remember-me">
                    <b>Do you want to register as driver?</b>
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={driverRole}
                    onChange={(e) => setDriverRole(e.target.checked)}
                    id="remember-me"
                  />{" "}
                  <b>Yes</b>
                </div>
              </div>
            )}

            <div className="field">
              <input type="submit" value="Update" />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
