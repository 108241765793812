import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import { clearError, clearShareDeleted } from "../../slices/shareSlice";
import { deleteAdminShare, getAdminShares } from "../../actions/shareActions";

export default function ShareList() {
  const {
    shares = [],
    loading = true,
    error,
    isShareDeleted,
  } = useSelector((state) => state.shareState);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteAdminShare(id));
  };

  const setShares = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Departure",
          field: "departure",
          sort: "asc",
        },
        {
          label: "arrive",
          field: "arrive",
          sort: "asc",
        },
        {
          label: "departureDate",
          field: "departureDate",
          sort: "asc",
        },
        {
          label: "departureTime",
          field: "departureTime",
          sort: "asc",
        },
        {
          label: "sharePassenger",
          field: "sharePassenger",
          sort: "asc",
        },
        {
          label: "shareAmount",
          field: "shareAmount",
          sort: "asc",
        },
        {
          label: "user",
          field: "user",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    shares.forEach((sharedata) => {
      data.rows.push({
        id: sharedata._id,
        departure: sharedata.departure,
        arrive: sharedata.arrive,
        departureDate: sharedata.departureDate,
        departureTime: sharedata.departureTime,
        sharePassenger: sharedata.sharePassenger,
        shareAmount: sharedata.shareAmount,
        user: sharedata.user,
        actions: (
          <Fragment>
            <Link
              to={`/admin/viewshare/${sharedata._id}`}
              className="btn btn-primary"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              to={`/admin/updateshare/${sharedata._id}`}
              className="btn btn-success"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, sharedata._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "shareadminlisterror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    if (isShareDeleted) {
      toast("share deleted successfully", {
        type: "success",
        toastId: "shareaadminlistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearShareDeleted()),
      });
      return;
    }

    dispatch(getAdminShares);
  }, [dispatch, error, isShareDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Share List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setShares()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
