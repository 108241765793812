import { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { forgotPassword, clearAuthError } from "../../actions/userActions";
import MetaData from "../layouts/MetaData";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.authState);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    dispatch(forgotPassword(formData));
  };

  useEffect(() => {
    if (message) {
      toast(message, {
        type: "success",
        toastId: "forgotsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setEmail("");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "forgoterror",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [message, error, dispatch]);

  return (
    <Fragment>
      <MetaData title={"forgot password"} />
      <div className="container">
        <div className="wrapper">
          <div className="title">Forgot Password</div>
          <form onSubmit={submitHandler}>
            <div className="field">
              <input
                type="text"
                value={email.toLowerCase()}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label>Email</label>
            </div>

            <div className="field">
              <input type="submit" value="Send Email" />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
