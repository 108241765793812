import { Fragment, useEffect, useState } from "react";
import Sidetoolbar from "./Sidetoolbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createNewCustom, getCustoms } from "../../actions/customActions";
import { clearCustomCreated, clearError } from "../../slices/customSlice";

export default function CustomRegister() {
  const [userCar, setUserCar] = useState("");
  const [userVan, setUserVan] = useState("");
  const [userBus, setUserBus] = useState("");
  const [userMiniCar, setUserMiniCar] = useState("");
  const [userMiniVan, setUserMiniVan] = useState("");
  const [userMiniBus, setUserMiniBus] = useState("");
  const [driverCar, setDriverCar] = useState("");
  const [driverVan, setDriverVan] = useState("");
  const [driverBus, setDriverBus] = useState("");
  const [driverMiniCar, setDriverMiniCar] = useState("");
  const [driverMiniVan, setDriverMiniVan] = useState("");
  const [driverMiniBus, setDriverMiniBus] = useState("");

  const {
    customs = [],
    loading,
    isCustomCreated = false,
    error,
  } = useSelector((state) => state.customState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createNewCustom(
        userMiniCar,
        userCar,
        userMiniVan,
        userVan,
        userMiniBus,
        userBus,
        driverMiniCar,
        driverCar,
        driverMiniVan,
        driverVan,
        driverMiniBus,
        driverBus
      )
    );
  };

  useEffect(() => {
    if (isCustomCreated) {
      toast("custom created successfully", {
        type: "success",
        toastId: "customsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearCustomCreated()),
      });
      navigate("/admin/custom");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "customerror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }

    dispatch(getCustoms);
  }, [isCustomCreated, error, dispatch]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>
      <div className="col-12 col-md-10">
        <Fragment>
          {customs.length >= 1 ? (
            <div className="container">
              <div className="wrapper">
                <div className="title">delete the custom</div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="wrapper">
                <div className="title">Registration Form</div>
                <form onSubmit={submitHandler} encType="multipart/form-data">
                  <div className="field">
                    <input
                      type="text"
                      value={userMiniCar}
                      onChange={(e) => setUserMiniCar(e.target.value)}
                      required
                    />
                    <label>User Mini Car</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={userCar}
                      onChange={(e) => setUserCar(e.target.value)}
                      required
                    />
                    <label>User Car</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={userMiniVan}
                      onChange={(e) => setUserMiniVan(e.target.value)}
                      required
                    />
                    <label>User Mini Van</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={userVan}
                      onChange={(e) => setUserVan(e.target.value)}
                      required
                    />
                    <label>User Van</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={userMiniBus}
                      onChange={(e) => setUserMiniBus(e.target.value)}
                      required
                    />
                    <label>User Mini Bus</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={userBus}
                      onChange={(e) => setUserBus(e.target.value)}
                      required
                    />
                    <label>User Bus</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={driverMiniCar}
                      onChange={(e) => setDriverMiniCar(e.target.value)}
                      required
                    />
                    <label>Driver Mini Car</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={driverCar}
                      onChange={(e) => setDriverCar(e.target.value)}
                      required
                    />
                    <label>Driver Car</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={driverMiniVan}
                      onChange={(e) => setDriverMiniVan(e.target.value)}
                      required
                    />
                    <label>Driver Mini Van</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={driverVan}
                      onChange={(e) => setDriverVan(e.target.value)}
                      required
                    />
                    <label>Driver Van</label>
                  </div>
                  <div className="field">
                    <input
                      type="text"
                      value={driverMiniBus}
                      onChange={(e) => setDriverMiniBus(e.target.value)}
                      required
                    />
                    <label>Driver Mini Bus</label>
                  </div>

                  <div className="field">
                    <input
                      type="text"
                      value={driverBus}
                      onChange={(e) => setDriverBus(e.target.value)}
                      required
                    />
                    <label>Driver Bus</label>
                  </div>

                  <div className="field" disabled={loading} id="btn">
                    <input type="submit" value="Register" />
                  </div>
                </form>
              </div>
            </div>
          )}
        </Fragment>
      </div>
    </div>
  );
}
