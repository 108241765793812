import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import { clearCustomDeleted, clearError } from "../../slices/customSlice";
import { deleteCustom, getCustoms } from "../../actions/customActions";

export default function CustomList() {
  const {
    customs = [],
    loading = true,
    error,
    isCustomDeleted,
  } = useSelector((state) => state.customState);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteCustom(id));
  };

  const setCustoms = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "User Mini Car",
          field: "userMiniCar",
          sort: "asc",
        },
        {
          label: "User Car",
          field: "userCar",
          sort: "asc",
        },
        {
          label: "User Mini Van",
          field: "userMiniVan",
          sort: "asc",
        },
        {
          label: "User Van",
          field: "userVan",
          sort: "asc",
        },
        {
          label: "User Mini Bus",
          field: "userMiniBus",
          sort: "asc",
        },
        {
          label: "User Bus",
          field: "userBus",
          sort: "asc",
        },
        {
          label: "Driver Mini Car",
          field: "driverMiniCar",
          sort: "asc",
        },
        {
          label: "Driver  Car",
          field: "driverCar",
          sort: "asc",
        },
        {
          label: "Driver Mini Van",
          field: "driverMiniVan",
          sort: "asc",
        },
        {
          label: "Driver Van",
          field: "driverVan",
          sort: "asc",
        },
        {
          label: "Driver Mini Bus",
          field: "driverMiniBus",
          sort: "asc",
        },
        {
          label: "Driver Bus",
          field: "driverBus",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    customs.forEach((custom) => {
      data.rows.push({
        id: custom._id,
        userMiniCar: custom.userMiniCar,
        userCar: custom.userCar,
        userMiniVan: custom.userMiniVan,
        userVan: custom.userVan,
        userMiniBus: custom.userMiniBus,
        userBus: custom.userBus,
        driverMiniCar: custom.driverMiniCar,
        driverCar: custom.driverCar,
        driverMiniVan: custom.driverMiniVan,
        driverVan: custom.driverVan,
        driverMiniBus: custom.driverMiniBus,
        driverBus: custom.driverBus,
        actions: (
          <Fragment>
            <Link
              to={`/admin/custom/${custom._id}`}
              className="btn btn-primary"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, custom._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "customlisterror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    if (isCustomDeleted) {
      toast("custom deleted successfully", {
        type: "success",
        toastId: "customlistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearCustomDeleted()),
      });
      return;
    }

    dispatch(getCustoms);
  }, [dispatch, error, isCustomDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Custom List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setCustoms()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
