import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import { clearUserDeleted, clearUserError } from "../../slices/userSlice";
import { deleteUser, getUsers } from "../../actions/userActions";

export default function UserList() {
  const {
    users = [],
    loading = true,
    error,
    isUserDeleted,
  } = useSelector((state) => state.userState);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteUser(id));
  };

  const setUsers = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
        },
        {
          label: "Role",
          field: "role",
          sort: "asc",
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    let roleUser = users.filter((user) => {
      return user.role === "user";
    });
    roleUser.forEach((user) => {
      data.rows.push({
        id: user._id,
        name: user.name,
        email: user.email,
        role: user.role,
        actions: (
          <Fragment>
            <Link
              to={`/admin/singleuser/${user._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              to={`/admin/user/${user._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, user._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  const setDrivers = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },

        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
        },
        {
          label: "Role",
          field: "role",
          sort: "asc",
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    let roleUser = users.filter((user) => {
      return user.role === "driver";
    });
    roleUser.forEach((user) => {
      data.rows.push({
        id: user._id,
        name: user.name,
        email: user.email,
        role: user.role,
        actions: (
          <Fragment>
            <Link
              to={`/admin/singleuser/${user._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              to={`/admin/user/${user._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, user._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  const setAdmin = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },

        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
        },
        {
          label: "Role",
          field: "role",
          sort: "asc",
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    let roleUser = users.filter((user) => {
      return user.role === "admin";
    });
    roleUser.forEach((user) => {
      data.rows.push({
        id: user._id,
        name: user.name,
        email: user.email,
        role: user.role,
        actions: (
          <Fragment>
            <Link
              to={`/admin/singleuser/${user._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              to={`/admin/user/${user._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, user._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "userlisterror",
        onOpen: () => {
          dispatch(clearUserError());
        },
      });
      return;
    }
    if (isUserDeleted) {
      toast("user deleted successfully", {
        type: "success",
        toastId: "userlistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearUserDeleted()),
      });
      return;
    }

    dispatch(getUsers);
  }, [dispatch, error, isUserDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">User List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setUsers()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>

        <h1 className="my-4">Driver List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setDrivers()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>

        <h1 className="my-4">Admin List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setAdmin()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
