import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { logout } from "../../actions/userActions";
import { Fragment } from "react";

export default function Header() {
  const { isAuthenticated, user = {} } = useSelector(
    (state) => state.authState
  );
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout);
  };

  return (
    <Fragment>
      <nav className="navbar navbar-expand-md" id="navigation">
        <Link className="navbar-brand" to="/">
          <img src="/images/logo.jpeg" style={{ height: 50, width: 100 }} alt="Brand logo" />
        </Link>

        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navb"
          aria-expanded="true"
        >
          <span role="button">
            <i
              className="fa fa-bars"
              aria-hidden="true"
              style={{ color: "white" }}
            ></i>
          </span>
        </button>
        <div id="navb" className="navbar-collapse collapse hide">
          <ul className="navbar-nav">
            <li className="nav-item" data-toggle="collapse" data-target="#navb">
              <Link className="nav-link" id="nav-text" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target="#navb">
              <a className="nav-link" id="nav-text" href="/#about">
                About
              </a>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target="#navb">
              <a className="nav-link" id="nav-text" href="/#contact">
                Contact Us
              </a>
            </li>
          </ul>
          {isAuthenticated ? (
            <ul className="nav navbar-nav ml-auto">
              {user.role === "admin" && (
                <li
                  className="nav-item"
                  data-toggle="collapse"
                  data-target="#navb"
                >
                  <Link
                    className="nav-link"
                    to="/admin/dashboard"
                    id="nav-text"
                  >
                    Dashboard
                  </Link>
                </li>
              )}
              {user.role === "driver" && (
                <Fragment>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link className="nav-link" to="/driver/order" id="nav-text">
                      Menu
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link
                      className="nav-link"
                      to="/driver/newbooking"
                      id="nav-text"
                    >
                      New
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link
                      className="nav-link"
                      to="/driver/acceptbooking"
                      id="nav-text"
                    >
                      Accept
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link
                      className="nav-link"
                      to="/driver/completedbooking"
                      id="nav-text"
                    >
                      Complete
                    </Link>
                  </li>
                </Fragment>
              )}
              {user.role === "user" && (
                <Fragment>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link className="nav-link" to="/map" id="nav-text">
                      Map
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link className="nav-link" to="/package" id="nav-text">
                      Package
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navb"
                  >
                    <Link className="nav-link" to="/mybookings" id="nav-text">
                      MyBooking
                    </Link>
                  </li>
                </Fragment>
              )}

              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navb"
              >
                <Link
                  className="nav-link"
                  to="/"
                  onClick={logoutHandler}
                  id="nav-text"
                >
                  <span className="fas fa-sign-out-alt"></span>
                  Logout
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navb"
              >
                <Link className="nav-link" to="/myprofile" id="nav-text">
                  {user.name}
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navb"
              >
                <figure className="avatar avatar-nav">
                  <Link className="nav-link" to="/myprofile">
                    <Image
                      width="50px"
                      src={user.avatar ?? "/images/default_avatar.png"}
                    />
                  </Link>
                </figure>
              </li>
            </ul>
          ) : (
            <ul className="nav navbar-nav ml-auto">
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navb"
              >
                <Link className="nav-link" to="/register" id="nav-text">
                  <span className="fas fa-user"></span> Sign Up
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navb"
              >
                <Link className="nav-link" to="/login" id="nav-text">
                  <span className="fas fa-sign-in-alt"></span> Login
                </Link>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </Fragment>
  );
}
