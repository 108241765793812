import { Fragment, useEffect, useState } from "react";
import Sidetoolbar from "./Sidetoolbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createNewDriver } from "../../actions/driverActions";
import { clearDriverCreated, clearError } from "../../slices/driverSlice";
import { getUsers } from "../../actions/userActions";

export default function DriversRegister() {
  const [name, setName] = useState("");
  const [driverEmail, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [category, setCategory] = useState("");
  const [model, setModel] = useState("");
  const [Passengers, setPassengers] = useState("");
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [avatarPreview, setAvatarPreview] = useState(
    "/images/default_avatar.png"
  );
  const [avatarDriver, setAvatarDriver] = useState("");

  const {
    loading,
    isDriverCreated = false,
    error,
  } = useSelector((state) => state.driverState);
  const { users = [] } = useSelector((state) => state.userState);

  const categories = ["van", "car", "bus", "minicar", "minivan", "minibus"];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onImagesChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState == 2) {
          setImagesPreview((oldArray) => [...oldArray, reader.result]);

          setImages((oldArray) => [...oldArray, file]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatarDriver(e.target.files[0]);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getEmail = users.filter((user) => {
    return user.email === driverEmail;
  });

  const getId = getEmail.map((user) => {
    return user._id;
  });

  console.log(getId);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("driverEmail", driverEmail);
    formData.append("contactNo", contactNo);
    formData.append("avatarDriver", avatarDriver);
    formData.append("vehicleNo", vehicleNo);
    formData.append("vehicleCategory", category);
    formData.append("vehicleModal", model);
    formData.append("maxPassengers", Passengers);
    images.forEach((image) => {
      formData.append("images", image);
    });
    formData.append("user", getId);
    dispatch(createNewDriver(formData));
  };

  useEffect(() => {
    if (isDriverCreated) {
      toast("driver created successfully", {
        type: "success",
        toastId: "driversuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearDriverCreated()),
      });
      navigate("/admin/drivers");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "drivererror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    dispatch(getUsers);
  }, [isDriverCreated, error, dispatch]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>
      <div className="col-12 col-md-10">
        <Fragment>
          <div className="container">
            <div className="wrapper">
              <div className="title">Registration Form</div>

              <form onSubmit={submitHandler} encType="multipart/form-data">
                <div className="field">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label>Name</label>
                </div>
                <div className="field">
                  <input
                    type="email"
                    value={driverEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label>Email Address</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    required
                  />
                  <label>Contact No</label>
                </div>

                <div className="field">
                  <input
                    type="text"
                    value={vehicleNo}
                    onChange={(e) => setVehicleNo(e.target.value)}
                    required
                  />
                  <label>Vehicle No</label>
                </div>
                <div className="field">
                  <select onChange={(e) => setCategory(e.target.value)}>
                    <option value="">Select vehicle</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    required
                  />
                  <label>Vehicle Model</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={Passengers}
                    onChange={(e) => setPassengers(e.target.value)}
                    required
                  />
                  <label>Max Passengers</label>
                </div>
                <div className="form-group">
                  <label>Images</label>

                  <div className="custom-file">
                    <input
                      type="file"
                      name="product_images"
                      className="custom-file-input"
                      id="customFile"
                      multiple
                      onChange={onImagesChange}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      Choose Images
                    </label>
                  </div>
                  {imagesPreview.map((image) => (
                    <img
                      className="mt-3 mr-2"
                      key={image}
                      src={image}
                      alt={`image Preview`}
                      width="55"
                      height="52"
                    />
                  ))}
                </div>

                <div className="field" disabled={loading}>
                  <input type="submit" value="Register" />
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
