import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function MenuBar({ map, bookings, mypackage }) {
  return (
    <Fragment>
      <div className="tile" id="tile-1">
        <ul className="nav nav-tabs nav-justified" role="tablist">
          {map ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="home-tab"
                data-toggle="tab"
                to="/map"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <i className="fas fa-home"></i> Map
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="home-tab"
                data-toggle="tab"
                to="/map"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <i className="fas fa-home"></i> Map
              </Link>
            </li>
          )}
          {mypackage ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="profile-tab"
                data-toggle="tab"
                to="/package"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <i className="fas fa-id-card"></i> Package
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="profile-tab"
                data-toggle="tab"
                to="/package"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <i className="fas fa-id-card"></i> Package
              </Link>
            </li>
          )}
          {bookings ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="contact-tab"
                data-toggle="tab"
                to="/mybookings"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                <i className="fa fa-car" aria-hidden="true"></i> Bookings
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="contact-tab"
                data-toggle="tab"
                to="/mybookings"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                <i className="fa fa-car" aria-hidden="true"></i> Bookings
              </Link>
            </li>
          )}
        </ul>
      </div>
    </Fragment>
  );
}
