import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import Sidetoolbar from "./Sidetoolbar";
import { getUser} from "../../actions/userActions";
import moment from "moment";

export default function SingleUser() {
  const { user = {} } = useSelector((state) => state.userState);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidetoolbar />
        </div>
        <div className="col-12 col-md-10">
          <div className="card-confirm">
            <div className="container">
              <div className="row m-0">
                <div className="col-lg-12 pb-5 pe-lg-5">
                  <div className="row">
                    <div className="row m-0 bg-light">
                      <div className="col-md-6 col-6 ps-30 pe-0 my-4">
                        <img
                          src={user.avatar ?? "/images/default_avatar.png"}
                          alt="profile"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                      <div className="col-md-6 col-6  ps-30 my-4">
                        <p className="text-muted">Profile</p>
                        <p className="h6 m-0">{user.avatar}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                        <p className="text-muted">Name</p>
                        <p className="h5">{user.name}</p>
                      </div>
                      <div className="col-md-4 col-6  ps-30 my-4">
                        <p className="text-muted">Email</p>
                        <p className="h6 m-0">{user.email}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Role</p>
                        <p className="h5 m-0">{user.role}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Id</p>
                        <p className="h6 m-0">{user._id}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">driverRole</p>
                        <p className="h5 m-0">{user.driverRole}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">createdAt</p>
                        <p className="h6 m-0">{moment(user.createdAt).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
