import { Fragment } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function BookingFail() {
  return (
    <Fragment>
      <MetaData title={"fail"} />
      <div className="row justify-content-center">
        <div className="col-6 mt-5 text-center">
          <img
            className="my-5 img-fluid d-block mx-auto"
            src="/images/fail.png"
            alt="Order Success"
            width="200px"
            height="200px"
          />

          <h2>
            We sincerely apologize, but something went wrong with your booking.
            Please Try Again.
          </h2>

          <Link to="/">Go to Home Page</Link>
        </div>
      </div>
    </Fragment>
  );
}
