import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../actions/driverActions";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function Reviews() {
  const dispatch = useDispatch();
  const { reviews = [] } = useSelector((state) => state.driverState);

  useEffect(() => {
    dispatch(getReviews);
  }, [dispatch]);

  return (
    <Fragment>
      <MetaData title={"driver Reviews"} />
      <div className="card-confirm">
        <table className="wp-table">
          <tr>
            <th>Rating</th>
            <th>Comment</th>
            <th>View</th>
          </tr>
          {reviews &&
            reviews.map((review) => (
              <tr>
                <td>
                  <div className="rating-outer">
                    <div
                      className="rating-inner"
                      style={{ width: `${(review.rating / 5) * 100}%` }}
                    ></div>
                  </div>
                </td>
                <td>{review.comment}</td>
                <td>
                  <Link
                    to={`/driver/completedDetail/${review.bookingId}`}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-eye"></i>
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      </div>
    </Fragment>
  );
}
