import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register, clearAuthError } from "../../actions/userActions";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function Registration() {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [avatar, setAvatar] = useState("");
  const [driverRole, setDriverRole] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(
    "/images/default_avatar.png"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.authState
  );
  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const onChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(e.target.files[0]);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", userData.name);
    formData.append("email", userData.email.toLowerCase().trim());
    formData.append("password", userData.password);
    formData.append("avatar", avatar);
    formData.append("driverRole", driverRole);
    dispatch(register(formData));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/map");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "registererror",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [error, isAuthenticated, dispatch, navigate]);

  return (
    <Fragment>
      <MetaData title={"Registration"} />
      <div className="container">
        <div className="wrapper">
          <div className="title">Registration Form</div>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            <div className="field">
              <input type="text" name="name" onChange={onChange} required />
              <label>Name</label>
            </div>
            <div className="field">
              <input type="email" name="email" onChange={onChange} required />
              <label>Email Address</label>
            </div>
            <div className="field">
              <input
                type={!isVisible ? "password" : "text"}
                name="password"
                onChange={onChange}
                required
              />
              <span className="eye" onClick={toggle}>
                {isVisible ? (
                  <i className="fa fa-eye" id="eye" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    id="eye-slash"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
              <label>Create Password</label>
            </div>
            <br />
            <div className="form-group">
              <div className="d-flex align-items-center">
                <div>
                  <figure className="avatar mr-3 item-rtl">
                    <img
                      src={avatarPreview}
                      className="rounded-circle"
                      alt="avatar"
                    />
                  </figure>
                </div>
                <div className="custom-file">
                  <input
                    type="file"
                    name="avatar"
                    onChange={onChange}
                    className="custom-file-input"
                    id="customFile"
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose Avatar
                  </label>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="checkbox">
                <label htmlFor="remember-me">
                  <b>Do you want to register as driver?</b>
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  value={driverRole}
                  onChange={(e) => setDriverRole(e.target.checked)}
                  id="remember-me"
                />{" "}
                <b>Yes</b>
              </div>
            </div>

            <div className="field" disabled={loading}>
              <input type="submit" value="Register" />
            </div>

            <div className="signup-link">
              A member? <Link to="/login">Sign in</Link>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
