import { Link } from "react-router-dom";
import Sidetoolbar from "./Sidetoolbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUsers } from "../../actions/userActions";
import { adminBookings } from "../../actions/bookingActions";

export default function Dashboard() {
  const { users = [] } = useSelector((state) => state.userState);
  const { adminBooking = [] } = useSelector((state) => state.bookingState);

  const dispatch = useDispatch();

  let totalUserAmount = 0;
  if (adminBooking.length > 0) {
    adminBooking.forEach((book) => {
      totalUserAmount += book.priceForUser;
    });
  }

  let totalDriverAmount = 0;
  if (adminBooking.length > 0) {
    adminBooking.forEach((book) => {
      totalDriverAmount += book.priceForDriver;
    });
  }

  useEffect(() => {
    dispatch(adminBookings);
    dispatch(getUsers);
  }, [dispatch]);

  const userCount = users.filter((count) => {
    return count.role === "user";
  });

  const driverCount = users.filter((count) => {
    return count.role === "driver";
  });

  const adminCount = users.filter((count) => {
    return count.role === "admin";
  });

  const completedCount = adminBooking.filter((count) => {
    return count.status.bookStatus === "Completed";
  });

  const cancelledCount = adminBooking.filter((count) => {
    return count.status.bookStatus === "Cancelled";
  });

  const acceptedCount = adminBooking.filter((count) => {
    return count.status.bookStatus === "Accepted";
  });

  const processCount = adminBooking.filter((count) => {
    return count.status.bookStatus === "Processing";
  });

  const pickupCount = adminBooking.filter((count) => {
    return count.status.bookStatus === "PickedUp";
  });

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Dashboard</h1>
        <div className="row pr-4">
          <div className="col-xl-4 col-sm-12 mb-3">
            <div className="card text-white bg-primary o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  User Amount
                  <br /> <b>Rs.{totalUserAmount}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-12 mb-3">
            <div className="card text-white bg-warning o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Driver Amount
                  <br /> <b>Rs.{totalDriverAmount}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-12 mb-3">
            <div className="card text-white bg-success o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Profit
                  <br /> <b>Rs.{totalUserAmount - totalDriverAmount}</b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pr-4">
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-success o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Users
                  <br /> <b>{userCount.length}</b>
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/admin/users"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fa fa-angle-right"></i>
                </span>
              </Link>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-danger o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Drivers
                  <br /> <b>{driverCount.length}</b>
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/admin/drivers"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fa fa-angle-right"></i>
                </span>
              </Link>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-info o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Bookings
                  <br /> <b>{adminBooking.length}</b>
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/admin/bookings"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fa fa-angle-right"></i>
                </span>
              </Link>
            </div>
          </div>

          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-warning o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Admin
                  <br /> <b>{adminCount.length}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pr-4">
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-success o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Completed
                  <br /> <b>{completedCount.length}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-danger o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Cancelled
                  <br /> <b>{cancelledCount.length}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-info o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Processing
                  <br /> <b>{processCount.length}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-warning o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Accepted = <b>{acceptedCount.length}</b>
                  <br /> 
                  Pickedup = <b>{pickupCount.length}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
