import { createSlice } from "@reduxjs/toolkit";

const driverSlice = createSlice({
  name: "driver",
  initialState: {
    loading: false,
    driver: {},
    admindriver: {},
    adminCancelDriver: [],
    drivers: [],
    driverDetails: {},
    isDriverCreated: false,
    isDriverDeleted: false,
    isDriverUpdated: false,
    isReviewSubmitted: false,
    reviews: [],
    driversPay: [],
  },
  reducers: {
    newDriverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newDriverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        driver: action.payload.driver,
        isDriverCreated: true,
      };
    },
    newDriverFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isDriverCreated: false,
      };
    },
    clearDriverCreated(state, action) {
      return {
        ...state,
        isDriverCreated: false,
      };
    },
    clearDriver(state, action) {
      return {
        ...state,
        driver: {},
      };
    },

    adminDriversRequest(state, action) {
      return {
        loading: true,
      };
    },
    adminDriversSuccess(state, action) {
      return {
        loading: false,
        drivers: action.payload.drivers,
      };
    },
    adminDriversFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    adminDriversPaymentRequest(state, action) {
      return {
        loading: true,
      };
    },
    adminDriversPaymentSuccess(state, action) {
      return {
        loading: false,
        driversPay: action.payload.filterCompletedBook,
      };
    },
    adminDriversPaymentFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },

    driverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    driverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        admindriver: action.payload.driver,
        adminCancelDriver: action.payload.adminCancelDriver,
      };
    },
    driverFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },

    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    updateDriverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateDriverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        driver: action.payload.driver,
        isDriverUpdated: true,
      };
    },
    updateDriverFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearDriverUpdated(state, action) {
      return {
        ...state,
        isDriverUpdated: false,
      };
    },

    deleteDriverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteDriverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isDriverDeleted: true,
      };
    },
    deleteDriverFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isDriverDeleted: false,
      };
    },
    clearDriverDeleted(state, action) {
      return {
        ...state,
        isDriverDeleted: false,
      };
    },

    driversAllRequest(state, action) {
      return {
        loading: true,
      };
    },
    driversAllSuccess(state, action) {
      return {
        loading: false,
        drivers: action.payload.drivers,
      };
    },
    driversAllFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    reviewsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    reviewsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        reviews: action.payload.reviews,
        driverDetails: action.payload.driver,
      };
    },
    reviewsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },

    userDriversRequest(state, action) {
      return {
        loading: true,
      };
    },
    userDriversSuccess(state, action) {
      return {
        loading: false,
        drivers: action.payload.drivers,
      };
    },
    userDriversFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    createReviewRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    createReviewSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isReviewSubmitted: true,
      };
    },
    createReviewFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearReviewSubmitted(state, action) {
      return {
        ...state,
        isReviewSubmitted: false,
      };
    },
  },
});

const { actions, reducer } = driverSlice;

export const {
  driverRequest,
  driverSuccess,
  driverFail,
  adminDriversFail,
  adminDriversRequest,
  adminDriversSuccess,
  adminDriversPaymentFail,
  adminDriversPaymentRequest,
  adminDriversPaymentSuccess,
  clearDriver,
  clearError,
  newDriverFail,
  newDriverRequest,
  newDriverSuccess,
  clearDriverCreated,
  deleteDriverFail,
  deleteDriverRequest,
  deleteDriverSuccess,
  clearDriverDeleted,
  updateDriverFail,
  updateDriverRequest,
  updateDriverSuccess,
  clearDriverUpdated,
  driversAllFail,
  driversAllRequest,
  driversAllSuccess,
  userDriversRequest,
  userDriversSuccess,
  userDriversFail,
  createReviewFail,
  createReviewRequest,
  createReviewSuccess,
  clearReviewSubmitted,
  reviewsRequest,
  reviewsSuccess,
  reviewsFail,
} = actions;

export default reducer;
