import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import { deleteDriver, getDrivers } from "../../actions/driverActions";
import { clearDriverDeleted, clearError } from "../../slices/driverSlice";

export default function DriverList() {
  const {
    drivers = [],
    loading = true,
    error,
    isDriverDeleted,
  } = useSelector((state) => state.driverState);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteDriver(id));
  };

  const setDrivers = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Email",
          field: "driverEmail",
          sort: "asc",
        },
        {
          label: "ContactNo",
          field: "contactNo",
          sort: "asc",
        },
        {
          label: "Vehicle No",
          field: "vehicleNo",
          sort: "asc",
        },
        {
          label: "Vehicle Category",
          field: "vehicleCategory",
          sort: "asc",
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    drivers.forEach((rider) => {
      data.rows.push({
        id: rider._id,
        name: rider.name,
        driverEmail: rider.driverEmail,
        contactNo: rider.contactNo,
        vehicleNo: rider.vehicleNo,
        vehicleCategory: rider.vehicleCategory,
        actions: (
          <Fragment>
            <Link
              to={`/admin/driver/payment/${rider._id}`}
              className="btn btn-info py-1 px-2 ml-2"
            >
              <i class="fa fa-money" aria-hidden="true"></i>
            </Link>
            <Link
              to={`/admin/singledriver/${rider._id}`}
              className="btn btn-info py-1 px-2 ml-2"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              to={`/admin/driver/${rider._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, rider._id)}
              ></i>
            </Button>
            <Link
              to={`/admin/reviews/${rider._id}`}
              className="btn btn-success py-1 px-2 ml-2"
            >
              <i className="fa fa-comment" aria-hidden="true"></i>
            </Link>
            <Link
              to={`/admin/cancel/${rider._id}`}
              className="btn btn-warning py-1 px-2 ml-2"
            >
              <i className="fa fa-ban" aria-hidden="true"></i>
            </Link>
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "driverlisterror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    if (isDriverDeleted) {
      toast("driver deleted successfully", {
        type: "success",
        toastId: "driverlistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearDriverDeleted()),
      });
      return;
    }

    dispatch(getDrivers);
  }, [dispatch, error, isDriverDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Driver List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setDrivers()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
