import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Tabs({ dashboard, newbooking, accept, complete }) {
  return (
    <Fragment>
      <div className="tile" id="tile-1">
        <ul className="nav nav-tabs nav-justified" role="tablist">
          {dashboard ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="home-tab"
                data-toggle="tab"
                to="/driver/order"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <i className="fas fa-home"></i> Home
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="home-tab"
                data-toggle="tab"
                to="/driver/order"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <i className="fas fa-home"></i> Home
              </Link>
            </li>
          )}
          {newbooking ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="profile-tab"
                data-toggle="tab"
                to="/driver/newbooking"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <i className="fas fa-id-card"></i> New
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="profile-tab"
                data-toggle="tab"
                to="/driver/newbooking"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <i className="fas fa-id-card"></i> New
              </Link>
            </li>
          )}
          {accept ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="contact-tab"
                data-toggle="tab"
                to="/driver/acceptbooking"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                <i className="fa fa-car" aria-hidden="true"></i> Accept
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="contact-tab"
                data-toggle="tab"
                to="/driver/acceptbooking"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                <i className="fa fa-car" aria-hidden="true"></i> Accept
              </Link>
            </li>
          )}
          {complete ? (
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="setting-tab"
                data-toggle="tab"
                to="/driver/completedbooking"
                role="tab"
                aria-controls="setting"
                aria-selected="false"
              >
                <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                Complete
              </Link>
            </li>
          ) : (
            <li className="nav-item">
              <Link
                className="nav-link"
                id="setting-tab"
                data-toggle="tab"
                to="/driver/completedbooking"
                role="tab"
                aria-controls="setting"
                aria-selected="false"
              >
                <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                Complete
              </Link>
            </li>
          )}
        </ul>
      </div>
    </Fragment>
  );
}
