import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Tabs from "./Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  driverAcceptBookings,
  driverCompleteBookings,
} from "../../actions/bookingActions";
import { getReviews } from "../../actions/driverActions";
import MetaData from "../layouts/MetaData";

export default function Order() {
  const {
    driverCompleteBooking = [],
    driverAcceptBooking = [],
    cancelledCount = 0,
  } = useSelector((state) => state.bookingState);
  const { reviews = [] } = useSelector((state) => state.driverState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(driverAcceptBookings);
    dispatch(driverCompleteBookings);
    dispatch(getReviews);
  }, [dispatch]);

  const completedCount = driverCompleteBooking
    ? driverCompleteBooking.length
    : 0;
  const reviewCount = reviews ? reviews.length : 0;
  const acceptCount = driverAcceptBooking ? driverAcceptBooking.length : 0;

  let totalAmount = 0;
  driverCompleteBooking.forEach((complete) => {
    totalAmount += complete.priceForDriver;
  });

  let paidBook = driverCompleteBooking.filter((pay) => {
    return pay.driverPayment === "paid";
  });

  let paidAmount = 0;
  paidBook.forEach((pay) => {
    paidAmount += pay.priceForDriver;
  });

  let unPaidBook = driverCompleteBooking.filter((pay) => {
    return pay.driverPayment === "unpaid";
  });

  let unPaidAmount = 0;
  unPaidBook.forEach((pay) => {
    unPaidAmount += pay.priceForDriver;
  });

  return (
    <Fragment>
      <MetaData title={"driver dashboard"} />
      <Tabs dashboard />

      <div className="row">
        <div className="col-12 col-md-12">
          <h1 className="my-4 text-center">Dashboard Driver</h1>
          <div className="row pr-4">
            <div className="col-xl-4 col-sm-6 mb-3">
              <div className="card bg-c-blue order-card h-100">
                <div className="card-body">
                  <h2 className="m-b-20">Total Amount</h2>
                  <h2 className="text-right">
                    <span>Rs.{totalAmount}</span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6 mb-3">
              <div className="card bg-c-green order-card h-100">
                <div className="card-body">
                  <h2 className="m-b-20">Paid</h2>
                  <h2 className="text-right">
                    <span>Rs.{paidAmount}</span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-sm-6 mb-3">
              <div className="card bg-c-pink order-card h-100">
                <div className="card-body">
                  <h2 className="m-b-20">UnPaid</h2>
                  <h2 className="text-right">
                    <span>Rs.{unPaidAmount}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row pr-4">
            <div className="col-xl-3 col-sm-6 mb-3">
              <div className="card bg-c-blue order-card">
                <div className="card-body">
                  <h6 className="m-b-20">Completed</h6>
                  <h2 className="text-right">
                    <i className="fa fa-check f-left"></i>
                    <span>{completedCount}</span>
                  </h2>
                </div>
                <Link
                  className="card-footer text-white clearfix small z-1"
                  to="/driver/completedbooking"
                >
                  <span className="float-left">View Details</span>
                  <span className="float-right">
                    <i className="fa fa-angle-right"></i>
                  </span>
                </Link>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 mb-3">
              <div className="card bg-c-green order-card">
                <div className="card-body">
                  <h6 className="m-b-20">Reviews</h6>
                  <h2 className="text-right">
                    <i className="fa fa-comment f-left"></i>
                    <span>{reviewCount}</span>
                  </h2>
                </div>
                <Link
                  className="card-footer text-white clearfix small z-1"
                  to="/driver/reviews"
                >
                  <span className="float-left">View Details</span>
                  <span className="float-right">
                    <i className="fa fa-angle-right"></i>
                  </span>
                </Link>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 mb-3">
              <div className="card bg-c-pink order-card h-100">
                <div className="card-body">
                  <h6 className="m-b-20">Cancelled</h6>
                  <h2 className="text-right">
                    <i className="fa fa-refresh f-left"></i>
                    <span>{cancelledCount}</span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 mb-3">
              <div className="card bg-c-yellow order-card h-100">
                <div className="card-body">
                  <h6 className="m-b-20">Accepted</h6>
                  <h2 className="text-right">
                    <i className="fa fa-rocket f-left"></i>
                    <span>{acceptCount}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
