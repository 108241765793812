export default function CheckoutBar({ map, personal, confirm, payment }) {
  return (
    <form id="msform">
      {map && (
        <ul id="progressbar">
          <li className="active" id="account">
            <strong>Map</strong>
          </li>
          <li id="personal">
            <strong>Personal</strong>
          </li>
          <li id="confirm">
            <strong>Payment</strong>
          </li>
        </ul>
      )}
      {personal && (
        <ul id="progressbar">
          <li className="active" id="account">
            <strong>Map</strong>
          </li>
          <li className="active" id="personal">
            <strong>Personal</strong>
          </li>
          <li id="confirm">
            <strong>Payment</strong>
          </li>
        </ul>
      )}

      {payment && (
        <ul id="progressbar">
          <li className="active" id="account">
            <strong>Map</strong>
          </li>
          <li className="active" id="personal">
            <strong>Personal</strong>
          </li>
          <li className="active" id="confirm">
            <strong>Payment</strong>
          </li>
        </ul>
      )}
    </form>
  );
}
