import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveBookingInfo } from "../../slices/bookingSlice";
import { toast } from "react-toastify";
import { getCustomsUser } from "../../actions/customActions";
import CheckoutBar from "./CheckoutBar";
import MenuBar from "./MenuBar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validateMap } from "./Map";
import MetaData from "../layouts/MetaData";

export const validateBooking = (bookingInfo, navigate) => {
  if (
    !bookingInfo.phoneNo ||
    !bookingInfo.noOfPassengers ||
    !bookingInfo.pickupDate ||
    !bookingInfo.pickupTime
  ) {
    toast.error("Please provide the booking details", {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "validatebook",
    });
    navigate("/booking");
  }
};

export default function BookingForm() {
  const mapInfo = JSON.parse(sessionStorage.getItem("mapInfo")) ?? {};

  const [phoneNo, setPhoneNo] = useState("");
  const [noOfPassengers, setPassengers] = useState("");
  const [pickupDate, setDate] = useState("");
  const [pickupTime, setTime] = useState("");
  const [vehicle, setVehicle] = useState("bus");
  const [valid, setValid] = useState(true);
  const { customUser = {} } = useSelector((state) => state.customState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveBookingInfo({
        phoneNo,
        noOfPassengers,
        pickupDate,
        pickupTime,
        vehicle,
        priceForUser,
      })
    );
    navigate("/confirm");
  };

  const handleChange = (value) => {
    setPhoneNo(value);
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNo) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNo);
  };

  //discount
  let subtotal;
  let priceForUser;
  let discount = 0.08;

  if (noOfPassengers) {
    if (vehicle === "car") {
      subtotal = Math.ceil(mapInfo.distance * customUser.userCar);
    }
    if (vehicle === "minicar") {
      subtotal = Math.ceil(mapInfo.distance * customUser.userMiniCar);
    }
    if (vehicle === "van") {
      subtotal = Math.ceil(mapInfo.distance * customUser.userVan);
    }
    if (vehicle === "minivan") {
      subtotal = Math.ceil(mapInfo.distance * customUser.userMiniVan);
    }
    if (vehicle === "bus") {
      subtotal = Math.ceil(mapInfo.distance * customUser.userBus);
    }
    if (vehicle === "minibus") {
      subtotal = Math.ceil(mapInfo.distance * customUser.userMiniBus);
    }
  } else {
    subtotal = 0;
  }

  //discount
  priceForUser = Math.ceil(subtotal);

  useEffect(() => {
    if (sessionStorage.getItem("mapInfo")) {
      validateMap(mapInfo, navigate);
    } else {
      navigate("/");
    }
    dispatch(getCustomsUser);

    if (noOfPassengers) {
      setVehicle("bus");
      document.getElementById("vehicle1").disabled = false;
      document.getElementById("vehicle2").disabled = false;
      document.getElementById("vehicle3").disabled = false;
      document.getElementById("vehicle4").disabled = false;
      document.getElementById("vehicle5").disabled = false;
      document.getElementById("vehicle6").disabled = false;

      if (noOfPassengers > 3) {
        document.getElementById("vehicle1").disabled = true;
        document.getElementById("vehicle2").disabled = false;
        document.getElementById("vehicle3").disabled = false;
        document.getElementById("vehicle4").disabled = false;
        document.getElementById("vehicle5").disabled = false;
        document.getElementById("vehicle6").disabled = false;
      }
      if (noOfPassengers > 4) {
        document.getElementById("vehicle1").disabled = true;
        document.getElementById("vehicle2").disabled = true;
        document.getElementById("vehicle3").disabled = false;
        document.getElementById("vehicle4").disabled = false;
        document.getElementById("vehicle5").disabled = false;
        document.getElementById("vehicle6").disabled = false;
      }
      if (noOfPassengers > 8) {
        document.getElementById("vehicle1").disabled = true;
        document.getElementById("vehicle2").disabled = true;
        document.getElementById("vehicle3").disabled = true;
        document.getElementById("vehicle4").disabled = false;
        document.getElementById("vehicle5").disabled = false;
        document.getElementById("vehicle6").disabled = false;
      }
      if (noOfPassengers > 9) {
        document.getElementById("vehicle1").disabled = true;
        document.getElementById("vehicle2").disabled = true;
        document.getElementById("vehicle3").disabled = true;
        document.getElementById("vehicle4").disabled = true;
        document.getElementById("vehicle5").disabled = false;
        document.getElementById("vehicle6").disabled = false;
      }

      if (noOfPassengers > 30) {
        document.getElementById("vehicle1").disabled = true;
        document.getElementById("vehicle2").disabled = true;
        document.getElementById("vehicle3").disabled = true;
        document.getElementById("vehicle4").disabled = true;
        document.getElementById("vehicle5").disabled = true;
        document.getElementById("vehicle6").disabled = false;
      }
    }
  }, [dispatch, noOfPassengers]);

  return (
    <Fragment>
      <MetaData title={"personal details"} />
      <MenuBar map />
      <CheckoutBar personal />
      <div id="booking" className="section">
        <div className="section-center">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-md-push-7">
                <div className="booking-cta">
                  <img src="/images/backgroundlogo.png" alt="logoimg" />
                  <h1>Make your reservation</h1>
                </div>
              </div>
              <div className="col-md-7 col-md-pull-5">
                <div className="booking-form">
                  <form onSubmit={submitHandler} encType="multipart/form-data">
                    <div className="form-group">
                      <span className="form-label">Your Contact Number</span>
                      <PhoneInput
                        country={"lk"}
                        value={phoneNo}
                        onChange={handleChange}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        required
                      />
                      {!valid && <p>Please enter a valid phone number.</p>}
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Pickup Date</span>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="Pickup date"
                            value={pickupDate}
                            onChange={(e) => setDate(e.target.value)}
                            min={new Date().toISOString().split("T")[0]}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Pickup Time</span>
                          <input
                            className="form-control"
                            placeholder="Pickup time"
                            type="time"
                            value={pickupTime}
                            onChange={(e) => setTime(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <span className="form-label">Passengers</span>

                          <input
                            className="form-control"
                            type="number"
                            placeholder="Passengers"
                            value={noOfPassengers}
                            min="1"
                            max="50"
                            onChange={(e) => setPassengers(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-group">
                          <span className="form-label col-sm-12">Vehicle</span>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vehicle"
                              id="vehicle1"
                              disabled
                              value="minicar"
                              checked={vehicle === "minicar"}
                              onChange={(e) => setVehicle(e.target.value)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="vehicle1"
                            >
                              <i className="fas fa-car"></i> MiniCar-3
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vehicle"
                              id="vehicle2"
                              disabled
                              value="car"
                              checked={vehicle === "car"}
                              onChange={(e) => setVehicle(e.target.value)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="vehicle2"
                            >
                              <i className="fas fa-car"></i> Car-4
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vehicle"
                              id="vehicle3"
                              disabled
                              value="minivan"
                              checked={vehicle === "minivan"}
                              onChange={(e) => setVehicle(e.target.value)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="vehicle3"
                            >
                              <i className="fas fa-shuttle-van"></i> MiniVan-8
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vehicle"
                              id="vehicle4"
                              disabled
                              value="van"
                              checked={vehicle === "van"}
                              onChange={(e) => setVehicle(e.target.value)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="vehicle4"
                            >
                              <i className="fas fa-shuttle-van"></i> Van-9
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vehicle"
                              id="vehicle5"
                              disabled
                              value="minibus"
                              checked={vehicle === "minibus"}
                              onChange={(e) => setVehicle(e.target.value)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="vehicle5"
                            >
                              <i className="fas fa-bus"></i> MiniBus-30
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="vehicle"
                              id="vehicle6"
                              disabled
                              value="bus"
                              checked={vehicle === "bus"}
                              onChange={(e) => setVehicle(e.target.value)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="vehicle6"
                            >
                              <i className="fas fa-bus"></i> Bus-50
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                        {/* 
                    <div className="row">
                      <div className="form-label ml-3">
                        {" "}
                        Subtotal: <span>Rs.{subtotal}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-label ml-3">
                        {" "}
                        Discount(8%): <span>Rs.{Math.ceil(discount*subtotal)}</span>
                      </div>
                      </div>
                      */}
                    <div className="row">
                      <div className="form-label ml-3">
                        {" "}
                        Total: <span>Rs.{priceForUser}</span>
                      </div>
                    </div>

                    <div className="form-btn mt-2">
                      <button type="submit" className="submit-btn">
                        Book A Vehicle
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
