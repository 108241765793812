import { Fragment, useEffect } from "react";
import { getDriver } from "../../actions/driverActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Sidetoolbar from "./Sidetoolbar";
import { Carousel } from "react-bootstrap";

export default function SingleDriver() {
  const {  admindriver = {} } = useSelector(
    (state) => state.driverState
  );
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDriver(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidetoolbar />
        </div>
        <div className="col-12 col-md-10">
          <div className="card-confirm">
            <div className="container">
              <div className="row m-0">
                <div className="col-lg-7 pb-5 pe-lg-5">
                  <div className="row">
                    <div className="col-12 col-lg-5 img-fluid">
                      <Carousel pause="hover">
                        {admindriver.images &&
                          admindriver.images.map((image) => (
                            <Carousel.Item key={image._id}>
                              <img
                                className="d-block w-100"
                                src={image.image}
                                alt="vehicle"
                                height="500"
                                width="500"
                              />
                            </Carousel.Item>
                          ))}
                      </Carousel>
                    </div>

                    <div className="row m-0 bg-light">
                      <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                        <p className="text-muted">Name</p>
                        <p className="h5">{admindriver.name}</p>
                      </div>
                      <div className="col-md-4 col-6  ps-30 my-4">
                        <p className="text-muted">Email</p>
                        <p className="h6 m-0">{admindriver.driverEmail}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Contact No</p>
                        <p className="h5 m-0">{admindriver.contactNo}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">vehicle No</p>
                        <p className="h5 m-0">{admindriver.vehicleNo}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">vehicle Category</p>
                        <p className="h5 m-0">{admindriver.vehicleCategory}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Vehicle Modal</p>
                        <p className="h5 m-0">{admindriver.vehicleModal}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 p-0 ps-lg-4">
                  <div className="row m-0">
                    <div className="col-12 px-4">
                      <div className="d-flex align-items-end mt-4 mb-2">
                        <p className="h4 m-0">
                          <span className="pe-1">Trip Details</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12 px-0">
                      <div className="row bg-light m-0">
                        <div className="col-12 px-4">
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">Passengers</p>
                            <p className="fs-14 fw-bold">
                              {admindriver.maxPassengers}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">No of reviews</p>
                            <p className="fs-14 fw-bold">
                              {admindriver.numOfReviews}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">ratings</p>
                            <p className="fs-14 fw-bold">
                              {admindriver.ratings}
                            </p>
                          </div>
                          <div className="rating-outer">
                            <div
                              className="rating-inner"
                              style={{
                                width: `${(admindriver.ratings / 5) * 100}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
