import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function Profile() {
  const { user = {} } = useSelector((state) => state.authState);

  return (
    <Fragment>
      <MetaData title={"My profile"} />
      <div className="row justify-content-around mt-5 user-info">
        <div className="col-12 col-md-3 ml-5">
          <figure className="avatar avatar-profile">
            <img
              className="rounded-circle img-fluid"
              src={user.avatar ?? "/images/default_avatar.png"}
              alt=""
            />
          </figure>
          <Link
            to="/myprofile/update"
            id="edit_profile"
            className="btn btn-primary btn-block my-5"
          >
            Edit Profile
          </Link>
        </div>

        <div className="col-12 col-md-5 ml-5 mb-5">
          <h4>Full Name</h4>
          <p>{user.name}</p>

          <h4>Email Address</h4>
          <p>{user.email}</p>

          <h4>Joined date</h4>
          <p>{String(user.createdAt).substring(0, 10)}</p>

          {user.role === "user" && (
            <Link to="/mybookings" className="btn btn-success btn-block mt-5">
              My Bookings
            </Link>
          )}

          {user.role === "driver" && (
            <Link
              to="/driver/mydetails"
              className="btn btn-success btn-block mt-5"
            >
              My Vehicle Details
            </Link>
          )}

          <Link
            to="/myprofile/update/password"
            className="btn btn-danger btn-block mt-3"
          >
            Change Password
          </Link>
        </div>
      </div>

      <div>
        <Link
          to="https://wa.me/94760667724?text-Hello How can I help you ?"
          target="_blank"
        >
          <span className="btn-label">Admin Number</span>
          <img
            src="/images/whatsapp.png"
            alt="whatsapp"
            className="whatsapp_float"
          />
        </Link>
      </div>
    </Fragment>
  );
}
