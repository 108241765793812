import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layouts/MetaData";
import { getDriverBookShares } from "../../actions/shareActions";
import { useParams } from "react-router-dom";
import Tabs from "./Tabs";

export default function ViewShare() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { bookshares = [] } = useSelector((state) => state.shareState);

  useEffect(() => {
    dispatch(getDriverBookShares(id));
  }, [dispatch]);

  return (
    <Fragment>
      <MetaData title={"view share booking"} />

      <Tabs accept />
      <div className="card-confirm">
        <table className="wp-table">
          <tr>
            <th>Name</th>
            <th>Phone No</th>
            <th>Passengers</th>
            <th>Pickup Address</th>
          </tr>
          {bookshares &&
            bookshares.map((book) => (
              <tr>
                <td>{book.username}</td>
                <td>{book.teleno}</td>
                <td>{book.commuter}</td>
                <td>{book.pickupAddress}</td>
                
              </tr>
            ))}
        </table>
      </div>
    </Fragment>
  );
}
