export default function Carousel() {
  return (
    <section className="pt-5 pb-5" id="carouselsection">
      <div className="container" id="containcarousel">
        <div className="row">
          <div className="col-6">
            <h3
              className="mb-3"
              style={{ fontSize: 30, fontWeight: 50, textAlign: "center" }}
            >
              Sri Lanka Tourist Places{" "}
            </h3>
          </div>
          <div className="col-6 text-right">
            <a
              className="btn  mb-3 mr-1"
              id="btn-left"
              href="#carouselExampleIndicators2"
              role="button"
              data-slide="prev"
            >
              <i className="fa fa-arrow-left"></i>
            </a>
            <a
              className="btn  mb-3"
              id="btn-right"
              href="#carouselExampleIndicators2"
              role="button"
              data-slide="next"
            >
              <i className="fa fa-arrow-right"></i>
            </a>
          </div>
          <div className="col-12">
            <div
              id="carouselExampleIndicators2"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner overflow-auto">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/sigiriya.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Sigiriya</h4>
                          <p className="card-text">
                            Sigiriya is an ancient rock fortress which is
                            located near Dambulla.
                            <br />
                            <a
                              href="https://en.wikipedia.org/wiki/Sigiriya"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3 ">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/pinnawala.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">
                            Pinnawala Elephant Orphanage
                          </h4>
                          <p className="card-text">
                            Pinnawala Elephant Orphanage is located in
                            Rambukkana.
                            <a
                              href=" https://en.wikipedia.org/wiki/Pinnawala_Elephant_Orphanage"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/Lotus_tower.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Lotus Tower</h4>
                          <p className="card-text">
                            The lotus tower is located on the waterfront of
                            Beira Lake, in Colombo.
                            <a
                              href="https://en.wikipedia.org/wiki/Lotus_Tower"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/colombo_zoo.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Colombo Zoo</h4>
                          <p className="card-text">
                            Colombo Zoo is located in Dehiwala-Mount Lavinia,
                            Sri Lanka.{" "}
                            <a
                              href="https://en.wikipedia.org/wiki/National_Zoological_Gardens_of_Sri_Lanka"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/pigeon_island.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">
                            Pigeon Island National Park
                          </h4>
                          <p className="card-text">
                            {" "}
                            The national park is situated 1 km off the coast of
                            Nilaveli, a coastal town in Eastern Province, Sri
                            Lanka.
                            <br />
                            <a
                              href="https://en.wikipedia.org/wiki/Pigeon_Island_National_Park"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/nine_arch_bridge.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Nine Arches Bridge</h4>
                          <p className="card-text">
                            The Nine Arches Bridge is located in Demodara,
                            between both Demodara and Ella railway stations.
                            <a
                              href="https://en.wikipedia.org/wiki/Nine_Arch_Bridge"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/peradeniya.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Botanical Gardens</h4>
                          <p className="card-text">
                            The Royal Botanical Gardens, Peradeniya is located
                            in Kandy.{" "}
                            <a
                              href="https://en.wikipedia.org/wiki/Royal_Botanical_Gardens,_Peradeniya"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/jaffna_fort.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Jaffna Fort</h4>
                          <p className="card-text">
                            Jaffna Fort is located in Jaffna, which was
                            originally built in 1618.{" "}
                            <a
                              href="https://en.wikipedia.org/wiki/Pigeon_Island_National_Park"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <img
                          className="img-fluid"
                          alt="100%x280"
                          src="/images/places/galle_face.jpg"
                        />
                        <div className="card-body">
                          <h4 className="card-title">Galle Face</h4>
                          <p className="card-text">
                            Galle Face Beach is located in Colombo.
                            <a
                              href="https://en.wikipedia.org/wiki/Galle_Face_Green"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (source)
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
