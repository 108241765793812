import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { getHomeShares } from "../../actions/shareActions";
import moment from "moment";
import MetaData from "./MetaData";

export default function ShareCard() {
  const { shares = [] } = useSelector((state) => state.shareState);

  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    dispatch(getHomeShares);
  }, [dispatch]);

  return (
    <Fragment>
      <MetaData tittle={"Share vehicle"} />
      <div className="package" id="homepackage">
        <h1 className="pack-title">Share vehicle bookings</h1>

        <Carousel showDots={true} responsive={responsive}>
          {shares &&
            shares.map((share) => (
              <div className="card-pack" key={share._id}>
                {(share.vehicle === "car" || share.vehicle === "minicar") && (
                  <i className="fas fa-car"></i>
                )}
                {(share.vehicle === "van" || share.vehicle === "minivan") && (
                  <i className="fas fa-shuttle-van"></i>
                )}
                {(share.vehicle === "bus" || share.vehicle === "minibus") && (
                  <i className="fas fa-bus-alt"></i>
                )}
                <h2 className="price">{share.vehicle}</h2>
                <h2>
                  Rs.{share.shareAmount}
                  <span>/per person</span>
                </h2>
                <p>
                  From <b>{share.departure}</b>
                </p>
                <p>
                  To <b>{share.arrive}</b>
                </p>
                <p>
                  Date/time:{" "}
                  <b>
                    {share.departureDate}/
                    {moment(share.departureTime, "HH:mm").format("hh:mm A")}
                  </b>
                </p>
                {share.sharePassenger - share.count != 0 ? (
                  <p>
                    <b>{share.sharePassenger - share.count}</b> Seats Available
                  </p>
                ) : (
                  <p>No Seats Available</p>
                )}

                {share.count === share.sharePassenger ? (
                  <Link>
                    {" "}
                    <button className="btn-pack" disabled>
                      Full Book
                    </button>
                  </Link>
                ) : (
                  <Link to={`/bookshare/${share._id}`}>
                    {" "}
                    <button className="btn-pack">Book Now</button>
                  </Link>
                )}
              </div>
            ))}
        </Carousel>

        <h2 style={{ color: "red", textAlign: "center" }}>
          The fares of the bookings on this page, are decided by the drivers.
        </h2>
      </div>
    </Fragment>
  );
}
