import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomePackages } from "../../actions/packageActions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

export default function PackageCard() {
  const { packages = [] } = useSelector((state) => state.packageState);
  const { isAuthenticated, user } = useSelector((state) => state.authState);

  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    dispatch(getHomePackages);
  }, [dispatch]);

  return (
    <div className="package" id="homepackage">
      <h1 className="pack-title">Our Packages</h1>

      <Carousel showDots={true} responsive={responsive}>
        {packages &&
          packages.map((pack, i) => (
            <div className="card-pack" key={i}>
              {pack.vehiclePackage === "car" && <i className="fas fa-car"></i>}
              {pack.vehiclePackage === "van" && (
                <i className="fas fa-shuttle-van"></i>
              )}
              {pack.vehiclePackage === "bus" && (
                <i className="fas fa-bus-alt"></i>
              )}
              <h2 className="price">{pack.vehiclePackage}</h2>
              <h2 className="pricetotal">
                Rs.{pack.userAmount}/day{" "}
              </h2>
             {/* <h2 className="pricestrike">
                <strike>Rs.{pack.userAmount}/day</strike>{" "}
                <span className="offer">-8%</span>
            </h2>*/}
              <p>{pack.mileage}km Free mileage/day</p>
              <p>(Two-way)</p>
              <p>Rs.{pack.extra} extra per km</p>
              <p>{pack.passengers} passengers</p>

              {isAuthenticated && user.role === "user" ? (
                <p>
                  <Link to="/package">
                    {" "}
                    <button className="btn-pack">Book Now</button>
                  </Link>
                </p>
              ) : isAuthenticated && user.role === "driver" ? (
                <p>
                  <Link to="/">
                    {" "}
                    <button className="btn-pack">Book now</button>
                  </Link>
                </p>
              ) : (
                <p>
                  <Link to="/login">
                    {" "}
                    <button className="btn-pack">Book now</button>
                  </Link>
                </p>
              )}
            </div>
          ))}
      </Carousel>
    </div>
  );
}
