import { Fragment, useEffect, useState } from "react";
import { updatePassword, clearAuthError } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MetaData from "../layouts/MetaData";

export default function UpdatePassword() {
  const { isUpdated, error } = useSelector((state) => state.authState);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  
  const navigate = useNavigate();

  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("password", password);
    dispatch(updatePassword(formData));
  };

  useEffect(() => {
    if (isUpdated) {
      toast("password updated successfully", {
        type: "success",
        toastId: "upasswordsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setOldPassword("");
      setPassword("");
      navigate('/myprofile')
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "upassworderror",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [isUpdated, error, dispatch]);

  return (
    <Fragment>
      <MetaData title={"UpdatePassword"} />
      <div className="container">
        <div className="wrapper">
          <div className="title">Update Password</div>
          <form onSubmit={submitHandler}>
            <div className="field">
              <input
                type={!isVisible1 ? "password" : "text"}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <span className="eye" onClick={toggle1}>
                {isVisible1 ? (
                  <i className="fa fa-eye" id="eye" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    id="eye-slash"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
              <label>Old Password</label>
            </div>
            <div className="field">
              <input
                type={!isVisible ? "password" : "text"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="eye" onClick={toggle}>
                {isVisible ? (
                  <i className="fa fa-eye" id="eye" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    id="eye-slash"
                    aria-hidden="true"
                  ></i>
                )}
              </span>
              <label>New Password</label>
            </div>

            <div className="field">
              <input type="submit" value="Update Password" />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
