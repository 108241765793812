import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import { deletePackage, getPackages } from "../../actions/packageActions";
import { clearPackageDeleted, clearError } from "../../slices/packageSlice";

export default function PackageList() {
  const {
    packages = [],
    loading = true,
    error,
    isPackageDeleted,
  } = useSelector((state) => state.packageState);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deletePackage(id));
  };

  const setPackages = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Days",
          field: "days",
          sort: "asc",
        },
        {
          label: "Mileage",
          field: "mileage",
          sort: "asc",
        },
        {
          label: "Extra",
          field: "extra",
          sort: "asc",
        },
        {
          label: "vehicle",
          field: "vehiclePackage",
          sort: "asc",
        },
        {
          label: "Passengers",
          field: "passengers",
          sort: "asc",
        },
        {
          label: "User Amount",
          field: "userAmount",
          sort: "asc",
        },
        {
          label: "Driver Amount",
          field: "driverAmount",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    packages.forEach((packagedata) => {
      data.rows.push({
        id: packagedata._id,
        days: packagedata.days,
        mileage: packagedata.mileage,
        extra: packagedata.extra,
        vehiclePackage: packagedata.vehiclePackage,
        passengers: packagedata.passengers,
        userAmount: packagedata.userAmount,
        driverAmount: packagedata.driverAmount,
        actions: (
          <Fragment>
            <Link
              to={`/admin/package/${packagedata._id}`}
              className="btn btn-primary"
            >
              <i className="fa fa-pen"></i>
            </Link>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, packagedata._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "packagelisterror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    if (isPackageDeleted) {
      toast("package deleted successfully", {
        type: "success",
        toastId: "packagelistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearPackageDeleted()),
      });
      return;
    }

    dispatch(getPackages);
  }, [dispatch, error, isPackageDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Package List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setPackages()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
