import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import { getDriver } from "../../actions/driverActions";
import Sidetoolbar from "./Sidetoolbar";

export default function ReviewsDriver() {
  const { admindriver = {} } = useSelector((state) => state.driverState);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getDriver(id));
  }, [dispatch, id]);

  // console.log(driver)

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <div className="card-confirm">
          <table className="wp-table">
            <tr>
              <th>Rating</th>
              <th>Comment</th>
              <th>View</th>
            </tr>
            {admindriver.reviews &&
              admindriver.reviews.map((review) => (
                <tr>
                  <td>
                    <div className="rating-outer">
                      <div
                        className="rating-inner"
                        style={{ width: `${(review.rating / 5) * 100}%` }}
                      ></div>
                    </div>
                  </td>
                  <td>{review.comment}</td>
                  <td>
                    <Link
                      to={`/admin/singlebooking/${review.bookingId}`}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-eye"></i>
                    </Link>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}
