import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layouts/MetaData";
import {
  deleteAdminUserShare,
  getAdminBookShares,
} from "../../actions/shareActions";
import { useParams } from "react-router-dom";
import Sidetoolbar from "./Sidetoolbar";
import { Button } from "react-bootstrap";
import { clearShareDeleted } from "../../slices/shareSlice";
import { toast } from "react-toastify";

export default function UserShareList() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { bookshares = [], isShareDeleted } = useSelector(
    (state) => state.shareState
  );

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteAdminUserShare(id));
  };

  useEffect(() => {
    if (isShareDeleted) {
      toast("user deleted successfully", {
        type: "success",
        toastId: "adminsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearShareDeleted()),
      });
      return;
    }

    dispatch(getAdminBookShares(id));
  }, [dispatch, isShareDeleted]);

  return (
    <Fragment>
      <MetaData title={"book share vehicle"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidetoolbar />
        </div>

        <div className="col-12 col-md-10">
          <div className="card-confirm">
            <table className="wp-table">
              <tr>
                <th>Name</th>
                <th>Phone No</th>
                <th>Passengers</th>
                <th>Email</th>
                <th>Pickup Address</th>
                <th>Actions</th>
              </tr>
              {bookshares &&
                bookshares.map((book) => (
                  <tr>
                    <td>{book.username}</td>
                    <td>{book.teleno}</td>
                    <td>{book.commuter}</td>
                    <td>{book.useremail}</td>
                    <td>{book.pickupAddress}</td>
                    <td>
                      <Button className="btn btn-danger py-1 px-2 ml-2">
                        <i
                          className="fa fa-trash"
                          onClick={(e) => deleteHandler(e, book._id)}
                        ></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
