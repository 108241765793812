export default function DriverReview({ reviews, bookingId }) {
  let filterReview = reviews.filter((review) => {
    return review.bookingId === bookingId;
  });

  return (
    <div>
      <h5>My Review:</h5>
      <hr />
      {filterReview &&
        filterReview.map((review) => (
          <div key={review._id}>
            <div className="rating-outer">
              <div
                className="rating-inner"
                style={{ width: `${(review.rating / 5) * 100}%` }}
              ></div>
            </div>

            <p className="review_comment">{review.comment}</p>

            <hr />
          </div>
        ))}
    </div>
  );
}
