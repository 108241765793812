import axios from "axios";
import {
  adminDriversFail,
  adminDriversPaymentFail,
  adminDriversPaymentRequest,
  adminDriversPaymentSuccess,
  adminDriversRequest,
  adminDriversSuccess,
  createReviewFail,
  createReviewRequest,
  createReviewSuccess,
  deleteDriverFail,
  deleteDriverRequest,
  deleteDriverSuccess,
  driverFail,
  driverRequest,
  driverSuccess,
  driversAllFail,
  driversAllRequest,
  driversAllSuccess,
  newDriverFail,
  newDriverRequest,
  newDriverSuccess,
  reviewsFail,
  reviewsRequest,
  reviewsSuccess,
  updateDriverFail,
  updateDriverRequest,
  updateDriverSuccess,
  userDriversFail,
  userDriversRequest,
  userDriversSuccess,
} from "../slices/driverSlice";

//Admin-create new drivers
export const createNewDriver = (driverData) => async (dispatch) => {
  try {
    dispatch(newDriverRequest());
    const config = {
      headers: {
        "Content-type": "multipart/form-data", //images add
      },
    };

    const { data } = await axios.post(
      `/api/v1/admin/driver/new`,
      driverData,
      config
    );

    dispatch(newDriverSuccess(data));
  } catch (error) {
    //handle error
    dispatch(newDriverFail(error.response.data.message));
  }
};

//Admin-get all admin drivers
export const getDrivers = async (dispatch) => {
  try {
    dispatch(adminDriversRequest());

    const { data } = await axios.get(`/api/v1/admin/drivers`);

    dispatch(adminDriversSuccess(data));
  } catch (error) {
    //handle error
    dispatch(adminDriversFail(error.response.data.message));
  }
};

//Admin-get single driver

export const getDriver = (id) => async (dispatch) => {
  try {
    dispatch(driverRequest());

    const { data } = await axios.get(`/api/v1/admin/driver/${id}`);

    dispatch(driverSuccess(data));
  } catch (error) {
    //handle error
    dispatch(driverFail(error.response.data.message));
  }
};

//Admin-update driver
export const updateDriver = (id, driverData) => async (dispatch) => {
  try {
    dispatch(updateDriverRequest());

    const { data } = await axios.put(`/api/v1/admin/driver/${id}`, driverData);

    dispatch(updateDriverSuccess(data));
  } catch (error) {
    //handle error
    dispatch(updateDriverFail(error.response.data.message));
  }
};

//ADmin-delete driver
export const deleteDriver = (id) => async (dispatch) => {
  try {
    dispatch(deleteDriverRequest());

    await axios.delete(`/api/v1/admin/driver/${id}`);

    dispatch(deleteDriverSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteDriverFail(error.response.data.message));
  }
};

//Admin-get all admin drivers payment book
export const getDriversPayment = (id) => async (dispatch) => {
  try {
    dispatch(adminDriversPaymentRequest());

    const { data } = await axios.get(`/api/v1/admin/payment/${id}`);

    dispatch(adminDriversPaymentSuccess(data));
  } catch (error) {
    //handle error
    dispatch(adminDriversPaymentFail(error.response.data.message));
  }
};

//get all role=driver drivers
export const getAllDrivers = async (dispatch) => {
  try {
    dispatch(driversAllRequest());

    const { data } = await axios.get(`/api/v1/drivers`);

    dispatch(driversAllSuccess(data));
  } catch (error) {
    //handle error
    dispatch(driversAllFail(error.response.data.message));
  }
};

export const getReviews = async (dispatch) => {
  try {
    dispatch(reviewsRequest());
    const { data } = await axios.get(`/api/v1/review`);
    dispatch(reviewsSuccess(data));
  } catch (error) {
    //handle error
    dispatch(reviewsFail(error.response.data.message));
  }
};

//get all role=user drivers
export const getuserDrivers = async (dispatch) => {
  try {
    dispatch(userDriversRequest());

    const { data } = await axios.get(`/api/v1/users/drivers`);

    dispatch(userDriversSuccess(data));
  } catch (error) {
    //handle error
    dispatch(userDriversFail(error.response.data.message));
  }
};

//create review

export const createReview = (reviewData) => async (dispatch) => {
  try {
    dispatch(createReviewRequest());

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.put(`/api/v1/review`, reviewData, config);

    dispatch(createReviewSuccess(data));
  } catch (error) {
    //handle error
    dispatch(createReviewFail(error.response.data.message));
  }
};
