import { Fragment, useEffect, useState } from "react";
import Sidetoolbar from "./Sidetoolbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, clearPackageUpdated } from "../../slices/packageSlice";
import {  getAdminPackage, updatePackage } from "../../actions/packageActions";

export default function UpdatePackage() {
  const [days, setDays] = useState("");
  const [mileage, setMileage] = useState("");
  const [extra, setExtra] = useState("");
  const [vehiclePackage, setVehiclePackage] = useState("");
  const [passengers, setPassengers] = useState("");
  const [userAmount, setUserAmount] = useState("");
  const [driverAmount, setDriverAmount] = useState("");
  const categories = ["van", "car", "bus", "minicar", "minivan", "minibus"];

  const { id: packageId } = useParams();

  const {
    loading,
    isPackageUpdated = false,
    error,
    adminPackage={}
  } = useSelector((state) => state.packageState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("days", days);
    formData.append("mileage", mileage);
    formData.append("extra", extra);
    formData.append("vehiclePackage", vehiclePackage);
    formData.append("passengers", passengers);
    formData.append("userAmount", userAmount);
    formData.append("driverAmount", driverAmount);
   
    dispatch(updatePackage(packageId, formData));
   
  };

  useEffect(() => {
    if (isPackageUpdated) {
      toast("Package updated successfully", {
        type: "success",
        toastId: "packageupdatesuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearPackageUpdated()),
      });
      navigate("/admin/package");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "packageupdateerror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }

    dispatch(getAdminPackage(packageId))
    
  }, [isPackageUpdated, error, dispatch,packageId]);

  useEffect(() => {
    if (adminPackage._id) {
      setDays(adminPackage.days);
      setMileage(adminPackage.mileage);
      setExtra(adminPackage.extra);
      setVehiclePackage(adminPackage.vehiclePackage);
      setPassengers(adminPackage.passengers);
      setUserAmount(adminPackage.userAmount);
      setDriverAmount(adminPackage.driverAmount);
     
    }
  }, [adminPackage]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>
      <div className="col-12 col-md-10">
        <Fragment>
          <div className="container">
            <div className="wrapper">
              <div className="title">Update Package</div>
              <form onSubmit={submitHandler} encType="multipart/form-data">
                <div className="field">
                  <input
                    type="text"
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    required
                  />
                  <label>Days</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={mileage}
                    onChange={(e) => setMileage(e.target.value)}
                    required
                  />
                  <label>Mileage</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={extra}
                    onChange={(e) => setExtra(e.target.value)}
                    required
                  />
                  <label>Extra</label>
                </div>
                <div className="field">
                  <select
                    onChange={(e) => setVehiclePackage(e.target.value)}
                    value={vehiclePackage}
                  >
                    <option value="">Select vehicle</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={passengers}
                    onChange={(e) => setPassengers(e.target.value)}
                    required
                  />
                  <label>passengers</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={userAmount}
                    onChange={(e) => setUserAmount(e.target.value)}
                    required
                  />
                  <label>User Amount</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    value={driverAmount}
                    onChange={(e) => setDriverAmount(e.target.value)}
                    required
                  />
                  <label>Driver Amount</label>
                </div>

                <div className="field" disabled={loading} id="btn">
                  <input type="submit" value="Update" />
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
