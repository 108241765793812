import { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveBookingInfo } from "../../slices/bookingSlice";
import { toast } from "react-toastify";
import MenuBar from "../booking/MenuBar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CheckOut from "./CheckOut";
import { Autocomplete } from "@react-google-maps/api";
import { Box, HStack, Input } from "@chakra-ui/react";
import MetaData from "../layouts/MetaData";

export const validateBooking = (bookingInfo, navigate) => {
  if (
    !bookingInfo.phoneNo ||
    !bookingInfo.pickupDate ||
    !bookingInfo.pickupTime ||
    !bookingInfo.pickupPlace
  ) {
    toast.error("please fill the booking details", {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "packbookformerror",
    });
    navigate("/packageform");
  }
};

export default function FormPackage() {
  const [phoneNo, setPhoneNo] = useState("");
  const [pickupDate, setDate] = useState("");
  const [pickupTime, setTime] = useState("");
  const [bookDay,setBookDay]=useState("");
  const [valid, setValid] = useState(true);
  const pickupRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let pickupPlace = pickupRef.current ? pickupRef.current.value : "";

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveBookingInfo({ pickupPlace, phoneNo, pickupDate, pickupTime,bookDay }));
    navigate("/packageconfirm");
  };

  const handleChange = (value) => {
    setPhoneNo(value);
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNo) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNo);
  };

  return (
    <Fragment>
      <MetaData title={"package personal details"} />
      <MenuBar mypackage />
      <CheckOut personalpack />
      <div id="booking" className="section">
        <div className="section-center">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-md-push-7">
                <div className="booking-cta">
                  <img src="/images/backgroundlogo.png" alt="logoimg" />
                  <h1>Make your reservation</h1>
                </div>
              </div>
              <div className="col-md-7 col-md-pull-5">
                <div className="booking-form">
                  <form onSubmit={submitHandler} encType="multipart/form-data">
                    <HStack spacing={2} justifyContent="space-between">
                      <Box flexGrow={1}>
                        <span className="form-label">Pickup Place</span>
                        <Autocomplete
                          options={{ componentRestrictions: { country: "lk" } }}
                        >
                          <Input
                            id="search"
                            type="text"
                            placeholder="Pickup Place"
                            ref={pickupRef}
                            required
                          />
                        </Autocomplete>
                      </Box>
                    </HStack>

                    <div className="form-group">
                      <span className="form-label">Your Contact Number</span>
                      <PhoneInput
                        country={"lk"}
                        value={phoneNo}
                        onChange={handleChange}
                        placeholder="Enter your Contact Number"
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        required
                      />
                      {!valid && <p>Please enter a valid phone number.</p>}
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Pickup Date</span>
                          <input
                            className="form-control"
                            type="date"
                            value={pickupDate}
                            onChange={(e) => setDate(e.target.value)}
                            min={new Date().toISOString().split("T")[0]}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Pickup Time</span>
                          <input
                            className="form-control"
                            type="time"
                            value={pickupTime}
                            onChange={(e) => setTime(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                          <span className="form-label">Days</span>
                          <input
                            className="form-control"
                            type="number"
                            min={1}
                            max={10}
                            value={bookDay}
                            onChange={(e) => setBookDay(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-btn">
                      <button type="submit" className="submit-btn">
                        Book A Vehicle
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
