import { useDispatch, useSelector } from "react-redux";
import Sidetoolbar from "./Sidetoolbar";
import { Fragment, useEffect } from "react";
import { getDriver, getDriversPayment } from "../../actions/driverActions";
import { Link, useParams } from "react-router-dom";
import { adminBookings } from "../../actions/bookingActions";
import { MDBDataTable } from "mdbreact";

export default function DriverPayment() {
  const { driversPay = [], admindriver = {} } = useSelector(
    (state) => state.driverState
  );
  const { adminBooking = [] } = useSelector((state) => state.bookingState);
  const { id } = useParams();

  console.log(driversPay);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDriversPayment(id));
    dispatch(adminBookings);
    dispatch(getDriver(id));
  }, [dispatch, id]);

  let totalAmount = 0;
  if (driversPay.length > 0) {
    driversPay.forEach((book) => {
      totalAmount += book.priceForDriver;
    });
  }

  let totalPaid = 0;
  if (driversPay.length > 0) {
    let paid = driversPay.filter((pay) => {
      return pay.driverPayment === "paid";
    });
    paid.forEach((book) => {
      totalPaid += book.priceForDriver;
    });
  }

  let totalUnPaid = 0;
  if (driversPay.length > 0) {
    let unpaid = driversPay.filter((pay) => {
      return pay.driverPayment === "unpaid";
    });
    unpaid.forEach((book) => {
      totalUnPaid += book.priceForDriver;
    });
  }

  const adminFilterBooking = adminBooking.filter((book) => {
    console.log(book.status.driver);
    return (
      book.status.bookStatus === "Completed" &&
      book.status.driver === admindriver.user
    );
  });

  console.log(id);

  const setBooking = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Phone No",
          field: "phoneNo",
          sort: "asc",
        },
        {
          label: "Amount",
          field: "priceForDriver",
          sort: "asc",
        },
        {
          label: "Pickup date",
          field: "pickupDate",
          sort: "asc",
        },
        {
          label: "Pickup Time",
          field: "pickupTime",
          sort: "asc",
        },
        {
          label: "Status",
          field: "bookStatus",
          sort: "asc",
        },
        {
          label: "Payment",
          field: "driverPayment",
          sort: "asc",
        },
        {
          label: "Actions of changing data",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    adminFilterBooking.forEach((booking) => {
      data.rows.push({
        id: booking._id,
        phoneNo: booking.phoneNo,
        priceForDriver: booking.priceForDriver,
        noOfPassengers: booking.noOfPassengers,
        pickupDate: booking.pickupDate,
        pickupTime: booking.pickupTime,
        bookStatus: (
          <p
            style={{
              color: booking.status.bookStatus.includes("processing")
                ? "red"
                : "green",
            }}
          >
            {booking.status.bookStatus}
          </p>
        ),
        driverPayment: (
          <p
            style={{
              color: booking.driverPayment.includes("unpaid") ? "red" : "green",
            }}
          >
            {booking.driverPayment}
          </p>
        ),
        actions: (
          <Fragment>
            <Link
              to={`/admin/bookings/${booking._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-pen"></i>
            </Link>
          </Fragment>
        ),
      });
    });
    return data;
  };

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Dashboard</h1>
        <div className="row pr-4">
          <div className="col-xl-4 col-sm-12 mb-3">
            <div className="card text-white bg-primary o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Total Amount
                  <br /> <b>Rs.{totalAmount}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-12 mb-3">
            <div className="card text-white bg-success o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  Paid
                  <br /> <b>Rs.{totalPaid}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-12 mb-3">
            <div className="card text-white bg-danger o-hidden h-100">
              <div className="card-body">
                <div className="text-center card-font-size">
                  UnPaid
                  <br /> <b>Rs.{totalUnPaid}</b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className="my-4">Booking List</h1>
        <Fragment>
          <MDBDataTable
            data={setBooking()}
            bordered
            striped
            hover
            responsive
            className="px-3"
          />
        </Fragment>
      </div>
    </div>
  );
}
