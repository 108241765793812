import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    book: {},
    bookingDetail: {},
    singleDriver: {},
    adminBookingDetail: {},
    driverDetail: {},
    userBooking: [],
    adminBooking: [],
    driverBooking: [],
    logInDriver: {},
    driverAcceptBooking: [],
    driverCompleteBooking: [],
    loading: false,
    isBookingDeleted: false,
    isBookingUpdated: false,
    bookingInfo: localStorage.getItem("bookingInfo")
      ? JSON.parse(localStorage.getItem("bookingInfo"))
      : {},
  },
  reducers: {
    adminBookingsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    adminBookingsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        adminBooking: action.payload.bookings,
      };
    },
    adminBookingsFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    adminBookingDetailRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    adminBookingDetailSuccess(state, action) {
      return {
        ...state,
        loading: false,
        adminBookingDetail: action.payload.booking,
      };
    },
    adminBookingDetailFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    deleteBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isBookingDeleted: true,
      };
    },
    deleteBookingFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearBookingDeleted(state, action) {
      return {
        ...state,
        isBookingDeleted: false,
      };
    },
    updateAdminBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateAdminBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isBookingUpdated: true,
      };
    },
    updateAdminBookingFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearAdminBookingUpdated(state, action) {
      return {
        ...state,
        isBookingUpdated: false,
      };
    },

    driverBookingsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    driverBookingsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        driverBooking: action.payload.booking,
        logInDriver: action.payload.filterDriver,
        cancelledCount: action.payload.cancelledCount,
      };
    },
    driverBookingsFail(state, action) {
      logoutNavigate(action);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    driveracceptBookingsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    driveracceptBookingsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        driverAcceptBooking: action.payload.booking,
        encryptedCode: action.payload.encryptedCode,
      };
    },
    driveracceptBookingsFail(state, action) {
      logoutNavigate(action);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    drivercompleteBookingsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    drivercompleteBookingsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        driverCompleteBooking: action.payload.booking,
      };
    },
    drivercompleteBookingsFail(state, action) {
      logoutNavigate(action);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    updateBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isBookingUpdated: true,
      };
    },
    updateBookingFail(state, action) {
      logoutNavigate(action);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearBookingUpdated(state, action) {
      return {
        ...state,
        isBookingUpdated: false,
      };
    },
    driverDetailRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    driverDetailSuccess(state, action) {
      return {
        ...state,
        loading: false,
        driverDetail: action.payload.booking,
      };
    },
    driverDetailFail(state, action) {
      logoutNavigate(action);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    createBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    createBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        book: action.payload.book,
      };
    },
    createBookingFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearBookingCreated(state, action) {
      return {
        ...state,
        isBookingCreated: false,
      };
    },
    clearBookingError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    userBookingsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    userBookingsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        userBooking: action.payload.bookings,
      };
    },
    userBookingsFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    bookingDetailRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    bookingDetailSuccess(state, action) {
      return {
        ...state,
        loading: false,
        bookingDetail: action.payload.booking,
        singleDriver: action.payload.filterDriver,
      };
    },
    bookingDetailFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    cancelBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    cancelBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isBookingUpdated: true,
      };
    },
    cancelBookingFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearBookingCancelled(state, action) {
      return {
        ...state,
        isBookingUpdated: false,
      };
    },
    adminCancelBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    adminCancelBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isBookingUpdated: true,
      };
    },
    adminCancelBookingFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearadminBookingCancelled(state, action) {
      return {
        ...state,
        isBookingUpdated: false,
      };
    },
    userUpdateBookingRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    userUpdateBookingSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isBookingUpdated: true,
      };
    },
    userUpdateBookingFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearBookinguserUpdate(state, action) {
      return {
        ...state,
        isBookingUpdated: false,
      };
    },

    saveBookingInfo(state, action) {
      localStorage.setItem("bookingInfo", JSON.stringify(action.payload));

      return {
        ...state,
        bookingInfo: action.payload,
      };
    },
    bookingCompleted(state, action) {
      localStorage.removeItem("bookingInfo");
      sessionStorage.removeItem("mapInfo");

      return {
        loading: false,
        bookingInfo: {},
        mapInfo: {},
      };
    },
  },
});

const { actions, reducer } = bookingSlice;

const logoutNavigate = (action) => {
  if (action.payload === "suspended") {
    window.location.href = "/login";
  }
};

export const {
  createBookingRequest,
  createBookingSuccess,
  createBookingFail,
  clearBookingCreated,
  clearBookingError,
  userBookingsFail,
  userBookingsSuccess,
  userBookingsRequest,
  bookingDetailFail,
  bookingDetailRequest,
  bookingDetailSuccess,
  driverDetailFail,
  driverDetailRequest,
  driverDetailSuccess,
  adminBookingsRequest,
  adminBookingsFail,
  adminBookingsSuccess,
  deleteBookingFail,
  deleteBookingRequest,
  deleteBookingSuccess,
  clearBookingDeleted,
  updateAdminBookingRequest,
  updateAdminBookingSuccess,
  updateAdminBookingFail,
  clearAdminBookingUpdated,
  updateBookingFail,
  updateBookingSuccess,
  updateBookingRequest,
  clearBookingUpdated,
  saveBookingInfo,
  bookingCompleted,
  driverBookingsFail,
  driverBookingsRequest,
  driverBookingsSuccess,
  driveracceptBookingsRequest,
  driveracceptBookingsSuccess,
  driveracceptBookingsFail,
  drivercompleteBookingsRequest,
  drivercompleteBookingsSuccess,
  drivercompleteBookingsFail,
  cancelBookingFail,
  cancelBookingRequest,
  cancelBookingSuccess,
  clearBookingCancelled,
  adminBookingDetailRequest,
  adminBookingDetailSuccess,
  adminBookingDetailFail,
  adminCancelBookingFail,
  adminCancelBookingRequest,
  adminCancelBookingSuccess,
  clearadminBookingCancelled,
  userUpdateBookingFail,
  userUpdateBookingSuccess,
  userUpdateBookingRequest,
  clearBookinguserUpdate,
} = actions;

export default reducer;
