import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { driverCompleteBookings } from "../../actions/bookingActions";
import { clearBookingError } from "../../slices/bookingSlice";
import Tabs from "./Tabs";
import { Link } from "react-router-dom";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";
import moment from "moment";

export default function CompletedBooking() {
  const {
    driverCompleteBooking = [],
    loading = true,
    error,
  } = useSelector((state) => state.bookingState);

  const dispatch = useDispatch();

  const newbook = driverCompleteBooking;

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "completeerror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }

    dispatch(driverCompleteBookings);
  }, [dispatch, error]);

  return (
    <Fragment>
      <MetaData title={"Completed"} />
      <Tabs complete />

      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 className="text-center">Completed List</h1>
          <br />

          <div className="row" id="ads">
            {newbook.map((booking) => (
              <div className="col-md-4 mb-5">
                <div className="card rounded">
                  <div className="card-image">
                    <span className="card-notify-badge">
                      Rs.{booking.priceForDriver}
                    </span>

                    <img
                      className="img-complete"
                      style={{
                        width: "120px",
                        height: "100px",
                        marginBlock: "5%",
                        position: "relative",
                        margin: "10px 35%",
                      }}
                      src="/images/taxiImg.jpeg"
                      alt="Alternate Text"
                    />
                    <span
                      className="card-notify-badge-pay"
                      style={{
                        backgroundColor: booking.driverPayment.includes(
                          "unpaid"
                        )
                          ? "red"
                          : "green",
                      }}
                    >
                      {booking.driverPayment}
                    </span>
                  </div>
                  <div className="card-image-overlay m-auto">
                    <span className="card-detail-badge">
                      {booking.noOfPassengers}
                    </span>
                    <span className="card-detail-badge">
                      {booking.pickupDate}
                    </span>
                    <span className="card-detail-badge">
                      {moment(booking.pickupTime, "HH:mm").format("hh:mm A")}
                    </span>
                  </div>
                  {booking.packageInfo ? (
                    <div className="card-body text-center">
                      <div className="ad-title m-auto">
                        <h5>{booking.packageInfo.days} days</h5>
                      </div>
                      <div className="ad-title m-auto">
                        <h5>{booking.packageInfo.mileage} Free Mileage</h5>
                      </div>
                      <Link
                        className="ad-btn"
                        to={`/driver/completedDetail/${booking._id}`}
                      >
                        View
                      </Link>
                    </div>
                  ) : (
                    <div className="card-body text-center">
                      <div className="ad-title m-auto">
                        <h5>{booking.mapInfo.pickupPoint}</h5>
                      </div>
                      <div className="ad-title m-auto">
                        <h5>{booking.mapInfo.dropPoint}</h5>
                      </div>
                      <Link
                        className="ad-btn"
                        to={`/driver/completedDetail/${booking._id}`}
                      >
                        View
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Fragment>
  );
}
