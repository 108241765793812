import { Fragment } from "react";
import MetaData from "./MetaData";

export default function Refund() {
  return (
    <Fragment>
      <MetaData title={"Refund policy"} />
      <div className="refund">
        <h1>
          <b>Refund Policy</b>
        </h1>

        <p>
        Thank you for using www.bro3taxi.com. 
        We value your satisfaction and aim to provide you with the best experience possible. 
        If, for any reason, you are not completely satisfied with your booking, we are here to help.
        </p>

        <h4>
          <b>1. Refunds</b>
        </h4>
        <p>
        Once we receive your booking, you will have until two days before your booking to cancel your booking in the 'My Booking' section. 
        If you cancel your booking during this period your total amount will be refunded back. 
        The refund will be given to the card that was used for the purchase. However, if cancelled after this period there will be no refund available.
          
        </p>
        <h4>
          <b>2. Processing Time</b>
        </h4>
        <p>
         Refunds will be processed as soon as you cancel your booking. 
         Please note that it may take around 3 - 10 business days to appear back in your account, depending on your payment provider. 
          
        </p>

        <h4>
          <b>3. Contact Us</b>
        </h4>
        <p>
          If you have any questions or concerns regarding our refund policy, please contact our customer support team. 
          We are here to assist you and ensure your experience with us is enjoyable and hassle-free.

        </p>

      </div>
    </Fragment>
  );
}
