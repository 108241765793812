export default function CheckOut({
  packages,
  personalpack,
  paymentpack,
}) {
  return (
    <form id="msform">
      {packages && (
        <ul id="progressbar">
          <li className="active" id="package">
            <strong>Package</strong>
          </li>
          <li id="personal">
            <strong>Personal</strong>
          </li>
          <li id="confirm">
            <strong>Payment</strong>
          </li>
        </ul>
      )}
      {personalpack && (
        <ul id="progressbar">
          <li className="active" id="package">
            <strong>Package</strong>
          </li>
          <li className="active" id="personal">
            <strong>Personal</strong>
          </li>
          <li id="confirm">
            <strong>Payment</strong>
          </li>
        </ul>
      )}

      {paymentpack && (
        <ul id="progressbar">
          <li className="active" id="package">
            <strong>Package</strong>
          </li>
          <li className="active" id="personal">
            <strong>Personal</strong>
          </li>
          <li className="active" id="confirm">
            <strong>Payment</strong>
          </li>
        </ul>
      )}
    </form>
  );
}
