import { createSlice } from "@reduxjs/toolkit";

const packageSlice = createSlice({
  name: "package",
  initialState: {
    loading: false,
    package: {},
    packages: [],
    packageDriver: {},
    adminPackage:{},
    packageUser: [],
    isPackageCreated: false,
    isPackageDeleted: false,
    isPackageUpdated: false,
  },
  reducers: {
    packageRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    packageSuccess(state, action) {
      return {
        ...state,
        loading: false,
        packages: action.payload.packages,
      };
    },
    packageFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearPackage(state, action) {
      return {
        ...state,
        package: {},
      };
    },
    newPackageRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newPackageSuccess(state, action) {
      return {
        ...state,
        loading: false,
        package: action.payload.package,
        isPackageCreated: true,
      };
    },
    newPackageFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isPackageCreated: false,
      };
    },
    clearPackageCreated(state, action) {
      return {
        ...state,
        isPackageCreated: false,
      };
    },
    packageSingleRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    packageSingleSuccess(state, action) {
      return {
        ...state,
        loading: false,
        adminPackage: action.payload.package,
      };
    },
    packageSingleFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    deletePackageRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deletePackageSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isPackageDeleted: true,
      };
    },
    deletePackageFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isPackageDeleted: false,
      };
    },
    clearPackageDeleted(state, action) {
      return {
        ...state,
        isPackageDeleted: false,
      };
    },
    updatePackageRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updatePackageSuccess(state, action) {
      return {
        ...state,
        loading: false,
        package: action.payload.package,
        isPackageUpdated: true,
      };
    },
    updatePackageFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearPackageUpdated(state, action) {
      return {
        ...state,
        isPackageUpdated: false,
      };
    },
    packageDriverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    packageDriverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        packageDriver: action.payload.packages,
      };
    },
    packageDriverFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    packageUserRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    packageUserSuccess(state, action) {
      return {
        ...state,
        loading: false,
        packageUser: action.payload.packages,
      };
    },
    packageUserFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    packageHomeRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    packageHomeSuccess(state, action) {
      return {
        ...state,
        loading: false,
        packages: action.payload.packages,
      };
    },
    packageHomeFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = packageSlice;

export const {
  packageRequest,
  packageSuccess,
  packageFail,
  clearPackage,
  clearError,
  newPackageRequest,
  newPackageSuccess,
  newPackageFail,
  clearPackageCreated,
  packageSingleFail,
  packageSingleRequest,
  packageSingleSuccess,
  deletePackageFail,
  deletePackageSuccess,
  deletePackageRequest,
  clearPackageDeleted,
  updatePackageFail,
  updatePackageRequest,
  updatePackageSuccess,
  clearPackageUpdated,
  packageDriverFail,
  packageDriverRequest,
  packageDriverSuccess,
  packageUserFail,
  packageUserRequest,
  packageUserSuccess,
  packageHomeFail,
  packageHomeRequest,
  packageHomeSuccess,
} = actions;

export default reducer;
