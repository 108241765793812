import { Fragment } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function PageNotFound() {
  return (
    <Fragment>
      <MetaData title={"page not found"} />
      <div className="row justify-content-center">
        <div className="col-6 mt-5 text-center">
          <h1>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>

          <Link to="/">Go to Home Page</Link>
        </div>
      </div>
    </Fragment>
  );
}
