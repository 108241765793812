import { Fragment, useEffect } from "react";

import { useState } from "react";
import { clearAuthError, login } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const toggle = () => {
    setVisible(!isVisible);
  };

  const { loading, error, isAuthenticated, user } = useSelector(
    (state) => state.authState
  );

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (isAuthenticated && user.role === "user") {
      navigate("/map");
      return;
    }
    if (isAuthenticated && user.role === "driver") {
      navigate("/driver/newbooking");
    }
    if (isAuthenticated && user.role === "admin") {
      navigate("/admin/dashboard");
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "login",
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
      return;
    }
  }, [error, isAuthenticated, dispatch, navigate]);
  return (
    <Fragment>
      <MetaData title={"Login"} />
      <div className="container">
        <div className="wrapper">
          <div className="title">Login Form</div>
          <form onSubmit={submitHandler}>
            <div className="field">
              <input
                type="text"
                value={email.toLowerCase().trim()}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label>Email Address</label>
            </div>
            <div className="field">
              <input
                type={!isVisible ? "password" : "text"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="eye" onClick={toggle}>
                {isVisible ? (
                  <i className="fa fa-eye" id="eye" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-eye-slash"
                    id="eye-slash"
                    aria-hidden="true"
                  ></i>
                )}
              </span>

              <label>Password</label>
            </div>

            <div className="field" disabled={loading}>
              <input type="submit" value="Login" />
            </div>
            <div className="content">
              <div className="pass-link">
                <a href="/password/forgot">Forgot password?</a>
              </div>
            </div>
            <div className="signup-link">
              Not a member? <Link to="/register">Sign up</Link>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
