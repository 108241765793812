import axios from "axios";
import {
  deletePackageFail,
  deletePackageRequest,
  deletePackageSuccess,
  newPackageFail,
  newPackageRequest,
  newPackageSuccess,
  packageDriverFail,
  packageDriverRequest,
  packageDriverSuccess,
  packageFail,
  packageHomeFail,
  packageHomeRequest,
  packageHomeSuccess,
  packageRequest,
  packageSingleFail,
  packageSingleRequest,
  packageSingleSuccess,
  packageSuccess,
  packageUserFail,
  packageUserRequest,
  packageUserSuccess,
  updatePackageFail,
  updatePackageRequest,
  updatePackageSuccess,
} from "../slices/packageSlice";

//create new customs
export const createNewPackage =
  (
    days,
    mileage,
    extra,
    vehiclePackage,
    passengers,
    userAmount,
    driverAmount
  ) =>
  async (dispatch) => {
    try {
      dispatch(newPackageRequest());

      const { data } = await axios.post(`/api/v1/admin/package/new`, {
        days,
        mileage,
        extra,
        vehiclePackage,
        passengers,
        userAmount,
        driverAmount,
      });

      dispatch(newPackageSuccess(data));
    } catch (error) {
      //handle error
      dispatch(newPackageFail(error.response.data.message));
    }
  };

//get admin customs
export const getPackages = async (dispatch) => {
  try {
    dispatch(packageRequest());

    const { data } = await axios.get(`/api/v1/admin/package`);

    dispatch(packageSuccess(data));
  } catch (error) {
    //handle error
    dispatch(packageFail(error.response.data.message));
  }
};

//delete driver
export const deletePackage = (id) => async (dispatch) => {
  try {
    dispatch(deletePackageRequest());

    await axios.delete(`/api/v1/admin/package/${id}`);

    dispatch(deletePackageSuccess());
  } catch (error) {
    //handle error
    dispatch(deletePackageFail(error.response.data.message));
  }
};

//get driver customs
export const getPackagesDriver = async (dispatch) => {
  try {
    dispatch(packageDriverRequest());

    const { data } = await axios.get(`/api/v1/driver/package`);

    dispatch(packageDriverSuccess(data));
  } catch (error) {
    //handle error
    dispatch(packageDriverFail(error.response.data.message));
  }
};

//get driver customs
export const getPackagesUser = async (dispatch) => {
  try {
    dispatch(packageUserRequest());

    const { data } = await axios.get(`/api/v1/user/package`);

    dispatch(packageUserSuccess(data));
  } catch (error) {
    //handle error
    dispatch(packageUserFail(error.response.data.message));
  }
};

//get Home package
export const getHomePackages = async (dispatch) => {
  try {
    dispatch(packageHomeRequest());

    const { data } = await axios.get(`/api/v1/packages`);

    dispatch(packageHomeSuccess(data));
  } catch (error) {
    //handle error
    dispatch(packageHomeFail(error.response.data.message));
  }
};



export const getAdminPackage = (id) => async (dispatch) => {
  try {
    dispatch(packageSingleRequest());

    const { data } = await axios.get(`/api/v1/admin/package/${id}`);

    dispatch(packageSingleSuccess(data));
  } catch (error) {
    //handle error
    dispatch(packageSingleFail(error.response.data.message));
  }
};

//Admin-update custom
export const updatePackage = (id, packageData) => async (dispatch) => {
  try {
    dispatch(updatePackageRequest());
    const config = {
      headers: {
        "Content-type": "application/json", //without image
      },
    };

    const { data } = await axios.put(`/api/v1/admin/package/${id}`, packageData,config);

    dispatch(updatePackageSuccess(data));
  } catch (error) {
    //handle error
    dispatch(updatePackageFail(error.response.data.message));
  }
};