import axios from "axios";
import {
  adminBookingDetailFail,
  adminBookingDetailRequest,
  adminBookingDetailSuccess,
  adminBookingsFail,
  adminBookingsRequest,
  adminBookingsSuccess,
  adminCancelBookingFail,
  adminCancelBookingRequest,
  adminCancelBookingSuccess,
  bookingDetailFail,
  bookingDetailRequest,
  bookingDetailSuccess,
  cancelBookingFail,
  cancelBookingRequest,
  cancelBookingSuccess,
  createBookingFail,
  createBookingRequest,
  createBookingSuccess,
  deleteBookingFail,
  deleteBookingRequest,
  deleteBookingSuccess,
  driverBookingsFail,
  driverBookingsRequest,
  driverBookingsSuccess,
  driverDetailFail,
  driverDetailRequest,
  driverDetailSuccess,
  driveracceptBookingsFail,
  driveracceptBookingsRequest,
  driveracceptBookingsSuccess,
  drivercompleteBookingsFail,
  drivercompleteBookingsRequest,
  drivercompleteBookingsSuccess,
  updateAdminBookingFail,
  updateAdminBookingRequest,
  updateAdminBookingSuccess,
  updateBookingFail,
  updateBookingRequest,
  updateBookingSuccess,
  userBookingsFail,
  userBookingsRequest,
  userBookingsSuccess,
  userUpdateBookingFail,
  userUpdateBookingRequest,
  userUpdateBookingSuccess,
} from "../slices/bookingSlice";

//admin all booking
export const adminBookings = async (dispatch) => {
  try {
    dispatch(adminBookingsRequest());
    const { data } = await axios.get(`/api/v1/admin/bookings`);
    dispatch(adminBookingsSuccess(data));
  } catch (error) {
    dispatch(adminBookingsFail(error.response.data.message));
  }
};

// admin booking detail
export const adminBookingDetailActions = (id) => async (dispatch) => {
  try {
    dispatch(adminBookingDetailRequest());
    const { data } = await axios.get(`/api/v1/admin/booking/${id}`);
    dispatch(adminBookingDetailSuccess(data));
  } catch (error) {
    dispatch(adminBookingDetailFail(error.response.data.message));
  }
};

//admin delete booking
export const deleteBooking = (id) => async (dispatch) => {
  try {
    dispatch(deleteBookingRequest());
    await axios.delete(`/api/v1/admin/booking/${id}`);
    dispatch(deleteBookingSuccess());
  } catch (error) {
    dispatch(deleteBookingFail(error.response.data.message));
  }
};

//admin update bookings
export const updateAdminBooking = (id, bookingData) => async (dispatch) => {
  try {
    dispatch(updateAdminBookingRequest());
    const { data } = await axios.put(
      `/api/v1/admin/bookings/${id}`,
      bookingData
    );
    dispatch(updateAdminBookingSuccess(data));
  } catch (error) {
    dispatch(updateAdminBookingFail(error.response.data.message));
  }
};

//driver get all bookings
export const driverBookings = async (dispatch) => {
  try {
    dispatch(driverBookingsRequest());
    const { data } = await axios.get(`/api/v1/driver/bookings`);
    dispatch(driverBookingsSuccess(data));
  } catch (error) {
    dispatch(driverBookingsFail(error.response.data.message));
  }
};

export const driverAcceptBookings = async (dispatch) => {
  try {
    dispatch(driveracceptBookingsRequest());
    const { data } = await axios.get(`/api/v1/driver/acceptbookings`);
    dispatch(driveracceptBookingsSuccess(data));
  } catch (error) {
    dispatch(driveracceptBookingsFail(error.response.data.message));
  }
};

export const driverCompleteBookings = async (dispatch) => {
  try {
    dispatch(drivercompleteBookingsRequest());
    const { data } = await axios.get(`/api/v1/driver/completebookings`);
    dispatch(drivercompleteBookingsSuccess(data));
  } catch (error) {
    dispatch(drivercompleteBookingsFail(error.response.data.message));
  }
};

//driver update bookings
export const updateDriverBooking = (id, bookingData) => async (dispatch) => {
  try {
    dispatch(updateBookingRequest());
    const { data } = await axios.put(
      `/api/v1/driver/bookings/${id}`,
      bookingData
    );
    dispatch(updateBookingSuccess(data));
  } catch (error) {
    dispatch(updateBookingFail(error.response.data.message));
  }
};

//driver - completed details

export const driverDetailActions = (id) => async (dispatch) => {
  try {
    dispatch(driverDetailRequest());
    const { data } = await axios.get(`/api/v1/driver/booking/${id}`);
    dispatch(driverDetailSuccess(data));
  } catch (error) {
    dispatch(driverDetailFail(error.response.data.message));
  }
};

//user create booking
export const createNewBooking = (booking) => async (dispatch) => {
  try {
    dispatch(createBookingRequest());
    const { data } = await axios.post(`/api/v1/book/new`, booking);

    dispatch(createBookingSuccess(data));
  } catch (error) {
    //handle error
    dispatch(createBookingFail(error.response.data.message));
  }
};

//user my bookings
export const userBookings = async (dispatch) => {
  try {
    dispatch(userBookingsRequest());
    const { data } = await axios.get(`/api/v1/mybookings`);
    dispatch(userBookingsSuccess(data));
  } catch (error) {
    dispatch(userBookingsFail(error.response.data.message));
  }
};

// user booking detail
export const bookingDetailActions = (id) => async (dispatch) => {
  try {
    dispatch(bookingDetailRequest());
    const { data } = await axios.get(`/api/v1/booking/${id}`);
    dispatch(bookingDetailSuccess(data));
  } catch (error) {
    dispatch(bookingDetailFail(error.response.data.message));
  }
};

//user cancelbooking
export const cancelBooking = (id, bookingData) => async (dispatch) => {
  try {
    dispatch(cancelBookingRequest());
    const { data } = await axios.put(
      `/api/v1/user/bookings/${id}`,
      bookingData
    );
    dispatch(cancelBookingSuccess(data));
  } catch (error) {
    dispatch(cancelBookingFail(error.response.data.message));
  }
};

//admin cancelbooking
export const adminCancelBooking = (id, bookingData) => async (dispatch) => {
  try {
    dispatch(adminCancelBookingRequest());
    const { data } = await axios.put(
      `/api/v1/admin/cancel/bookings/${id}`,
      bookingData
    );
    dispatch(adminCancelBookingSuccess(data));
  } catch (error) {
    dispatch(adminCancelBookingFail(error.response.data.message));
  }
};

//user updatebooking
export const userUpdateBooking = (id, bookingData) => async (dispatch) => {
  try {
    dispatch(userUpdateBookingRequest());
    const { data } = await axios.put(
      `/api/v1/user/mybookings/${id}`,
      bookingData
    );
    dispatch(userUpdateBookingSuccess(data));
  } catch (error) {
    dispatch(userUpdateBookingFail(error.response.data.message));
  }
};
