import { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import {
  deleteBooking,
  adminBookings as adminBookingsAction,
  adminCancelBooking,
} from "../../actions/bookingActions";
import {
  clearBookingDeleted,
  clearBookingError,
  clearadminBookingCancelled,
} from "../../slices/bookingSlice";

export default function BookingList() {
  const {
    adminBooking = [],
    loading = true,
    error,
    isBookingDeleted,
    isBookingUpdated = false,
  } = useSelector((state) => state.bookingState);

  const [userReason, setUserReason] = useState("cancelled by admin");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteBooking(id));
  };

  useEffect(() => {
    if (isBookingUpdated) {
      handleClose();
      toast("Booking cancelled successfully", {
        type: "success",
        toastId: "useradminbookupdate",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearadminBookingCancelled()),
      });
      return;
    }
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "useradminbookerror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }
    dispatch(adminBookingsAction);
  }, [isBookingUpdated, error, dispatch]);

  const submitHandler = (e, id) => {
    e.preventDefault();
    let bookingData = adminBooking.filter((book) => {
      return book._id === id;
    })[0];

   

    bookingData = {
      ...bookingData,
      status: {
        ...bookingData.status,
        bookStatus: "Cancelled",
        cancelledUserAt: new Date(),
        userReason: userReason,
      },
    };
    console.log(bookingData);
    dispatch(adminCancelBooking(id, bookingData));
  };

  const setBooking = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Pickup point",
          field: "pickupPoint",
          sort: "asc",
        },
        {
          label: "Destination Point",
          field: "dropPoint",
          sort: "asc",
        },
        {
          label: "Phone No",
          field: "phoneNo",
          sort: "asc",
        },
        {
          label: "Passenger",
          field: "noOfPassengers",
          sort: "asc",
        },
        {
          label: "Date",
          field: "pickupDate",
          sort: "asc",
        },
        {
          label: "Time",
          field: "pickupTime",
          sort: "asc",
        },
        {
          label: "Status",
          field: "bookStatus",
          sort: "asc",
        },
        {
          label: "Actions of changing data",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    adminBooking.forEach((booking, index) => {
      data.rows.push({
        id: booking._id,
        pickupPoint: booking.mapInfo.pickupPoint,
        dropPoint: booking.mapInfo.dropPoint,
        phoneNo: booking.phoneNo,
        noOfPassengers: booking.noOfPassengers,
        pickupDate: booking.pickupDate,
        pickupTime: booking.pickupTime,
        bookStatus: (
          <p
            style={{
              color: booking.status.bookStatus.includes("processing")
                ? "red"
                : "green",
            }}
          >
            {booking.status.bookStatus}
          </p>
        ),
        actions: (
          <Fragment>
            <Link
              to={`/admin/singlebooking/${booking._id}`}
              className="btn btn-primary py-1 px-2 ml-2"
            >
              <i className="fa fa-eye"></i>
            </Link>
            {(booking.status.bookStatus === "Processing" ||
              booking.status.bookStatus === "Accepted") && (
              <Link
                to={`/admin/bookings/${booking._id}`}
                className="btn btn-primary py-1 px-2 ml-2"
              >
                <i className="fa fa-pen"></i>
              </Link>
            )}
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, booking._id)}
              ></i>
            </Button>
            {(booking.status.bookStatus === "Processing" ||
              booking.status.bookStatus === "Accepted") && (
              <Button className="btn btn-danger py-1 px-2 ml-2">
                <i
                  className="fa fa-ban"
                  onClick={handleShow}
                  data-toggle="modal"
                  data-target={"#bookid" + index}
                ></i>
                <div
                  className="modal fade"
                  id={"bookid" + index}
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          style={{ color: "black" }}
                          className="modal-title"
                          id="exampleModalLongTitle"
                        >
                          Do you want to cancel the trip?
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          aria-hidden="true"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <textarea
                          className="form-control"
                          type="text"
                          value={userReason}
                          onChange={(e) => setUserReason(e.target.value)}
                          placeholder="Please Give Reason"
                        ></textarea>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => submitHandler(e, booking._id)}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            )}
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "booklisterror",
        onOpen: () => {
          dispatch(clearBookingError());
        },
      });
      return;
    }
    if (isBookingDeleted) {
      toast("booking deleted successfully", {
        type: "success",
        toastId: "booklistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearBookingDeleted()),
      });
      return;
    }

    dispatch(adminBookingsAction);
  }, [dispatch, error, isBookingDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Booking List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setBooking()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
