import { Fragment, useEffect } from "react";
import { getReviews } from "../../actions/driverActions";
import { useDispatch, useSelector } from "react-redux";

import { Carousel } from "react-bootstrap";
import MetaData from "../layouts/MetaData";

export default function VehicleDetails() {
  const { driverDetails = {} } = useSelector((state) => state.driverState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReviews); //review data already get from this here get driver details
  }, [dispatch]);

  return (
    <Fragment>
      <MetaData title={"vehicle details"} />
      <h1 className="text-center">Vehicle Details</h1>
      <div className="row ">
        <div className="col-12 col-md-10">
          <div className="card-confirm">
            <div className="container">
              <div className="row m-0">
                <div className="col-lg-7 pb-5 pe-lg-7">
                  <div className="row">
                    <div className="col-12 col-lg-5 img-fluid">
                      <Carousel pause="hover">
                        {driverDetails.images &&
                          driverDetails.images.map((image) => (
                            <Carousel.Item key={image._id}>
                              <img
                                className="d-block w-100"
                                src={image.image}
                                alt="vehicle"
                                height="500"
                                width="500"
                              />
                            </Carousel.Item>
                          ))}
                      </Carousel>
                    </div>

                    <div className="row m-0 bg-light">
                      <div className="col-md-4 col-6 ps-30 pe-0 my-4">
                        <p className="text-muted">Name</p>
                        <p className="h5">{driverDetails.name}</p>
                      </div>
                      <div className="col-md-4 col-6  ps-30 my-4">
                        <p className="text-muted">Email</p>
                        <p className="h6 m-0">{driverDetails.driverEmail}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Contact No</p>
                        <p className="h5 m-0">{driverDetails.contactNo}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">vehicle No</p>
                        <p className="h5 m-0">{driverDetails.vehicleNo}</p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">vehicle Category</p>
                        <p className="h5 m-0">
                          {driverDetails.vehicleCategory}
                        </p>
                      </div>
                      <div className="col-md-4 col-6 ps-30 my-4">
                        <p className="text-muted">Vehicle Modal</p>
                        <p className="h5 m-0">{driverDetails.vehicleModal}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 p-0 ps-lg-4">
                  <div className="row m-0">
                    <div className="col-12 px-4">
                      <div className="d-flex align-items-end mt-4 mb-2">
                        <p className="h4 m-0">
                          <span className="pe-1"></span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12 px-0">
                      <div className="row bg-light m-0">
                        <div className="col-12 px-4">
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">Passengers</p>
                            <p className="fs-14 fw-bold">
                              {driverDetails.maxPassengers}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">No of reviews</p>
                            <p className="fs-14 fw-bold">
                              {driverDetails.numOfReviews}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                            <p className="text-muted">ratings</p>
                            <div className="rating-outer">
                              <div
                                className="rating-inner"
                                style={{
                                  width: `${
                                    (driverDetails.ratings / 5) * 100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
