import { Fragment } from "react";
import MetaData from "./MetaData";

export default function Terms() {
  return (
    <Fragment>
      <MetaData title={"terms & conditions"} />
      <div className="terms">
        <h1>
          <b>TERMS AND CONDITIONS</b>
        </h1>

        <p>
          Your access to and use of this website is subject exclusively to these
          terms and conditions. These terms and conditions are tailored to the
          use of the services offered by BRO 3 (PVT) LTD. You will not use the
          website for any purposes that are prohibited by these Terms and
          Conditions. When using this website you are accepting the Terms and
          Conditions, and if you do not accept these Terms and Conditions, you
          must not use this website and the associated softwares.
        </p>

        <h4>
          <b>1. BOOKING AND CANCELLATION CONDITIONS</b>
        </h4>
        <p>
          <ul>
           
            <li>
            You can change the dates of your booking, for no extra fees.

            </li>
            <li>
            You can cancel the booking until two days before the booking. If you cancel your booking during this period, the total amount will be refunded back to the original payment method. 
            However if cancelled after this period, a refund will not be available.
            </li>
            <li>
             If you change the date/ time of your booking once, you will not be able to get a refund, if you cancel the booking.
            </li>
          </ul>
        </p>
        <h4>
          <b>2. PAYMENT CONDITIONS</b>
        </h4>
        <p>
          <ul>
            <li>
              All payments will be taken in advance by card payments. 
            </li>
          </ul>
        </p>

        <h4>
          <b>3. RESPONSIBILITIES AND RISK OF PASSENGERS</b>
        </h4>
        <p>
          <ul>
            <li>
              BRO 3 (PVT) LTD will not be responsible for any personal losses or
              damages that are not caused by the driver.
            </li>
            <li>
              The passenger certifies that they use the service of their own
              free will and that they understand and use the website responsibly
              at their own risk.
            </li>
            <li>
              The user understands that www.bro3taxi.com is not responsible for
              booking cancellations, delays or miscommunication between the
              driver and the passenger.
            </li>
            <li>
              The passenger acknowledges and agrees that www.bro3taxi.com does
              not select the drivers solely by registering on the website, but
              does additional checks before hiring a driver.
            </li>
            <li>
              The passenger acknowledges that the data provided to them about
              the taxi driver and vehicle were provided by the driver themself.
              By accepting this the passenger understands that www.bro3taxi.com
              has no involvement in the contractual relationship between the
              driver and passenger.
            </li>
          </ul>
        </p>

        <h4>
          <b>4. RESPONSIBILITIES AND RISK OF DRIVER</b>
        </h4>
        <p>
          <ul>
            <li>
              When a different driver takes your vehicle for the hire, you must
              change your profile picture to the picture of the person who is
              going to do the hire in your vehicle
            </li>
            <li>
              As a driver, you must not use your phone during the journey unless
              when used for GPS purposes. If you need to make a call, or answer
              an important call, you must inform the passenger and stop the
              vehicle before you make or answer that call.
            </li>
            <li>
              As a driver, you must responsibly transport passengers safely in
              accordance with the road safety rules in Sri Lanka. You must not
              drive if you are drowsy as it can cause accidents. Therefore, if
              you feel drowsy inform the passenger and take a break.
            </li>
            <li>
              You must not drive when under strong medication, as it can affect
              your driving which then puts the passengers at risk. If prescribed
              strong medication for a long period of time, you must inform BRO 3
              (PVT) LTD, and they will tell you the necessary steps that need to
              be taken.
            </li>
            <li>
              BRO 3 (PVT) LTD does not tolerate drug or alcohol use by drivers
              while using the service.
            </li>
            <li>
              If 3 different passengers complain and state that you were under
              the influence of alcohol or drugs or state that you were driving
              dangerously, you will be terminated from BRO 3 (PVT) LTD
            </li>
          </ul>
        </p>
        <h4>
          <b>5. RELATIONSHIP BETWEEN DRIVER AND PASSENGER</b>
        </h4>
        <p>
          BRO 3 (PVT) LTD does not serve immediately, all bookings are
          pre-booked using the website. www.bro3taxi.com has no direct contact
          with the taxi driver, it is used to facilitate contact between the
          taxi driver and the passenger. The user agrees that www.bro3taxi.com
          is not responsible for any act or exclusion committed by any user,
          whether by discussion, robbery, commitment break as a result of any
          act. The user must acknowledge that by registering and accepting the
          Terms and Conditions of the service, does so at their total means and
          exclusive risk. www.bro3taxi.com assures that each transaction is done
          properly in line with the guidelines.
        </p>
        <h4>
          <b>6. SYSTEM AVAILABILITY</b>
        </h4>
        <p>
          {" "}
          BRO 3 (PVT) LTD does not guarantee that the system will be available
          without any interference and that it will always be error-free,
          therefore, it is not responsible for any damages caused to the users.
          You agree that www.bro3taxi.com is not liable for any losses or
          damages caused to your device when using the website. www.bro3taxi.com
          is not responsible for any error or wrong information of other
          independent systems, such as GPS and other similar ones.
        </p>
        <h4>
          <b>7. COMPENSATION FOR DAMAGES</b>
        </h4>
        <p>
          The user agrees to compensate and hold harmless www.bro3taxi.com and
          its representatives from any claims, losses, damages and expenses,
          losses, and court costs for the damage they cause. The user agrees to
          compensate and hold harmless www.bro3taxi.com and its representative
          from any Accidental Claims or suits that occur as a result of the
          driver’s negligence or by the fault of the driver. The user accepts
          that www.bro3taxi.com acts solely as a booking service for the user
          and matches them with an available driver, and that it is not
          responsible for any issues that arise during the taxi ride, such as
          accidents, taxi discomfort or delays.
        </p>
        <h4>
          <b>8. GENERAL TERMS AND CONDITIONS</b>
        </h4>
        <p>
          BRO 3 (PVT) LTD follows a zero-tolerance policy regarding all forms of
          discrimination, harassment or abuse.
          <br />
          <br />
          We believe it is unacceptable to refuse to provide services based on a
          person’s religion, national origin, race, sexual orientation, sex,
          disability, age or any other characteristic protected under applicable
          Sri Lankan law. In the case of this occurrence, it can result in
          permanent loss of access to our platform and further action will be
          taken under the law.
          <br />
          <br />
          Drivers and passengers are expected to act appropriately with each
          other, respecting their boundaries. You must not make unnecessary
          comments that are inappropriate. In addition to this you must not
          comment on appearance and ask very personal questions. You must also
          not make unwanted physical contact. These are all considered as
          inappropriate behaviour, and not accepted by our BRO 3 (PVT) LTD.
          Anyone who engages in sexual conduct while using the BRO 3 (PVT) LTD
          platform, will permanently lose access to our platform and further
          action will be taken under the law. Therefore, we advise you to be
          mindful of other people’s personal space. Violence is also not
          permitted, in case of any violence, further disciplinary action will
          be taken.
          <br />
          <br />
          Both driver and passengers are expected to be treated with respect.
          This expectation extends to personal property as well. As a passenger,
          you are responsible for your own personal belongings and also make
          sure you treat the driver’s vehicle with respect. As a driver, it is
          your responsibility to return any lost items in your vehicle. You
          cannot require extra payment to return their lost belongings, if so
          you may permanently lose access to our BRO 3 (PVT) LTD platform.
          <br />
          <br />
          The safety of both drivers and passengers is of utmost concern, in
          order to protect everyone in the vehicle, we require the following:
          <br />
          <br />
          <ul>
            <li>
              {" "}
              Illegal substances (unless explicitly allowed by law) such as open
              containers of alcohol are not permitted in drivers’ vehicles,
              which is against the law and a serious violation of BRO 3 (PVT)
              LTD policy.{" "}
            </li>
            <li>
              Furthermore, BRO 3 (PVT) LTD does not tolerate drug or alcohol use
              by drivers while using the service. If a passenger believes the
              driver may be under the influence of drugs or alcohol, please
              request the driver to end the trip immediately and inform BRO 3
              (PVT) LTD.
            </li>
            <li>
              BRO 3 (PVT) LTD forbids any kind of human trafficking. Anyone
              engaging in such activity will permanently lose access to BRO 3
              (PVT) LTD platform, and further action will be taken under the
              law.
            </li>
            <li>
              As a passenger, you are responsible for your own safety, and abide
              by the seat belt laws in Sri Lanka.
            </li>
            <li>
              As a passenger, if at any point of the journey, you do not feel
              safe or have any concerns, you can reach out to us immediately and
              we will take the required action.
            </li>
            <li>
              If these Terms and Conditions are violated by either the driver or
              passenger, it could result in permanent loss of your BRO 3 (PVT)
              LTD account. All violations should be reported to BRO 3 (PVT) LTD,
              which will then be investigated.
            </li>
            <li>
              If at any point of the journey you are faced with a situation that
              requires immediate emergency attention, contact BRO 3 (PVT) LTD.
            </li>
            <li>Finally, be assured that you will be our utmost priority.</li>
          </ul>
        </p>

        <h4>
          <b>9. CONTENT RIGHTS AND TERMINATION </b>
        </h4>
        <p>
          The content and features of the website are free and made available to
          visitors. However, we have the right to terminate access to certain
          aspects of the website, with or without notice. We also have the right
          to deny access to particular access to any of the services.
          www.bro3taxi.com also has the right to change or introduce new
          features on the website without any prior notice.
        </p>
      </div>
    </Fragment>
  );
}
