import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import Sidetoolbar from "./Sidetoolbar";
import { deleteContact, getContacts } from "../../actions/contactActions";
import { clearContactDeleted, clearError } from "../../slices/contactSlice";

export default function ContactList() {
  const {
    contacts = [],
    loading = true,
    error,
    isContactDeleted,
  } = useSelector((state) => state.contactState);

  const dispatch = useDispatch();

  const deleteHandler = (e, id) => {
    e.target.disabled = true;
    dispatch(deleteContact(id));
  };

  const setContact = () => {
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "UserName",
          field: "userName",
          sort: "asc",
        },
        {
          label: "UserEmail",
          field: "userEmail",
          sort: "asc",
        },
        {
          label: "UserMessage",
          field: "userMessage",
          sort: "asc",
        },
        {
          label: "CreatedAt",
          field: "createdAt",
          sort: "asc",
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    contacts.forEach((contact) => {
      data.rows.push({
        id: contact._id,
        userName: contact.userName,
        userEmail: contact.userEmail,
        userMessage: contact.userMessage,
        createdAt: contact.createdAt,
        actions: (
          <Fragment>
            <Button className="btn btn-danger py-1 px-2 ml-2">
              <i
                className="fa fa-trash"
                onClick={(e) => deleteHandler(e, contact._id)}
              ></i>
            </Button>
          </Fragment>
        ),
      });
    });
    return data;
  };

  useEffect(() => {
    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        toastId: "contactlisterror",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
    if (isContactDeleted) {
      toast("message deleted successfully", {
        type: "success",
        toastId: "contactlistsuccess",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearContactDeleted()),
      });
      return;
    }

    dispatch(getContacts);
  }, [dispatch, error, isContactDeleted]);

  return (
    <div className="row">
      <div className="col-12 col-md-2">
        <Sidetoolbar />
      </div>

      <div className="col-12 col-md-10">
        <h1 className="my-4">Contact us List</h1>
        <Fragment>
          {loading ? (
            <Loader />
          ) : (
            <MDBDataTable
              data={setContact()}
              bordered
              striped
              hover
              responsive
              className="px-3"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
}
