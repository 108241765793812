import { createSlice } from "@reduxjs/toolkit";

const customSlice = createSlice({
  name: "custom",
  initialState: {
    loading: false,
    custom: {},
    customs: [],
    adminCustom:{},
    customDriver: {},
    customUser: {},
    isCustomCreated: false,
    isCustomDeleted: false,
    isCustomUpdated: false,
  },
  reducers: {
    customRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    customSuccess(state, action) {
      return {
        ...state,
        loading: false,
        customs: action.payload.customs
      };
    },
    customFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearCustom(state, action) {
      return {
        ...state,
        custom: {},
      };
    },
    customSingleRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    customSingleSuccess(state, action) {
      return {
        ...state,
        loading: false,
        adminCustom: action.payload.custom
      };
    },
    customSingleFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    newCustomRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newCustomSuccess(state, action) {
      return {
        ...state,
        loading: false,
        custom: action.payload.custom,
        isCustomCreated: true,
      };
    },
    newCustomFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCustomCreated: false,
      };
    },
    clearCustomCreated(state, action) {
      return {
        ...state,
        isCustomCreated: false,
      };
    },
    deleteCustomRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteCustomSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isCustomDeleted: true,
      };
    },
    deleteCustomFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCustomDeleted: false,
      };
    },
    clearCustomDeleted(state, action) {
      return {
        ...state,
        isCustomDeleted: false,
      };
    },
    updateCustomRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateCustomSuccess(state, action) {
      return {
        ...state,
        loading: false,
        custom: action.payload.custom,
        isCustomUpdated: true,
      };
    },
    updateCustomFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearCustomUpdated(state, action) {
      return {
        ...state,
        isCustomUpdated: false,
      };
    },
    customDriverRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    customDriverSuccess(state, action) {
      return {
        ...state,
        loading: false,
        customDriver: action.payload.customs,
      };
    },
    customDriverFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    customUserRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    customUserSuccess(state, action) {
      return {
        ...state,
        loading: false,
        customUser: action.payload.customs,
      };
    },
    customUserFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = customSlice;

export const {
  customRequest,
  customSuccess,
  customFail,
  clearCustom,
  clearError,
  customSingleRequest,
  customSingleSuccess,
  customSingleFail,
  newCustomRequest,
  newCustomSuccess,
  newCustomFail,
  clearCustomCreated,
  deleteCustomFail,
  deleteCustomRequest,
  deleteCustomSuccess,
  clearCustomDeleted,
  updateCustomFail,
  updateCustomRequest,
  updateCustomSuccess,
  clearCustomUpdated,
  customDriverRequest,
  customDriverFail,
  customDriverSuccess,
  customUserRequest,
  customUserFail,
  customUserSuccess,
} = actions;

export default reducer;
